import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {Component, createRef} from 'react';
import Query from 'react-apollo/Query';
import {withRouter} from 'react-router-dom'
import 'react-table/react-table.css';
import DashboardTable from '../fhg/components/DashboardTable';
import DeleteMutation from '../fhg/components/data/DeleteMutation';
import DisplayError from '../fhg/components/DisplayError';
import Typography from '../fhg/components/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getUnitsQuerySmall} from '../pages/UnitEdit';

// Styles for the table.
const styles = theme => ({
   outerFrame: {
      margin: theme.spacing(0, 2, 2, 2),
      maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
      width: 'fit-content',
      overflow: 'hidden',
      height: '100%',
   },
   '& .rt-table': {
      width: '330px !important',
   },
   '& .rt-td': {
      padding: `${theme.spacing(0.5)}px !important`,
   },
   margin: {
      margin: theme.spacing(1),
   },
   fabProgress: {
      position: 'absolute',
      top: 7,
      left: 7,
      zIndex: 1,
   },
});

const DELETE_UNIT = gql`
   mutation deleteUnit($unitId: Int!)
   {
      truck_Delete(truckId: $unitId)
   }
`;

/**
 * The table of data for the devices.
 */
class UnitTable extends Component {
   tableRef = createRef();
   state = {};

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      search: PropTypes.string,                 // A search string for filtering the table.
      isLoading: PropTypes.bool,                // Indicates if the table data is being loaded.
      disabled: PropTypes.bool,                 // True if the table should be disabled.
      onQuery: PropTypes.func,                  // Supplied by the withRequest HOC to perform the query on the server.
   };

   handleEditClick = id => () => {
      this.props.history.push(`/admin/edit/unit/${id}`, this.props.filters);
   };

   handleDoubleClick = (item) => {
      this.handleEditClick(item.id)();
   };

   handleDelete = async (deleteItemId, item) => {
      this.setState({isDeleting: true, deleteItemId, item});
   };

   handleUpdate = () => {
      this.setState({isDeleting: false, deleteItemId: undefined, item: undefined});
   };

   /*
      Status,Event,City,Driver,Ticket/BOL,Start Date,Start Time,Gallons,Net Gallons,Loco ID,Loco #,Totalizer Start,Totalizer End,Loaded,Delivered,Adjusted,Balance,Start Day,Springfield,,,,,,,,,,,,,,8043.5 */
   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   getColumns = () => {
      const {classes} = this.props;

      return [
         {
            id: 'Unit',
            Header: <Typography>Unit</Typography>,
            accessor: 'identityNumber',
            minWidth: 100,
            width: 140,
         }, {
            id: 'Location',
            Header: <Typography>Location</Typography>,
            accessor: 'location.label',
            minWidth: 85,
            width: 120,
         }, {
            id: '_Edit',
            width: 100,
            accessor: 'identityNumber',
            Cell: row => (
               <Grid container direction={'row'} wrap={'nowrap'}>
                  <Grid item>
                     <IconButton aria-label='Edit' classes={{root: classes.buttonStyle}} disabled={this.state.isDeleting}
                                 onClick={this.handleEditClick(row.original.id)}>
                        <EditIcon fontSize='small'/>
                     </IconButton>

                  </Grid>
                  <Grid item>
                     <Grid item style={{position: 'relative'}}>
                        <IconButton aria-label='Delete' classes={{root: classes.buttonStyle}} disabled={this.state.isDeleting}
                                    onClick={() => {
                                       return this.handleDelete( row.original.id, row.value)
                                    }}>
                           <DeleteIcon fontSize='small'/>
                        </IconButton>
                        {this.state.deleteItemId === row.original.id && (
                           <CircularProgress size={30} className={classes.fabProgress}/>
                        )}
                     </Grid>
                  </Grid>
               </Grid>
            )
         },
      ]
   };

   render() {
      let {classes, filters, disabled, search, tableRef} = this.props;
      const {isDeleting, deleteItemId, item} = this.state;

      return (
         <Query query={getUnitsQuerySmall} variables={filters} skip={filters === undefined}
                fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <DeleteMutation mutation={DELETE_UNIT} idKey={'unitId'}
                               deletedItemId={deleteItemId} confirmValues={{item}}
                               confirmKey={'unit.delete.confirmation'}
                               updateCache={[
                                  {query: getUnitsQuerySmall, key: 'units', variables: this.props.filters},
                               ]}
                               onUpdate={this.handleUpdate} onCancel={this.handleUpdate}
               >
                  <Grid item className={classes.outerFrame}>
                     <DisplayError error={error}/>

                     <DashboardTable name='Units' innerRef={tableRef} data={data && data.units}
                                     getColumns={this.getColumns}
                                     isLoading={loading && !(data && data.units)}
                                     onDoubleClick={this.handleDoubleClick}
                                     disabled={disabled || isDeleting || loading}
                                     search={search}/>
                  </Grid>
               </DeleteMutation>
            )}
         </Query>
      );
   }
}

// The initial fetch will be only the data from today.
export default withRouter(withStyles(styles)(UnitTable));
