import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {format} from 'date-fns-tz';
import gql from 'graphql-tag';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {createRef, PureComponent} from 'react';
import Query from 'react-apollo/Query';
import {Link} from 'react-router-dom';
import 'react-table/react-table.css';
import {DATE_FORMAT, DATE_FORMAT_TIME_ZONE} from '../Constants';
import DashboardTable from '../fhg/components/DashboardTable';
import DisplayError from '../fhg/components/DisplayError';
import Typography from '../fhg/components/Typography';

// Styles for the table.
const styles = theme => ({
   outerFrame: {
      margin: theme.spacing(0, 2, 2, 2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      overflow: 'hidden',
      height: '100%',
   },
});

const unitBalanceReport = gql`
   query getUnitBalanceReport($eventId: [Int], $unitId: Int!, $startDate: String, $endDate: String)
   {
      unitBalanceReport:truckBalanceReport(eventTypeId: $eventId, truckId: $unitId, startDate: $startDate, endDate: $endDate) {
         id:transactionId
         Status
         Event
         City
         Driver
         TicketBol
         FuelBegin
         GrossGallons
         NetGallons
         LocoId
         LocoNumber
         GangID
         CNumber
         Balance
         SubmitTo
         Transaction
         Adjusted
         TotalizerBegin
         TotalizerEnd
         ValidityCheckText
         Timezone
      }
   }
`;

/**
 * The table of data for the devices.
 */
class UnitBalanceTable extends PureComponent {
   tableRef = createRef();

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      search: PropTypes.string,                 // A search string for filtering the table.
      isLoading: PropTypes.bool,                // Indicates if the table data is being loaded.
      disabled: PropTypes.bool,                 // True if the table should be disabled.
      onQuery: PropTypes.func,                  // Supplied by the withRequest HOC to perform the query on the server.
   };

   state = {
      showError: true,
   };

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({ showError: false });
   };

   /*
   Status,Event,City,Driver,Ticket/BOL,Start Date,Start Time,Gallons,Net Gallons,Loco ID,Loco #,Totalizer Start,Totalizer End,Loaded,Delivered,Adjusted,Balance,Start Day,Springfield,,,,,,,,,,,,,,8043.5 */
   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   getColumns = (data) => {
      return [
         {
            id: 'Status',
            Header: <Typography id={'balance.status.column'}/>,
            accessor: 'Status',
            minWidth: 100,
            Footer: <Typography id={'balance.totals.column'}/>,
         }, {
            id: 'Event',
            Header: <Typography id={'balance.event.column'}/>,
            accessor: 'Event',
            minWidth: 85,
         }, {
            id: 'City',
            Header: <Typography id={'balance.city.column'}/>,
            accessor: 'City',
            minWidth: 125,
         }, {
            id: 'Driver',
            Header: <Typography>Driver</Typography>,
            accessor: 'Driver',
            minWidth: 125,
         }, {
            id: 'Ticket/BOL',
            Header: <Typography>Ticket/BOL</Typography>,
            accessor: 'TicketBol',
            minWidth: 100,
         }, {
            id: 'Start Date',
            Header: <Typography>Start Date</Typography>,
            accessor: 'FuelBegin',
            Cell: row => {
               if (row.value) {
                  const dateInTimezone = new Date(new Date(row.value).toLocaleString("en-US", {timeZone: row.original.Timezone}));
                  return format(dateInTimezone, DATE_FORMAT_TIME_ZONE, {timeZone: row.original.Timezone});
               } else {
                  return 'N/A';
               }
            },
            minWidth: 185,
         }, {
            id: 'Gross Gallons',
            Header: <Typography hasLineBreaks id={'balance.grossGallons.column'}>Gross Gallons</Typography>,
            accessor: 'GrossGallons',
            minWidth: 105,
            Footer: table => (
               <span>
                  {_.round(_.sumBy(table.data, d => Number(d['Gross Gallons'] || 0)).toFixed(1), 1)}
               </span>
            )
         }, {
            id: 'Net Gallons',
            Header: <Typography hasLineBreaks id={'balance.netGallons.column'}>Net Gallons</Typography>,
            accessor: 'NetGallons',
            minWidth: 105,
            Footer: table => (
               <span>
                  {_.round(_.sumBy(table.data, d => Number(d['Net Gallons'] || 0)).toFixed(1), 1)}
               </span>
            )
         }, {
            id: 'Loco ID',
            Header: <Typography>Loco ID</Typography>,
            accessor: 'LocoId',
            minWidth: 75,
         }, {
            id: 'Loco #',
            Header: <Typography>Loco #</Typography>,
            accessor: 'LocoNumber',
            minWidth: 75,
         }, {
            id: 'GangID',
            Header: <Typography>Gang ID</Typography>,
            accessor: 'GangID',
            minWidth: 75,
         }, {
            id: 'CNumber',
            Header: <Typography>C Number</Typography>,
            accessor: 'CNumber',
            minWidth: 75,
         }, {
            id: 'TotalizerBegin',
            Header: <Typography hasLineBreaks>{`Totalizer\nBegin`}</Typography>,
            accessor: 'TotalizerBegin',
            minWidth: 100,
         }, {
            id: 'TotalizerEnd',
            Header: <Typography hasLineBreaks>{`Totalizer\nEnd`}</Typography>,
            accessor: 'TotalizerEnd',
            minWidth: 100,
         }, {
            id: 'Adjusted',
            Header: <Typography>Adjusted</Typography>,
            accessor: 'Adjusted',
            minWidth: 85,
         }, {
            id: 'Balance',
            Header: <Typography>Balance</Typography>,
            accessor: 'Balance',
            minWidth: 75,
         }, {
            id: 'SubmitTo',
            Header: <Typography>Submit To</Typography>,
            accessor: 'SubmitTo',
            minWidth: 85,
         }, {
            id: 'Transaction',
            Header: <Typography id={'balance.transaction.column'}/>,
            accessor: 'Transaction',
            Cell: row => <Link to={`/admin/edit/transaction/${row.value}`}>{row.value}</Link>,
            minWidth: 105,
         }, {
            id: 'ValidityCheckText',
            Header: <Typography id={'balance.reason.column'}/>,
            accessor: 'ValidityCheckText',
            minWidth: 300,
         },
      ]
   };

   render() {
      let {classes, disabled, search, tableRef, filters} = this.props;
      const unitId = _.get(filters, 'unitId');

      return (
         <Query query={unitBalanceReport} variables={filters} skip={!unitId} fetchPolicy={'network-only'}>
            {({loading, error, data}) => (
               <Grid item className={classes.outerFrame}>
                  <DisplayError error={error}/>

                  <DashboardTable name='Unit Balance Report' innerRef={tableRef} data={data && data.unitBalanceReport} getColumns={this.getColumns} isLoading={loading}
                                  disabled={disabled}
                                  search={search}/>
               </Grid>
            )}
         </Query>
      );
   }
}

// The initial fetch will be only the data from today.
export default withStyles(styles)(UnitBalanceTable);
