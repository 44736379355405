import React, { useState, useContext, useRef } from 'react'
import { DatePicker } from 'material-ui-pickers'
import { MuiPickersContext } from 'material-ui-pickers'
import withStyles from '@material-ui/core/styles/withStyles'
import { styles as dayStyles } from 'material-ui-pickers/DatePicker/components/Day'
import classnames from 'classnames'

function DateRangePicker({
   classes,
   value,
   onChange,
   labelFunc,
   format,
   emptyLabel,
   autoOk,
   onClose,
   ...props
}) {
   const [begin, setBegin] = useState(value && value[0]);
   const [end, setEnd] = useState(value && value[1]);
   const [hover, setHover] = useState(undefined);
   const picker = useRef();
   const utils = useContext(MuiPickersContext);

   const cache = (value && value.length > 0 && value.sort()) || [];
   const min = Math.min(begin, end || hover || begin);
   const max = Math.max(begin, end || hover || begin);

   function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
      return React.cloneElement(dayComponent, {
         onClick: e => {
            e.stopPropagation();
            if (!begin) setBegin(day);
            else if (!end) {
               setEnd(day);
               if (autoOk) {
                  onChange([begin, day].sort());
                  picker.current.close()
               }
            } else {
               setBegin(day);
               setEnd(undefined)
            }
         },
         onMouseEnter: e => setHover(day),
         onMouseLeave: e => setHover(undefined),
         className: classnames(classes.day, {
            [classes.hidden]: dayComponent.props.hidden,
            [classes.current]: dayComponent.props.current,
            [classes.isDisabled]: dayComponent.props.disabled,
            [classes.isSelected]: day >= min && day <= max,
            [classes.beginCap]: max !== min && utils.isSameDay(day, min),
            [classes.endCap]: max !== min && utils.isSameDay(day, max),
            [classes.bothCap]: max === min && utils.isSameDay(day, min),
         })
      })
   }

   const formatDate = date => utils.format(date, format || utils.dateFormat);

   return (
      <DatePicker
         {...props}
         value={begin}
         renderDay={renderDay}
         onClose={() => {
            if (onClose) onClose()
         }}
         onDismiss={() => {
            setBegin(cache[0]);
            setEnd(cache[1]);
         }}
         onChange={() => {
            onChange([begin, end].sort());
         }}
         onClear={() => {
            setBegin(undefined);
            setEnd(undefined);
            setHover(undefined);
            onChange([])
         }}
         ref={picker}
         labelFunc={(date, invalid) => {
            const min = Math.min(begin, end || hover || begin);
            const max = Math.max(begin, end || hover || begin);
            return labelFunc
               ? labelFunc([min, max], invalid)
               : date && min && max
               ? `${formatDate(min)} - ${formatDate(max)}`
               : emptyLabel || ''
         }}
      />
   )
}

export const styles = theme => {
   const base = dayStyles(theme);
   return {
      ...base,
      day: {
         ...base.day,
         margin: 0,
         width: '40px',
         borderRadius: '0'
      },
      beginCap: {
         borderRadius: '50% 0 0 50%'
      },
      endCap: {
         borderRadius: '0 50% 50% 0'
      },
      bothCap: {
         borderRadius: '50% 50% 50% 50%'
      }
   }
};

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker)
