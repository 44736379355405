import {Grid} from '@material-ui/core';
import TextField from '@material-ui/core//TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import gql from 'graphql-tag';
import {isEqual, memoize} from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import {DateTimePicker, InlineTimePicker} from 'material-ui-pickers'
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import Mutation from 'react-apollo/Mutation';
import Query from 'react-apollo/Query';
import {intlShape, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {HEADER_HEIGHT} from '../Constants';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import NumberFormatCustom from '../fhg/components/NumberFormat';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import {getChangedObject, formatMessage} from '../fhg/utils/Utils';
import memoizeOne from 'memoize-one';
import {Prompt} from 'react-router-dom';
import {zonedTimeToUtc} from 'date-fns-tz';

const MAX_WIDTH = 500;
const LOCOMOTIVE_TRANSACTION = 2;
const NON_LOCOMOTIVE_TRANSACTION = 3;
const NO_SPILL = 'No Spill';
const SPILL = 'Spill';
const NON_LOCOMOTIVE = 'Non-Locomotive';
const LOCOMOTIVE = 'Locomotive';

const styles = theme => ({
   button: {
      margin: theme.spacing(1),
   },
   unsizingFlex: {
      flex: '0 0 auto',
   },
   subtitleStyle: {
      maxWidth: MAX_WIDTH,
   },
   flex: {
      display: 'flex',
   },
   stickyCaption: {
      position: 'sticky',
      left: 0,
   },
   resizingContainer: {
      display: 'flex',
      flex: '1 1',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
      // alignItems: 'center',
   },
   outerContainer: {
      height: `calc(100% - ${theme.spacing(1)}px)`,
      overflow: 'auto',

      // alignItems: 'center',
   },
   innerContainer: {
      height: '100%',
      maxWidth: MAX_WIDTH,
   },
   progressMargin: {
      marginLeft: theme.spacing(0.5),
   },
   titleStyle: {
      marginBottom: theme.spacing(3),
   },
   inlineStyle: {
      display: 'inline-block',
   },
   selectStyle: {
      marginTop: 3,
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
   largeWidth: {
      maxWidth: MAX_WIDTH,
      minWidth: MAX_WIDTH / 2,
   },
   mediumWidth: {
      maxWidth: MAX_WIDTH * .75,
      minWidth: (MAX_WIDTH * .75) / 2,
   },
   smallWidth: {
      maxWidth: MAX_WIDTH / 2,
      minWidth: MAX_WIDTH / 4,
   },
   cityStyle: {
      marginRight: theme.spacing(1),
   },
   stateStyle: {
      maxWidth: 132,
      minWidth: 75,
   },
   progressStyle: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
   },
   formStyle: {
      height: '100%',
   },
   formContainer: {
      margin: theme.spacing(2),
      height: `calc(100% - 96px)`,
   },
   spinnerStyle: {
      color: 'white',
      marginLeft: theme.spacing(0.5),
   },
   checkboxStyle: {
      marginLeft: theme.spacing(1),
   },
});

/*
transactionId: Int!
transactionNumber: String
eventTypeId: Int
dtlSupplierId: Int
fuelSupplierId: Int
vendorAccountId: Int
productTypeId: Int
timezoneId: Int
locomotiveId: Int
locomotiveIdentifierId: Int
truckId: Int
compartmentId: Int
locationId: Int
cityId: Int
stateId: Int
zipCode: Int
billOfLadingNumber: String
billOfLadingDate: String
fuelBeginTimestamp: String
fuelBeginLatitude: Float
fuelBeginLongitude: Float
fuelBeginBalance: Float
fuelEndTimestamp: String
fuelEndLatitude: Float
fuelEndLongitude: Float
fuelEndBalance: Float
fuelVolumeGross: Float
fuelVolumeNet: Float
fuelTemperature: Float
recircValveOpen: Boolean
manualCapture: Boolean
comment: String
statusId: Int
 */

const TRANSACTION_FRAGMENT = gql`
   fragment transactionInfo on Transaction {
      id
      eventTypeId
      statusId
      driverId
      deliveredToId
      loadingFacilityId
      dtsUnloadBeginTimestamp
      dtsUnloadEndTimestamp
      unitId:truckId
      grossGallons: fuelVolumeGross
      netGallons:fuelVolumeNet
      fuelBeginTimestamp
      fuelEndTimestamp
      timeZoneId:timezoneId
      timezone {
         id
         momentTZCode
      }
      dtlSupplierId
      cityId
      stateId
      locationId
      location {
         isDeleted
         id
         value:id
         label:name
         stateId
      }
      productTypeId
      billOfLadingNumber
      billOfLadingBeginTimestamp
      billOfLadingEndTimestamp
      comment
      ignoreDuplicate
      updatedByUser{
         id
         firstName
         lastName
      }
      submitToId
      locomotiveIdentifierId
      locomotiveId
      locomotiveNumber
      gangIdentifier
      cNumber
      nonLocomotive
      spill
      transactionNumber
      ticketNumber
      unit:truck {
         id
         value:id
         label: identityNumber
         isDeleted
         identityNumber
      }
      validityCheckText
   }
`;

const GET_UNIT_BY_ID = gql`
   query getUnitById($unitId: Int!){
      unit:truck_ById(truckId: $unitId) {
         id
         value:id
         label: identityNumber
         isDeleted
         loadingFacilityList {
            id
            value:id
            label:name
         }
         deliveredToList {
            id
            value: id
            label: name
         }
      }
   }`;

const GET_TRANSACTION = gql`
   query getTransaction($transactionNumber: String!)
   {
      drivers:user_All {
         id
         value:id
         firstName
         lastName
      }

      eventTypes:eventType_All {
         id
         label:name
         value:id
      }

      statuses: status_All {
         id
         value:id
         label:name
      }

      units:truck_All {
         id
         value:id
         label: identityNumber
         location {
            id
            name
         }
      }
      cities:city_All {
         id
         value:id
         label:name
      }
      
      states:state_All {
         id
         value:id
         label:name
      }

      timeZones:timezone_All {
         id
         momentTZCode
         value:id
         label:name
      }

      dtlSuppliers:dtlSupplier_All {
         id
         value:id
         label:name
      }

      locations:location_All {
         id
         value:id
         label:name
         stateId
      }

      productTypes:productType_All {
         id
         value:id
         label:name
      }

      locomotiveIdentifiers:locomotiveIdentifier_All {
         id
         value: id
         label: name
      }

      submitTo: submitTo_All {
         id
         value: id
         label: name
      }
      transaction:transaction_ByTransactionNumber(transactionNumber: $transactionNumber) {
         ...transactionInfo
      }
   }
   ${TRANSACTION_FRAGMENT}
`;

const UPDATE_TRANSACTION = gql`
   mutation UpdateTransaction(
      $id: Int!
      $driverId: Int
      $transactionNumber: String
      $eventTypeId: Int
      $dtlSupplierId: Int
      $deliveredToId: Int
      $fuelSupplierId: Int
      $vendorAccountId: Int
      $productTypeId: Int
      $timeZoneId: Int
      $locomotiveNumber: String
      $nonLocomotive: Boolean
      $locomotiveIdentifierId: Int
      $gangIdentifier: String
      $cNumber: String
      $unitId: Int
      $compartmentId: Int
      $locationId: Int
      $cityId: Int
      $stateId: Int
      $zipCode: Int
      $billOfLadingNumber: String
      $billOfLadingBeginTimestamp: String
      $billOfLadingEndTimestamp: String
      $fuelBeginTimestamp: String
      $fuelBeginLatitude: Float
      $dtsUnloadBeginTimestamp: String
      $dtsUnloadEndTimestamp: String
      $fuelBeginLongitude: Float
      $fuelBeginBalance: Float
      $fuelEndTimestamp: String
      $fuelEndLatitude: Float
      $fuelEndLongitude: Float
      $fuelEndBalance: Float
      $grossGallons: Float
      $netGallons: Float
      $fuelTemperature: Float
      $recircValveOpen: Boolean
      $loadingFacilityId: Int
      $manualCapture: Boolean
      $ignoreDuplicate: Boolean
      $comment: String
      $statusId: Int
      $submitToId: Int
      $spill: Boolean
   ) {
      transactionUpdate:transaction_Update(transactionId: $id, transaction: {
         transactionNumber: $transactionNumber
         eventTypeId: $eventTypeId
         dtlSupplierId: $dtlSupplierId
         deliveredToId: $deliveredToId
         driverId: $driverId
         fuelSupplierId: $fuelSupplierId
         vendorAccountId: $vendorAccountId
         productTypeId: $productTypeId
         timezoneId: $timeZoneId
         locomotiveNumber: $locomotiveNumber
         gangIdentifier: $gangIdentifier
         cNumber: $cNumber
         nonLocomotive: $nonLocomotive
         locomotiveIdentifierId: $locomotiveIdentifierId
         truckId: $unitId
         compartmentId: $compartmentId
         locationId: $locationId
         cityId: $cityId
         stateId: $stateId
         zipCode: $zipCode
         billOfLadingNumber: $billOfLadingNumber
         billOfLadingBeginTimestamp: $billOfLadingBeginTimestamp
         billOfLadingEndTimestamp: $billOfLadingEndTimestamp
         fuelBeginTimestamp: $fuelBeginTimestamp
         fuelBeginLatitude: $fuelBeginLatitude
         dtsUnloadBeginTimestamp: $dtsUnloadBeginTimestamp
         dtsUnloadEndTimestamp: $dtsUnloadEndTimestamp
         fuelBeginLongitude: $fuelBeginLongitude
         fuelBeginBalance:$fuelBeginBalance
         fuelEndTimestamp: $fuelEndTimestamp
         fuelEndLatitude: $fuelEndLatitude
         fuelEndLongitude: $fuelEndLongitude
         fuelEndBalance: $fuelEndBalance
         fuelVolumeGross: $grossGallons
         fuelVolumeNet: $netGallons
         fuelTemperature: $fuelTemperature
         recircValveOpen: $recircValveOpen
         loadingFacilityId: $loadingFacilityId
         manualCapture: $manualCapture
         ignoreDuplicate: $ignoreDuplicate
         comment: $comment
         statusId: $statusId
         submitToId: $submitToId
         spill: $spill
      }) {
         ...transactionInfo
      }
   }
   ${TRANSACTION_FRAGMENT}
`;
const CREATE_TRANSACTION = gql`
   mutation CreateTransaction(
      $driverId: Int
      #      $transactionNumber: String
      $eventTypeId: Int!
      $dtlSupplierId: Int
      $deliveredToId: Int
      $fuelSupplierId: Int
      $vendorAccountId: Int
      $productTypeId: Int
      $timeZoneId: Int!
      $locomotiveNumber: String
      $nonLocomotive: Boolean
      $locomotiveIdentifierId: Int
      $gangIdentifier: String
      $cNumber: String
      $unitId: Int
      $compartmentId: Int
      $locationId: Int
      $cityId: Int
      $stateId: Int
      $zipCode: Int
      $billOfLadingNumber: String
      $billOfLadingBeginTimestamp: String
      $billOfLadingEndTimestamp: String
      $fuelBeginTimestamp: String
      $fuelBeginLatitude: Float
      $dtsUnloadBeginTimestamp: String
      $dtsUnloadEndTimestamp: String
      $fuelBeginLongitude: Float
      $fuelBeginBalance: Float
      $fuelEndTimestamp: String
      $fuelEndLatitude: Float
      $fuelEndLongitude: Float
      $fuelEndBalance: Float
      $grossGallons: Float
      $netGallons: Float
      $fuelTemperature: Float
      $recircValveOpen: Boolean
      $loadingFacilityId: Int
      $manualCapture: Boolean!
      $ignoreDuplicate: Boolean
      $comment: String
      #      $statusId: Int
      $submitToId: Int!
      $spill: Boolean
   ) {
      transaction:transaction_Create(transaction: {
         #         transactionNumber: $transactionNumber
         eventTypeId: $eventTypeId
         dtlSupplierId: $dtlSupplierId
         deliveredToId: $deliveredToId
         driverId: $driverId
         fuelSupplierId: $fuelSupplierId
         vendorAccountId: $vendorAccountId
         productTypeId: $productTypeId
         timezoneId: $timeZoneId
         locomotiveNumber: $locomotiveNumber
         gangIdentifier: $gangIdentifier
         cNumber: $cNumber
         nonLocomotive: $nonLocomotive
         locomotiveIdentifierId: $locomotiveIdentifierId
         truckId: $unitId
         compartmentId: $compartmentId
         locationId: $locationId
         cityId: $cityId
         stateId: $stateId
         zipCode: $zipCode
         billOfLadingNumber: $billOfLadingNumber
         billOfLadingBeginTimestamp: $billOfLadingBeginTimestamp
         billOfLadingEndTimestamp: $billOfLadingEndTimestamp
         fuelBeginTimestamp: $fuelBeginTimestamp
         fuelBeginLatitude: $fuelBeginLatitude
         dtsUnloadBeginTimestamp: $dtsUnloadBeginTimestamp
         dtsUnloadEndTimestamp: $dtsUnloadEndTimestamp
         fuelBeginLongitude: $fuelBeginLongitude
         fuelBeginBalance:$fuelBeginBalance
         fuelEndTimestamp: $fuelEndTimestamp
         fuelEndLatitude: $fuelEndLatitude
         fuelEndLongitude: $fuelEndLongitude
         fuelEndBalance: $fuelEndBalance
         fuelVolumeGross: $grossGallons
         fuelVolumeNet: $netGallons
         fuelTemperature: $fuelTemperature
         recircValveOpen: $recircValveOpen
         loadingFacilityId: $loadingFacilityId
         manualCapture: $manualCapture
         ignoreDuplicate: $ignoreDuplicate
         comment: $comment
         #         statusId: $statusId
         submitToId: $submitToId
         spill: $spill
      }) {
         ...transactionInfo
      }
   }
   ${TRANSACTION_FRAGMENT}
`;

/**
 * The component that shows the Transaction fields.
 */
class TransactionEdit extends Component {

   static propTypes = {
      intl: intlShape,                       // Localization messages.
      classes: PropTypes.object.isRequired,  // The style for the component.
   };

   state = {
      isChanged: false,
   };

   findSelectedOption = (options = [], id, labelKey = 'name', valueKey = 'id', matchKey = 'id') => {
      const selectedOption = find(options, {[matchKey]: id});
      return selectedOption ? {label: selectedOption[labelKey], value: selectedOption[valueKey]} : '';

   };

   getSelectValues = (object, path, defaultValue, getLabel) => {
      const array = get(object, path, defaultValue);
      const convertedArray = [];
      if (array && array.length > 0) {
         for (const item of array) {
            convertedArray.push({label: (getLabel && getLabel(item)) || item, value: item.value || item});
         }
      }
      return convertedArray;
   };

   getLabel = (item) => {
      return `${item.firstName} ${item.lastName}`;
   };

   handleSelectChange = (value, name) => {
      this.setState({
         [name]: value, isChanged: true
      });
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   handleChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
   };

   handleCheckboxChange = ({target}) => {
      this.setState({[target.name]: target.checked, isChanged: true});
   };

   handleRadioChange = ({target}) => {
      this.setState({[target.name]: Boolean(target.value === 'true'), isChanged: true});
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   onDateChange = (name) => (date) => {
      this.setState({[name]: date, isChanged: true});
   };

   /**
    * Callback when the user submits the changes.
    */
   onSubmit = update => e => {
      const {nonLocomotive, spill} = this.state;

      e.preventDefault();
      this.setState({isSaving: true, mutationError: false});
      const isCreate = this.props.match.params.id === 'new';
      const variables = {
         id: isCreate ? undefined : this.transaction.id,
         submitToId: this.transaction.submitToId,
         ...getChangedObject(this.state, this.transaction, [
            'grossGallons', 'netGallons',
         ], number => number ? Number(number) : undefined),
         ...getChangedObject(this.state, this.transaction, [
            'eventTypeId', 'statusId', 'unitId', 'driverId', 'timeZoneId', 'dtlSupplierId', 'locationId', 'submitToId',
            'productTypeId', 'locomotiveIdentifierId', 'loadingFacilityId', 'deliveredToId', 'stateId', 'cityId',
         ], select => {
            if (select === null) {
               return null;
            }
            return select && select.value ? Number(select.value) : undefined
         }),
         ...getChangedObject(this.state, this.transaction, [
            'locomotiveNumber', 'billOfLadingNumber', 'comment', 'gangIdentifier', 'cNumber', 'ignoreDuplicate',
         ]),
         ...getChangedObject(this.state, this.transaction, [
            'fuelBeginTimestamp', 'fuelEndTimestamp', 'dtsUnloadBeginTimestamp',
            'dtsUnloadEndTimestamp', 'billOfLadingBeginTimestamp', 'billOfLadingEndTimestamp',
         ], timestamp => {
            if (timestamp) {
               return zonedTimeToUtc(timestamp, get(this.state, 'timeZoneId.momentTZCode', get(this. transaction, 'timezone.momentTZCode')));
            }else {
               return undefined;
            }
         }),
         nonLocomotive: nonLocomotive === NON_LOCOMOTIVE ? true : (nonLocomotive === LOCOMOTIVE ? false : undefined),
         spill: spill === SPILL ? true : (spill === NO_SPILL ? false : undefined),
      };
      if (isCreate) {
         variables.manualCapture = true;
      }
      update({variables}).then(() => {
         this.setState({isSaving: false, isChanged: false}, () => {
            this.props.history.goBack();
         });
      }).catch(mutationError => {
         console.log(mutationError);
         this.setState({mutationError});
      }).finally(() => {
         this.setState({isSaving: false});
      });
   };

   static convertTimezone = (timestamp, defaultDate, timezone) => {
      return timestamp ? new Date(new Date(timestamp).toLocaleString("en-US", {timeZone: timezone})) : defaultDate;
   };

   updateState = memoizeOne(
      transaction => {
         const isCreate = this.props.match.params.id === 'new';
         const defaultDate = isCreate ? new Date() : undefined;
         const timezone =  get(transaction, 'timezone.momentTZCode');

         const fuelBeginTimestamp = TransactionEdit.convertTimezone(get(transaction, 'fuelBeginTimestamp') , defaultDate, timezone);
         const fuelEndTimestamp = TransactionEdit.convertTimezone(get(transaction, 'fuelEndTimestamp'), defaultDate, timezone);
         const billOfLadingBeginTimestamp = TransactionEdit.convertTimezone(get(transaction, 'billOfLadingBeginTimestamp'), defaultDate, timezone);
         const billOfLadingEndTimestamp = TransactionEdit.convertTimezone(get(transaction, 'billOfLadingEndTimestamp'), defaultDate, timezone);
         const dtsUnloadBeginTimestamp = TransactionEdit.convertTimezone(get(transaction, 'dtsUnloadBeginTimestamp'), defaultDate, timezone);
         const dtsUnloadEndTimestamp = TransactionEdit.convertTimezone(get(transaction, 'dtsUnloadEndTimestamp'), defaultDate, timezone);
         const validityCheckText = get(transaction, 'validityCheckText');

         this.setState({
            fuelBeginTimestamp,
            fuelEndTimestamp,
            billOfLadingBeginTimestamp,
            billOfLadingEndTimestamp,
            dtsUnloadBeginTimestamp,
            dtsUnloadEndTimestamp,
            validityCheckText
         })
      }
      , isEqual);

   updateLocations = memoize((locations = [], transactionLocation) => {
      if (!!transactionLocation && transactionLocation.isDeleted) {
         return [transactionLocation].concat(locations);
      }
      return locations;
   });

   updateUnits = memoize((units = [], transactionUnit = {}) => {
      if (transactionUnit.isDeleted) {
         const {id, value, label} = transactionUnit;
         const transactionOption = {id, value, label};
         return [transactionOption].concat(units);
      }
      return units;
   });

   isLocomotiveRequired = data => {
      const eventTypeId = (this.state.eventTypeId !== undefined && this.state.eventTypeId.value) ||
         (this.state.eventTypeId === undefined && get(data, 'transaction.eventTypeId'));
      return eventTypeId === LOCOMOTIVE_TRANSACTION;
   };

   isNonLocomotiveTransaction = data => {
      const eventTypeId = (this.state.eventTypeId !== undefined && this.state.eventTypeId.value) ||
         (this.state.eventTypeId === undefined && get(data, 'transaction.eventTypeId'));
      return eventTypeId === NON_LOCOMOTIVE_TRANSACTION;
   };

   render() {
      const {intl, classes} = this.props;
      const {isChanged, isSaving, mutationError, validityCheckText} = this.state;
      const isCreate = this.props.match.params.id === 'new';

      return (
         <Query query={GET_TRANSACTION} variables={{transactionNumber: this.props.match.params.id}}
                fetchPolicy={'cache-and-network'}>
            {({loading, error, data = {}}) => (
               <DataLoadingAndErrors isLoading={loading} error={error || mutationError} data={data}
                                     errorId={'transaction.loading.error'} values={{
                  action: !!mutationError ? 'saved' : 'loaded',
                  message: (error && error.message) || (mutationError && mutationError.message)
               }}>
                  {data => {
                     this.transaction = get(data, 'transaction') || {};
                     this.updateState(this.transaction);
                     const locationOptions = this.updateLocations(get(data, 'locations'),
                        get(data, 'transaction.location'));
                     const unitOptions = this.updateUnits(get(data, 'units'), get(data, 'transaction.unit'));
                     const isLocomotiveRequired = this.isLocomotiveRequired(data);
                     const isNonLocomotiveTransaction = this.isNonLocomotiveTransaction(data);
                     const unitId = get(this.state, 'unitId.id', get(data, 'transaction.unit.id'));
                     console.log('unitId', unitId);
                     return (
                        <Query key={'unit'+unitId} query={GET_UNIT_BY_ID} variables={{unitId}} fetchPolicy={'cache-and-network'} skip={!unitId}>
                           {({data:unitData}) => { return (
                              <Mutation mutation={isCreate ? CREATE_TRANSACTION : UPDATE_TRANSACTION}
                                        key={this.props.match.params.id}>
                                 {transactionUpdate => (
                                    <form onSubmit={this.onSubmit(transactionUpdate)} className={classes.formStyle}>
                                       {!loading && (
                                          <Grid container direction={'column'} spacing={0} wrap={'nowrap'}
                                                className={classes.formContainer}>
                                             <Prompt when={isChanged}
                                                     message={() => formatMessage(intl, 'leavePage',
                                                        'Discard changes?')}/>
                                             <Grid item container className={classes.resizingContainer} spacing={0}
                                                   direction={'column'} wrap={'nowrap'}>
                                                <Grid item className={classes.unsizingFlex}>
                                                   <Typography variant={'h5'}
                                                               id={'transaction.edit.title'} values={{
                                                      ticketNumber: get(data, 'transaction.ticketNumber', 'New') ||
                                                         get(data, 'transaction.billOfLadingNumber', 'New') || 'N/A'
                                                   }}/>
                                                   <Typography
                                                      className={`${classes.subtitleStyle} ${classes.titleStyle}`}
                                                      variant={'subtitle2'}>
                                                      {get(data, 'transaction.validityCheckText')}
                                                   </Typography>
                                                </Grid>
                                                <Grid container className={classes.outerContainer} direction={'column'}
                                                      xs={12}>
                                                   <Grid container className={classes.innerContainer}
                                                         direction={'column'}
                                                         xs={12} spacing={2} wrap={'nowrap'}>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            classes={{root: classes.smallWidth}}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.fuelEvent.label'}
                                                                               nowrap/>}
                                                            options={data.eventTypes}
                                                            name={'eventTypeId'}
                                                            required
                                                            defaultValue={get(data, 'transaction.eventTypeId')}
                                                            value={this.state.eventTypeId}
                                                            onChange={this.handleSelectChange}
                                                            isMulti={false}
                                                            disabled={isSaving}
                                                            fullWidth
                                                         />
                                                      </Grid>
                                                      {!isCreate && (
                                                         <Grid item xs={12}>
                                                            <ReactSelect
                                                               classes={{root: classes.smallWidth}}
                                                               name='statusId'
                                                               label={<Typography className={classes.inlineStyle}
                                                                                  variant='inherit'
                                                                                  id={'transaction.status.label'}
                                                                                  nowrap/>}
                                                               required
                                                               options={data.statuses}
                                                               defaultValue={get(data, 'transaction.statusId', 1)}
                                                               value={this.state.statusId}
                                                               onChange={this.handleSelectChange}
                                                               isMulti={false}
                                                               disabled={isSaving}
                                                               fullWidth
                                                            />
                                                         </Grid>
                                                      )}
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            classes={{root: classes.smallWidth}}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               id={'transaction.unit.label'}/>}
                                                            options={unitOptions}
                                                            getLabel={item => `${item.label || 'N/A'} - ${get(item,
                                                               'location.name',
                                                               'N/A')}`}
                                                            name={'unitId'}
                                                            defaultValue={get(data, 'transaction.unitId')}
                                                            value={this.state.unitId}
                                                            onChange={this.handleSelectChange}
                                                            isMulti={false}
                                                            required
                                                            disabled={isSaving}
                                                            fullWidth
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <TextField
                                                            className={classes.smallWidth}
                                                            name='grossGallons'
                                                            placeholder={'2388.9'}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.grossGallons.label'}
                                                                               nowrap/>}
                                                            defaultValue={get(data, 'transaction.grossGallons')}
                                                            value={this.state.grossGallons}
                                                            fullWidth
                                                            required
                                                            disabled={isSaving}
                                                            onChange={this.handleChange}
                                                            id='formatted-numberformat-input'
                                                            InputProps={{
                                                               inputComponent: NumberFormatCustom,
                                                            }}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <TextField
                                                            className={classes.smallWidth}
                                                            name='netGallons'
                                                            placeholder={'2400.5'}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.netGallons.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            required
                                                            defaultValue={get(data, 'transaction.netGallons')}
                                                            value={this.state.netGallons}
                                                            onChange={this.handleChange}
                                                            disabled={isSaving}
                                                            InputProps={{
                                                               inputComponent: NumberFormatCustom,
                                                            }}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <DateTimePicker
                                                            className={classes.smallWidth}
                                                            format={'MM/dd/yyyy HH:mm'}
                                                            name={'fuelBeginTimestamp'}
                                                            ampm={false}
                                                            showTodayButton
                                                            fullWidth
                                                            required
                                                            disabled={isSaving}
                                                            disableFuture
                                                            value={this.state.fuelBeginTimestamp}
                                                            onChange={this.onDateChange('fuelBeginTimestamp')}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.fuelBeginTimestamp.label'}
                                                                               nowrap/>}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            className={classes.mediumWidth}
                                                            name='driverId'
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.driver.label'} nowrap/>}
                                                            fullWidth
                                                            required
                                                            defaultValue={get(data, 'transaction.driverId')}
                                                            value={this.state.driverId}
                                                            onChange={this.handleSelectChange}
                                                            disabled={isSaving}
                                                            isMulti={false}
                                                            options={this.getSelectValues(data, 'drivers', [],
                                                               this.getLabel)}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <DateTimePicker
                                                            className={classes.smallWidth}
                                                            format={'MM/dd/yyyy HH:mm'}
                                                            name={'fuelEndTimestamp'}
                                                            fullWidth
                                                            required
                                                            disabled={isSaving}
                                                            ampm={false}
                                                            disableFuture
                                                            showTodayButton
                                                            defaultValue={get(data, 'transaction.fuelEndTimestamp')}
                                                            value={this.state.fuelEndTimestamp}
                                                            onChange={this.onDateChange('fuelEndTimestamp')}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.fuelEndTimestamp.label'}
                                                                               nowrap/>}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            classes={{root: classes.smallWidth}}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               id={'transaction.fuelingEventTimeZone.label'}/>}
                                                            options={data.timeZones}
                                                            name={'timeZoneId'}
                                                            defaultValue={get(data, 'transaction.timeZoneId')}
                                                            value={this.state.timeZoneId}
                                                            onChange={this.handleSelectChange}
                                                            isMulti={false}
                                                            disabled={isSaving}
                                                            fullWidth
                                                            required
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            className={classes.mediumWidth}
                                                            options={data.dtlSuppliers}
                                                            name='dtlSupplierId'
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.dtlSupplierName.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            required
                                                            defaultValue={get(data, 'transaction.dtlSupplierId')}
                                                            value={this.state.dtlSupplierId}
                                                            onChange={this.handleSelectChange}
                                                            disabled={isSaving}
                                                            isMulti={false}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            className={classes.largeWidth}
                                                            name='locationId'
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.locationName.label'}
                                                                               nowrap/>}
                                                            options={locationOptions}
                                                            fullWidth
                                                            required
                                                            defaultValue={get(data, 'transaction.locationId')}
                                                            value={this.state.locationId}
                                                            onChange={this.handleSelectChange}
                                                            disabled={isSaving}
                                                            isMulti={false}
                                                         />
                                                      </Grid>
                                                      {(isCreate || (validityCheckText && this.state.validityCheckText.match(/City was not found despite gps data/))) && (<>
                                                         <Grid item xs={12}>
                                                            <ReactSelect
                                                               className={classes.largeWidth}
                                                               name='cityId'
                                                               label={<Typography className={classes.inlineStyle}
                                                                                  variant='inherit'
                                                                                  id={'transaction.locationCity.label'}
                                                                                  nowrap/>}
                                                               options={get(data, 'cities')}
                                                               fullWidth
                                                               required
                                                               defaultValue={get(data, 'transaction.cityId')}
                                                               value={this.state.cityId}
                                                               onChange={this.handleSelectChange}
                                                               disabled={isSaving}
                                                               isMulti={false}
                                                            />
                                                         </Grid>
                                                            <Grid item xs={12}>
                                                               <ReactSelect
                                                                  className={classes.largeWidth}
                                                                  name='stateId'
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={'transaction.locationState.label'}
                                                                                     nowrap/>}
                                                                  options={get(data, 'states')}
                                                                  fullWidth
                                                                  required
                                                                  defaultValue={get(data, 'transaction.stateId')}
                                                                  value={this.state.stateId}
                                                                  onChange={this.handleSelectChange}
                                                                  disabled={isSaving}
                                                                  isMulti={false}
                                                               />
                                                            </Grid>
                                                      </>)}
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            classes={{root: classes.smallWidth}}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               id={'transaction.productType.label'}/>}
                                                            options={data.productTypes}
                                                            name={'productTypeId'}
                                                            defaultValue={get(data, 'transaction.productTypeId')}
                                                            value={this.state.productTypeId}
                                                            onChange={this.handleSelectChange}
                                                            isMulti={false}
                                                            fullWidth
                                                            required
                                                            disabled={isSaving}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            name={'submitToId'}
                                                            className={classes.smallWidth}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               id={'transaction.submitTo.label'}/>}
                                                            defaultValue={get(data, 'transaction.submitToId')}
                                                            value={this.state.submitToId}
                                                            onChange={this.handleSelectChange}
                                                            options={data.submitTo}
                                                            fullWidth
                                                            required
                                                            disabled={isSaving}
                                                            isMulti={false}
                                                         />
                                                      </Grid>
                                                      {!isNonLocomotiveTransaction ? (
                                                         <Fragment>
                                                            <Grid item xs={12}>
                                                               <ReactSelect
                                                                  className={classes.smallWidth}
                                                                  name='locomotiveIdentifierId'
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={isLocomotiveRequired ?
                                                                                        'transaction.locomotiveIdInitial.label' :
                                                                                        'transaction.locomotiveIdInitialOptional.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  required={isLocomotiveRequired}
                                                                  isClearable={!isLocomotiveRequired}
                                                                  defaultValue={get(data,
                                                                     'transaction.locomotiveIdentifierId')}
                                                                  value={this.state.locomotiveIdentifierId}
                                                                  onChange={this.handleSelectChange}
                                                                  disabled={isSaving}
                                                                  options={data.locomotiveIdentifiers}
                                                                  isMulti={false}
                                                               />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                               <TextField
                                                                  className={classes.smallWidth}
                                                                  name='locomotiveNumber'
                                                                  required={isLocomotiveRequired}
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={isLocomotiveRequired ?
                                                                                        'transaction.locomotiveIdNumber.label' :
                                                                                        'transaction.locomotiveIdNumberOptional.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  defaultValue={get(data,
                                                                     'transaction.locomotiveNumber')}
                                                                  value={this.state.locomotiveNumber}
                                                                  onChange={this.handleChange}
                                                                  disabled={isSaving}
                                                               />
                                                            </Grid>
                                                         </Fragment>
                                                      ) : (
                                                         <Fragment>
                                                            <Grid item xs={12}>
                                                               <TextField
                                                                  className={classes.smallWidth}
                                                                  name='gangIdentifier'
                                                                  required
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={'transaction.gangIdentifier.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  defaultValue={get(data, 'transaction.gangIdentifier')}
                                                                  value={this.state.gangIdentifier}
                                                                  onChange={this.handleChange}
                                                                  disabled={isSaving}
                                                               />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                               <TextField
                                                                  className={classes.smallWidth}
                                                                  name='cNumber'
                                                                  required
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={'transaction.cNumber.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  defaultValue={get(data, 'transaction.cNumber')}
                                                                  value={this.state.cNumber}
                                                                  onChange={this.handleChange}
                                                                  disabled={isSaving}
                                                               />
                                                            </Grid>
                                                         </Fragment>
                                                      )}
                                                      <Grid item xs={12}>
                                                         <FormControl component='fieldset' disabled={isSaving}>
                                                            <RadioGroup
                                                               aria-label='Non-Locomotive'
                                                               row
                                                               name='nonLocomotive'
                                                               defaultValue={get(data, 'transaction.nonLocomotive') ===
                                                               true ? NON_LOCOMOTIVE : LOCOMOTIVE}
                                                               value={this.state.nonLocomotive}
                                                               onChange={this.handleChange}
                                                            >
                                                               <FormControlLabel value={LOCOMOTIVE} control={<Radio/>}
                                                                                 label='Locomotive'/>
                                                               <FormControlLabel value={NON_LOCOMOTIVE}
                                                                                 control={<Radio/>}
                                                                                 label='Non-Locomotive'/>
                                                            </RadioGroup>
                                                         </FormControl>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <TextField
                                                            className={classes.mediumWidth}
                                                            name='billOfLadingNumber'
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.billOfLadingNumber.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            defaultValue={get(data, 'transaction.billOfLadingNumber')}
                                                            value={this.state.billOfLadingNumber}
                                                            onChange={this.handleChange}
                                                            disabled={isSaving}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <DateTimePicker
                                                            className={classes.smallWidth}
                                                            format={'MM/dd/yyyy HH:mm'}
                                                            name={'billOfLadingBeginTimestamp'}
                                                            ampm={false}
                                                            fullWidth
                                                            defaultValue={get(data,
                                                               'transaction.billOfLadingBeginTimestamp')}
                                                            value={this.state.billOfLadingBeginTimestamp}
                                                            onChange={this.onDateChange('billOfLadingBeginTimestamp')}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.billOfLadingBeginTimestamp.label'}
                                                                               nowrap/>}
                                                            disabled={isSaving}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <DateTimePicker
                                                            className={classes.smallWidth}
                                                            format={'MM/dd/yyyy HH:mm'}
                                                            name={'billOfLadingEndTimestamp'}
                                                            ampm={false}
                                                            fullWidth
                                                            defaultValue={get(data,
                                                               'transaction.billOfLadingEndTimestamp')}
                                                            value={this.state.billOfLadingEndTimestamp}
                                                            onChange={this.onDateChange('billOfLadingEndTimestamp')}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.billOfLadingEndTimestamp.label'}
                                                                               nowrap/>}
                                                            disabled={isSaving}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            className={classes.smallWidth}
                                                            name='loadingFacilityId'
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.loadingFacility.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            isMulti={false}
                                                            placeholder={'005'}
                                                            defaultValue={get(data, 'transaction.loadingFacilityId')}
                                                            value={this.state.loadingFacilityId}
                                                            onChange={this.handleSelectChange}
                                                            disabled={isSaving}
                                                            options={get(unitData, 'unit.loadingFacilityList',
                                                               [])}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <FormControl component='fieldset' disabled={isSaving}>
                                                            <RadioGroup
                                                               aria-label='Spill'
                                                               row
                                                               name='spill'
                                                               defaultValue={get(data, 'transaction.spill') === true ?
                                                                  SPILL : NO_SPILL}
                                                               value={this.state.spill}
                                                               onChange={this.handleChange}
                                                            >
                                                               <FormControlLabel value={NO_SPILL} control={<Radio/>}
                                                                                 label='No Spill'/>
                                                               <FormControlLabel value={SPILL} control={<Radio/>}
                                                                                 label='Spill'/>
                                                            </RadioGroup>
                                                         </FormControl>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <Grid container xs={12} spacing={1}
                                                               style={{maxWidth: MAX_WIDTH + 16}}>
                                                            <Grid item xs={12} sm={6}>
                                                               <InlineTimePicker
                                                                  name={'dtsUnloadBeginTimestamp'}
                                                                  ampm={false}
                                                                  autoOk
                                                                  disableFuture
                                                                  value={this.state.dtsUnloadBeginTimestamp}
                                                                  onChange={this.onDateChange(
                                                                     'dtsUnloadBeginTimestamp')}
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={'transaction.dtsUnloadStartTime.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  disabled={isSaving}
                                                               />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                               <InlineTimePicker
                                                                  name={'dtsUnloadEndTimestamp'}
                                                                  ampm={false}
                                                                  autoOk
                                                                  disableFuture
                                                                  value={this.state.dtsUnloadEndTimestamp}
                                                                  onChange={this.onDateChange('dtsUnloadEndTimestamp')}
                                                                  label={<Typography className={classes.inlineStyle}
                                                                                     variant='inherit'
                                                                                     id={'transaction.dtsUnloadEndTime.label'}
                                                                                     nowrap/>}
                                                                  fullWidth
                                                                  disabled={isSaving}
                                                               />
                                                            </Grid>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <ReactSelect
                                                            name='deliveredToId'
                                                            className={classes.mediumWidth}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.deliveredTo.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            isMulti={false}
                                                            defaultValue={get(data, 'transaction.deliveredToId')}
                                                            value={this.state.deliveredToId}
                                                            onChange={this.handleSelectChange}
                                                            disabled={isSaving}
                                                            options={get(unitData, 'unit.deliveredToList', [])}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <FormControlLabel
                                                            className={classes.checkboxStyle}
                                                            control={
                                                               <Checkbox
                                                                  name={'ignoreDuplicate'}
                                                                  disableRipple
                                                                  defaultChecked={get(data, 'transaction.ignoreDuplicate')}
                                                                  checked={this.state.ignoreDuplicate}
                                                                  onChange={this.handleCheckboxChange}
                                                                  value='ignoreDuplicate'
                                                               />
                                                            }
                                                            label={<Typography id={'transaction.duplicate.label'}/>}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                         <TextField
                                                            name='comment'
                                                            className={classes.largeWidth}
                                                            label={<Typography className={classes.inlineStyle}
                                                                               variant='inherit'
                                                                               id={'transaction.comment.label'}
                                                                               nowrap/>}
                                                            fullWidth
                                                            defaultValue={get(data, 'transaction.comment')}
                                                            value={this.state.comment}
                                                            onChange={this.handleChange}
                                                            disabled={isSaving}
                                                            rows={2}
                                                            multiline={true}
                                                         />
                                                      </Grid>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                             <Grid item style={{padding: 0}}>
                                                {!isCreate && (
                                                   <Typography variant={'subtitle2'} style={{marginTop: 16}}>
                                                      {`Last Update By: ${get(data,
                                                         'transaction.updatedByUser.firstName', '')} ${get(data,
                                                         'transaction.updatedByUser.lastName', '')}`}
                                                   </Typography>
                                                )}
                                                <Button variant='contained' color='secondary' className={classes.button}
                                                        onClick={this.handleFilter} type={'submit'} disabled={isSaving}>
                                                   <SaveIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                                                   <Typography color='inherit' id={'save.label'}>Save</Typography>
                                                   {isSaving &&
                                                   <CircularProgress className={classes.spinnerStyle} size={15}
                                                                     thickness={2.5}/>}
                                                </Button>
                                             </Grid>
                                          </Grid>
                                       )}
                                    </form>
                                 )}
                              </Mutation>
                           )}}
                        </Query>
                     )
                  }}
               </DataLoadingAndErrors>
            )
            }
         </Query>
      );
   }
}

export default injectIntl(withRouter(withStyles(styles)(TransactionEdit)));
