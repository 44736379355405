import InputBase from '@material-ui/core/InputBase';
import {withStyles, createStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

const styles = (theme) =>
   createStyles({
      search: {
         '& ::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 0.80, /* Firefox */
         },

         '& :-ms-input-placeholder': { /* Internet Explorer 10-11 */
            opacity: 0.80,
         },

         '& ::-ms-input-placeholder': { /* Microsoft Edge */
            opacity: 0.80,
         },

         position: 'relative',
         borderRadius: theme.shape.borderRadius,
         backgroundColor: fade(theme.palette.common.white, 0.50),
         '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.60),
         },
         marginLeft: 0,
         width: '100%',
         [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
         },
      },
      searchIcon: {
         width: theme.spacing(9),
         height: '100%',
         position: 'absolute',
         pointerEvents: 'none',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
      },
      inputRoot: {
         color: 'inherit',
         width: '100%',
      },
      inputInput: {
         paddingTop: theme.spacing(1),
         paddingRight: theme.spacing(1),
         paddingBottom: theme.spacing(1),
         paddingLeft: theme.spacing(10),
         transition: theme.transitions.create('width'),
         width: '100%',
         [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
               width: 200,
            },
         },
      },
   });

/**
 * The Search component that handles entering a search value.
 */
class Search extends PureComponent {
   static propTypes = {
      classes: PropTypes.any,
      value: PropTypes.string,
      onChange: PropTypes.func
   };

   state = {
      search: '',
   };

   constructor(props, context) {
      super(props, context);

      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape' && event.target.name === 'search') {
            event.stopPropagation();
            event.preventDefault();
            this.onCancelSearch();
         }
      }
   };

   componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.value !== this.props.value && nextProps.value !== this.state.search) {
         this.setState({search: nextProps.value});
      }
   }

   /**
    * When search data has changed.
    * @param target The change event.
    */
   handleChange = ({target}) => {
      this.setState({search: target.value}, this.handleSearchChange);
   };

   /**
    * When the user presses a key, if it is the escape key clear the search.
    * @param event The key down event.
    */
   onKeydown = (event) => {
      if (event.key === 'Escape') {
         event.stopPropagation();
         event.preventDefault();
         this.onCancelSearch();
      }
   };

   /**
    * When the search is canceled, clear the search field.
    */
   onCancelSearch = () => {
      this.setState({search: ''}, this.handleSearchChange);
   };

   /**
    * After debounce, perform the actual search of data for the search string the user entered.
    * @type {Function} the debounced function.
    */
   handleSearchChange = debounce(async () => {
      const {onChange} = this.props;
      onChange && onChange(this.state.search);
   }, 750);

   render() {
      const {classes, placeholder} = this.props;
      const {search} = this.state;

      return (
         <div className={classes.search}>
            <div className={classes.searchIcon}>
               <SearchIcon/>
            </div>
            <InputBase
               name={'search'}
               value={search}
               // inputProps={{onKeyDown: this.onKeyDown}}
               onChange={this.handleChange}
               placeholder={placeholder || 'Search…'}
               classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
               }}
            />
         </div>
      );
   }
}

export default withStyles(styles)(Search);