import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {format} from 'date-fns-tz';
import gql from 'graphql-tag';
import _, {find} from 'lodash';
import PropTypes from 'prop-types';
import React, {createRef, PureComponent} from 'react';
import Query from 'react-apollo/Query';
import withRouter from 'react-router-dom/withRouter';
import 'react-table/react-table.css';
import {DATE_FORMAT_TIME_ZONE, PDF_DOWNLOAD_ICON, PDF_BASE_PATH} from '../Constants';
import DashboardTable from '../fhg/components/DashboardTable';
import DisplayError from '../fhg/components/DisplayError';
import Typography from '../fhg/components/Typography';

// Styles for the table.
const styles = theme => ({
   outerFrame: {
      margin: theme.spacing(0, 2, 2, 2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      overflow: 'hidden',
      height: '100%',
   },
});

const driverVehicleInspectionReport = gql`
   query getDriverVehicleInspectionReport($startDate: String, $endDate: String, $safeOnly: Boolean, $driverId: [Int], $identityNumber: [String], $defectsCorrected: [Boolean], $conditionSatisfactory: [Boolean], $correctionNotRequired: [Boolean])
   {
      driverVehicleInspectionReport:dvir_AllWhere(startDate: $startDate, endDate: $endDate, safeOnly: $safeOnly,
         dvirSearch: {driverId: $driverId, identityNumber: $identityNumber, defectsCorrected: $defectsCorrected, 
            conditionSatisfactory: $conditionSatisfactory, correctionNotRequired: $correctionNotRequired
         }) {
         id
         identityNumber
         driverId
         date:createdDateTime
         defectsCorrected
         conditionSatisfactory
         correctionNotRequired
      }
      users:user_All {
         id
         value:id
         firstName
         lastName
      }
   }
`;

/**
 * The table of data for the devices.
 */
class DriverVehicleInspectionTable extends PureComponent {
   tableRef = createRef();

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      search: PropTypes.string,                 // A search string for filtering the table.
      isLoading: PropTypes.bool,                // Indicates if the table data is being loaded.
      disabled: PropTypes.bool,                 // True if the table should be disabled.
      onQuery: PropTypes.func,                  // Supplied by the withRequest HOC to perform the query on the server.
   };

   handlePdfDownload = (reportId) => () => {
      this.props.history.push(`${PDF_BASE_PATH}/${reportId}`);
   };

   /*
      Unit Numbers
      Vehicle condition
      Driver
      Date Range
    */
   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   getColumns = (users) => (driverVehicleData)=> {
      return [
         {
            id: 'PDF',
            Cell: row => (
               <img alt={'PDF download button'} src={PDF_DOWNLOAD_ICON} height={40} onClick={this.handlePdfDownload(row.original.id)}/>
            ),
            sortable: false,
            width: 50,
         }, {
            id: 'Tractor/Truck Number',
            Header: <Typography id={'vehicleInspection.unit.column'}/>,
            accessor: 'identityNumber',
            minWidth: 125,
         }, {
            id: 'Condition',
            Header: <Typography id={'vehicleInspection.condition.column'}/>,
            // accessor: 'defectsCorrected',
            minWidth: 100,
            accessor: row => {
               if (row.defectsCorrected) {
                  return 'Defects Repaired';
               } else if (row.conditionSatisfactory || row.correctionNotRequired) {
                  return 'Safe';
               } else {
                  return 'Not Safe';
               }
            }
         }, {
            id: 'Driver',
            Header: <Typography id={'vehicleInspection.driver.column'}/>,
            accessor: 'driverId',
            minWidth: 125,
            Cell: row => {
               const user = find(users, {id: row.value});
               return user ? `${user.firstName} ${user.lastName}` : 'N/A';
            }
         }, {
            id: 'Date',
            Header: <Typography id={'vehicleInspection.startDate.column'}/>,
            accessor: 'date',
            Cell: row => {
               if (row.value) {
                  const dateInTimezone = new Date(new Date(row.value));
                  return format(dateInTimezone, DATE_FORMAT_TIME_ZONE);
               } else {
                  return 'N/A';
               }
            },
            minWidth: 185,
         },
      ]
   };

   render() {
      let {classes, disabled, search, tableRef, filters} = this.props;

      return (
         <Query query={driverVehicleInspectionReport} variables={filters} skip={filters === undefined} fetchPolicy={'network-only'}>
            {({loading, error, data}) => (
               <Grid item className={classes.outerFrame}>
                  <DisplayError error={error}/>

                  <DashboardTable
                     name='Driver Vehicle Inspection Report'
                     innerRef={tableRef}
                     data={data && data.driverVehicleInspectionReport}
                     getColumns={this.getColumns(data && data.users)}
                     isLoading={loading}
                     disabled={disabled}
                     search={search}
                  />
               </Grid>
            )}
         </Query>
      );
   }
}

// The initial fetch will be only the data from today.
export default withRouter(withStyles(styles)(DriverVehicleInspectionTable));
