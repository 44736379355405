import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/es/styles/withStyles';
import PropTypes from 'prop-types';
import React, {createRef, PureComponent} from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import DriverVehicleInspectionReportPdf from '../components/DriverVehicleInspectionReportPdf';
import NavigationDrawer from '../components/NavigationDrawer';
import SearchAppBar from '../components/SearchAppBar';
import {ADMIN_GROUP, PDF_PATH, SETTINGS_PATH} from '../Constants';
import withGraphql from '../withGraphql';
import withIntl, {getCurrentLocale} from '../withIntl';
import withRoot from '../withRoot';
import {MyTheme} from './App';
import DriverVehicleInspectionReport from './DriverVehicleInspectionReport';
import LocationEdit from './LocationEdit';
import LocationList from './LocationList';
import MySignUp from './MySignUp';
import RemoveUserAccount from './RemoveUserAccount';
import SettingsEdit from './SettingsEdit';
import TransactionEdit from './TransactionEdit';
import UnitBalanceReport from './UnitBalanceReport';
import UnitDetailReport from './UnitDetailReport';
import UnitEdit from './UnitEdit';
import UnitList from './UnitList';
import get from 'lodash/get';
import Typography from '../fhg/components/Typography';

import Auth from '@aws-amplify/auth';

const styles = theme => ({
   root: {
      display: 'flex',
      overflow: 'hidden',
      height: '100vh',
      fontFamily: theme.typography.fontFamily,
   },
   content: {
      flexGrow: 1,
      height: '100vh',
      // padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
   },
   toolbar: theme.mixins.toolbar,
});

class Main extends PureComponent {
   static propTypes = {
      classes: PropTypes.object.isRequired,
   };

   state = {};

   constructor(props) {
      super(props);

      this.reactRefs = {};
      this.reactRefs[encodeURIComponent('/admin/report/balance')] = createRef();
      this.reactRefs[encodeURIComponent('/admin/report/detail')] = createRef();
      this.reactRefs[encodeURIComponent('/admin/report/inspection')] = createRef();
      this.reactRefs[encodeURIComponent('/admin/report/unit')] = createRef();
      this.reactRefs[encodeURIComponent('/admin/report/location')] = createRef();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      // Cancel the search when the user navigates to new page. Unexpected to continue searching.
      if (prevProps.location.pathname !== this.props.location.pathname) {
         this.setState({searchText: undefined});
      }
   }

   handleSearch = (searchText) => {
      this.setState({searchText});
   };

   handleFilter = (filters) => {
      this.setState({filters});
   };

   handleDownload = () => {
      const reactRef = this.reactRefs[encodeURIComponent(this.props.location.pathname)];
      if (reactRef) {
         reactRef.current.downloadToCsv();
      }
   };

   handleSignUp = () => {
      this.setState({showCreateAccount: true})
   };

   handleCloseSignUp = () => {
      this.setState({showCreateAccount: false})
   };

   handleRemoveAccount = () => {
      this.setState({showRemoveAccount: true})
   };

   handleCloseRemoveAccount = () => {
      this.setState({showRemoveAccount: false})
   };

   render() {
      const {classes, authData, authState} = this.props;
      const {showCreateAccount, showRemoveAccount} = this.state;

      const isReport = this.props.location.pathname.indexOf('report') >= 0 && this.props.location.pathname.indexOf('pdf') < 0;
      const groups = get(authData, 'signInUserSession.idToken.payload["cognito:groups"]', []);

      const isAdmin = groups.indexOf(ADMIN_GROUP) >= 0;

      if (authState !== 'signIn') {
         if (isAdmin) {
            if (this.clearTimeout) {
               clearTimeout(this.clearTimeout);
               this.clearTimeout = undefined;
            }
            return (
               <div className={classes.root}>
                  <CssBaseline/>
                  <SearchAppBar key={`SearchBar-${this.props.location.pathname}`} onSearch={this.handleSearch}
                                onDownloadToCsv={this.handleDownload} showCsv={isReport}
                                showSearch={isReport} onShowSignup={this.handleSignUp}
                                onShowRemove={this.handleRemoveAccount} isAdmin={isAdmin}/>
                  <NavigationDrawer/>
                  <main className={classes.content}>
                     <div className={classes.toolbar}/>
                     {showRemoveAccount ? (
                        <RemoveUserAccount onClose={this.handleCloseRemoveAccount}/>
                     ) : <>
                        {showCreateAccount ? (
                           <Dialog open={true} maxWidth={'md'}>
                              <MySignUp theme={MyTheme} onClose={this.handleCloseSignUp}/>
                           </Dialog>
                        ) : (
                           <Switch>
                              <Route exact path='/admin/report/balance'
                                     render={props => <UnitBalanceReport
                                        tableRef={this.reactRefs[encodeURIComponent(props.location.pathname)]}
                                        search={this.state.searchText}/>}
                              />
                              <Route exact path='/admin/report/detail'
                                     render={props => <UnitDetailReport
                                        tableRef={this.reactRefs[encodeURIComponent(props.location.pathname)]}
                                        search={this.state.searchText}/>}
                              />
                              <Route exact path='/admin/report/inspection'
                                     render={props => <DriverVehicleInspectionReport
                                        tableRef={this.reactRefs[encodeURIComponent(props.location.pathname)]}
                                        search={this.state.searchText}/>}
                              />
                              <Route exact path={PDF_PATH}>
                                 <DriverVehicleInspectionReportPdf/>
                              </Route>
                              <Route exact path='/admin/report/unit'
                                     render={props => <UnitList
                                        tableRef={this.reactRefs[encodeURIComponent(props.location.pathname)]}
                                        search={this.state.searchText}/>}
                              />
                              <Route exact path='/admin/report/location'
                                     render={props => <LocationList
                                        tableRef={this.reactRefs[encodeURIComponent(props.location.pathname)]}
                                        search={this.state.searchText}/>}
                              />
                              <Route exact path='/admin/edit/unit/:id' component={UnitEdit}/>
                              <Route exact path='/admin/edit/transaction/:id' component={TransactionEdit}/>
                              <Route exact path='/admin/edit/location/:id' component={LocationEdit}/>
                              <Route exact path={SETTINGS_PATH} component={SettingsEdit}/>
                              <Route path='/' render={() => <Redirect to='/admin/report/balance'/>}/>
                           </Switch>
                        )}
                     </>}
                  </main>
               </div>
            );
         } else {
            this.clearTimeout = setTimeout(() => {
               Auth.signOut();
            }, 10000);
            return <Typography variant={'h6'} id={'noPermission.message'} style={{margin: 32}}/>
         }
      } else {
         return null;
      }
   }
}

let messages;
try {
   messages = require(`../messages/${getCurrentLocale()}`);
} catch (e) {
   messages = require('../messages/en-US');
}

const getAccessToken = () => {
   return Auth.currentSession()
      .then(session => {
         return session.accessToken.jwtToken;
      })
      .catch(error => {
         console.log(error);
         throw error;
      });
};
export default withGraphql(getAccessToken)(withIntl({messages: messages})(withRoot(withRouter(withStyles(styles)(Main)))));
