import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import isSameDay from 'date-fns/isSameDay';
import isSameHour from 'date-fns/isSameHour';
import isSameMinute from 'date-fns/isSameMinute';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import {isEmpty} from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {DATE_FORMAT, MAX_CSV_ROWS} from '../../Constants';
import {stringToBoolean, b64EncodeUnicode} from '../utils/Utils';
import './DashboardTable.css';
import ErrorSnackbar from './ErrorSnackbar';
import Typography from './Typography';


const styles = theme => ({
   tableStyle: {
      flex: '1 1',
      maxHeight: '100%',
      maxWidth: '100%',
      border: 'none',
      '& .-sort-desc .rt-resizer::before': {
         content: '"keyboard_arrow_down"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
         position: 'absolute',
         top: '50%',
         transform: 'translateY(-50%)',
},
      '& .-sort-asc .rt-resizer::before': {
         content: '"keyboard_arrow_up"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
         position: 'absolute',
         top: '50%',
         transform: 'translateY(-50%)',
      },
      '& .rt-th, .rt-td': {
         marginTop: 'auto',
         marginBottom: 'auto',
      },
   },
   emptyHeight: {
      height: `calc(100% - ${theme.spacing(4) + 12}px) !important`,
      minHeight: 200,
   },
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
   },
   saveProgressStyle: {
      marginLeft: 'auto',
      position: 'relative',
      marginRight: 'auto',
   },
   selectedStyle: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
});

/**
 * The table containing the data.
 */
class DashboardTable extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      getColumns: PropTypes.func.isRequired,    // Get all the columns to display in the table.
      data: PropTypes.array,                    // The data to display.
      search: PropTypes.string,                 // A search string for filtering the table.
      isLoading: PropTypes.bool,                // Indicates if the data is loading (e.g from the server).
      disabled: PropTypes.bool,                 // Indicates if the table should be disabled.
      onDoubleClick: PropTypes.func,            // Callback when the row in the table is doubled clicked.
      onClick: PropTypes.func,                  // Callback when the row in the table is clicked.
      defaultSort: PropTypes.any,               // The default sort for the table.
      selectedId: PropTypes.number,             // The id of the selected row.
   };

   static defaultProps = {
      isLoading: false,
      disabled: false,
      data: [],
      defaultSort: {sort: undefined}
   };

   constructor(props, context) {
      // noinspection JSCheckFunctionSignatures
      super(props, context);

      // Read the table settings from session storage to restore when the user comes back to this page.
      const dashboardTableString = sessionStorage.getItem('dashboardTable' + this.props.name);
      const dashboardTable = dashboardTableString ? JSON.parse(dashboardTableString) : props.defaultSort;
      const search = get(this.props, 'location.search');
      const selectId = search && search.indexOf('select=') >= 0 && Number(search.substring(8));
      this.state = {
         selectedId: selectId || dashboardTable.selectedId || -1,
         defaultSort: dashboardTable.sort,
         filteredData: this.onSearch(props.search, props.getColumns(), props.data),
         originalData: props.data.slice(0),
      };
   }

   // noinspection JSCheckFunctionSignatures
   componentWillReceiveProps(nextProps) {
      const {search} = this.props;

      const isDataEqual = isEqual(this.state.originalData, nextProps.data);
      if (search !== nextProps.search || !isDataEqual) {
         const filteredData = this.onSearch(nextProps.search, nextProps.getColumns(), nextProps.data);
         this.setState({filteredData, originalData: nextProps.data.slice(0)});
      }
   }

   componentDidUpdate() {
      this.scrollIntoView();
   }

   handleSelect = (item, index, callback) => {
      const {onClick} = this.props;
      const selectedId =  item.id !== undefined ? item.id : index;

      const dashboardTableString = sessionStorage.getItem('dashboardTable' + this.props.name);
      const dashboardTable = dashboardTableString ? JSON.parse(dashboardTableString) : this.props.defaultSort;
      dashboardTable.selectedId = selectedId;
      sessionStorage.setItem('dashboardTable' + this.props.name, JSON.stringify(dashboardTable));

      this.setState({selectedId}, () => {
         onClick && onClick(item);
         callback && callback(item);
      });
   };

   /**
    * When the user changes the sort. Save to the session storage.
    * @param newSorted
    */
   onSortedChange = (newSorted) => {
      sessionStorage.setItem('dashboardTable' + this.props.name, JSON.stringify({sort: newSorted, selectedId: this.state.selectedId}));
   };

   /**
    * Checks if the row is selected.
    * @param row The row to check.
    * @return {*|boolean} True if the row is selected.
    */
   isRowSelected = (row) => {
      if (row) {
         return row.original.id !== undefined ? row.original.id === this.state.selectedId : row.index === this.state.selectedId;
      }
      return false;
   };

   /**
    * Scroll the selected row into view.
    */
   scrollIntoView = () => {
      const elements = document.getElementsByClassName(this.props.classes.selectedStyle);
      let objDiv = document.getElementsByClassName('rt-tbody')[0];
      if (objDiv && elements.length > 0) {
         objDiv.scrollTop = elements[ 0 ].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
            (elements[ 0 ].offsetHeight / 2);
      }
   };

   /**
    * When the user searches the table.
    * @param search The search text to find.
    * @param columns The table columns. Used for the accessor to get the data from the column to search.
    * @param data The table data.
    * @return {*|Array} The array of filtered rows.
    */
   onSearch = (search, columns, data) => {
      let filteredData = [];
      let dateSearch = parse(search, DATE_FORMAT, new Date());
      const isSearchDate = isValid(dateSearch);
      let isSearchHour = getHours(dateSearch);
      let isSearchMinute = getMinutes(dateSearch);
      let isSearchDay = isSearchDate && !isSearchHour && !isSearchMinute;

      if (search) {
         search = search.toLocaleLowerCase().trim();
         search = stringToBoolean(search);
         filteredData = data.filter(item => {
            for (let column of columns) {
               let columnAccessor = column.accessor;
               let value = typeof columnAccessor === 'function' ? columnAccessor(item) : get(item, columnAccessor);
               if (column.Cell) {
                  value = column.Cell({value, original: item});
               }

               if (value !== undefined && value !== null && value !== '' && typeof value !== 'object') {
                  const valueDate = parse(value, DATE_FORMAT, new Date());
                  // noinspection JSCheckFunctionSignatures
                  if ((isSearchDate && (isSearchHour && isSameHour(dateSearch, valueDate))) ||
                     (isSearchMinute && isSameMinute(dateSearch, valueDate)) ||
                     (isSearchDay && isSameDay(dateSearch, valueDate))) {
                     return true;
                  }
                  const valueString = value.toString();
                  if (valueString.toLocaleLowerCase().indexOf(search) >= 0) {
                        return true;
                     }
               }
            }
            return false;
         });
      } else {
         filteredData = isEmpty(data) ? [] : data;
      }

      return filteredData;
   };

   /**
    * Download the table data as a CSV file. Automatically triggers the download.
    */
   downloadToCsv = () => {
      const {filteredData} = this.state;

      if (filteredData.length > MAX_CSV_ROWS) {
         this.setState({showError: true, errorMessageId: 'csv.exceededMaximumRows.error', errorValues: {maxRows: '18,000'}});
         return;
      }
      const columns = this.props.getColumns();
      const data = [];
      this.setState({isSaving: true, showError: false, errorMessageId: undefined, errorValues: undefined});
      try {

      for (let item of filteredData) {
         const row = [];
         for (let column of columns) {
            let columnAccessor = column.accessor;
            let value = typeof columnAccessor === 'function' ? columnAccessor(item) : get(item, columnAccessor);
            if (column.Cell) {
                  const cellValue = column.Cell({value, original: item});
                  if (typeof cellValue !== 'object') {
                     value = cellValue;
                  }
            }
            row.push(value);
         }
         data.push(row);
      }
         let csv = Papa.unparse({fields: columns.map(column => column.id), data}, {quotes: true});
      let link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8;base64,' + b64EncodeUnicode(csv);
      link.target = '_blank';
      link.download = `McAlister Dashboard - ${this.props.name} ${(new Date()).toLocaleString()}.csv`;

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      } catch (e) {
         console.log(e);
         this.setState({showError: this, errorMessageId: 'csv.download.error', errorValues: e});
      } finally {
         this.setState({isSaving: false});
      }
   };

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({ showError: false });
   };

   render() {
      let {defaultSort, filteredData = [], isSaving, showError, errorMessageId, errorMessage, errorValues, } = this.state;
      let {classes, isLoading = false, getColumns, onDoubleClick, disabled } = this.props;

      return (
         <React.Fragment>
            {isSaving && (
               <Grid container alignItems={'center'} justify={'center'} direction={'column'}>
                  <Typography>Downloading to CSV</Typography>
                  <CircularProgress className={classes.saveProgressStyle}/>
               </Grid>
            )}
            <ErrorSnackbar open={showError} onClose={this.handleErrorClose} errorId={errorMessageId}
                           values={errorValues} message={errorMessage} enableRefresh={false}
            />
            <ReactTable defaultPageSize={1000000} minRows={1} loading={isLoading}
                     LoadingComponent={() => isLoading && <Grid container alignItems={'center'}> <CircularProgress
                        className={classes.progressStyle}/></Grid>}
                     multiSort={false} className={classNames(classes.tableStyle, '-highlight',
            {[classes.emptyHeight]: !filteredData || filteredData.length <= 0})}
                     data={filteredData}
                     defaultSorted={defaultSort}
                     noDataText={this.props.isLoading ? ' ' : 'No data available.'}
                     onSortedChange={this.onSortedChange}
                     showPagination={false}
                        getTrProps={(state, rowInfo) => {
                           return {
                              className: this.isRowSelected(rowInfo) ? classes.selectedStyle :undefined,
                              onClick: (e) => {
                                 if (!disabled) {
                                    e.preventDefault();
                                    this.handleSelect(rowInfo.original, rowInfo.index);
                                 }
                              },
                              onDoubleClick: (e) => {
                                 if (!disabled) {
                                    e.preventDefault();
                                    this.handleSelect(rowInfo.original, rowInfo.index, () => {
                                       onDoubleClick && onDoubleClick(rowInfo.original);
                                    });
                                 }
                              },
                           }
                        }}
                     columns={getColumns(filteredData)}
         />
         </React.Fragment>
      );
   }
}

export default withRouter(withStyles(styles)(DashboardTable));
