import {forOwn, isEqual} from 'lodash';
import {removeOne} from './Utils';

export const updateAdd = (mutationKey, options) => (proxy, { data }) => {
   let currentOptions = Array.isArray(options) ? options : [options];
   for (const option of currentOptions) {
      updateAddChange(mutationKey, option, proxy, data);
   }
};

export const updateAddChange = (mutationKey, options, proxy, data ) => {
   const { query, key, variables} = options;
   let queryData = proxy.readQuery(options);
   let usingDataKey = queryData[ mutationKey ];
   if (usingDataKey) {
      usingDataKey.push(data[ key]);
   } else {
      queryData[ key ].push(data[ mutationKey ]);
   }
   proxy.writeQuery({ query, data: queryData });

   if (variables && !isEqual(variables, {})) {
      queryData = proxy.readQuery({query, variables});
      usingDataKey = queryData[mutationKey];
      if (usingDataKey) {
         usingDataKey.push(data[key]);
      } else {
         queryData[key].push(data[mutationKey]);
      }
      proxy.writeQuery({query, data: queryData})
   }
};

export const updateEdit = (mutationKey, options) => (proxy, { data }) => {
   let currentOptions = Array.isArray(options) ? options : [options];
   for (const option of currentOptions) {
      updateEditChange(mutationKey, option, proxy, data);
   }
};

function copyMatchingProperties(originalObject, changedObject) {
   let matchingObject = {};
   forOwn(originalObject, (value, key) => {
      matchingObject[key] = changedObject[key] || value;
   });
   return matchingObject;
}

export const updateEditChange = (mutationKey, options, proxy, data) => {
   const { query, key, variables} = options;
   const queryData = proxy.readQuery({ query, variables});
   const usingDataKey = queryData[ mutationKey ];
   if (usingDataKey) {
      queryData[ mutationKey ] = data[ key];
   } else {
      const selectedIndex = queryData[ key ].findIndex(item => item.id === data[ mutationKey ].id);
      queryData[ key ][selectedIndex] = copyMatchingProperties(queryData[ key ][selectedIndex], data[ mutationKey ]);
   }
   proxy.writeQuery({ query, data: queryData })
};

export const updateDelete = (id, options) => (proxy, { data }) => {
   let currentOptions = Array.isArray(options) ? options : [options];
   for (const option of currentOptions) {
      updateDeleteChange(id, option, proxy, data);
   }
};

export const updateDeleteChange = (id, options, proxy, data) => {
   const queryData = proxy.readQuery(options);
   const selectedIndex = queryData[ options.key ].findIndex(item => item.id === id);
   if (selectedIndex >= 0) {
      removeOne(queryData[ options.key], selectedIndex);
      proxy.writeQuery({ query: options.query, data: queryData })
   } else {
      console.log('Could not find item to remove from cache on delete.');
   }
};
