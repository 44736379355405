import React from 'react';
import UnitDetailFilter from '../components/UnitDetailFilter';
import UnitDetailTable from '../components/UnitDetailTable';

/**
 * Component to show the Detail Report.
 */
class UnitDetailReport extends React.Component {

   state = {
      filters: undefined,
   };

   handleFilter = (filters) => {
      this.setState({filters});
   };

   render() {
      const {tableRef, search} = this.props;

      return (
         <React.Fragment>
            <UnitDetailFilter  key="detailFilter" onFilter={this.handleFilter} storeName={'detailFilter'}/>
            <UnitDetailTable tableRef={tableRef} search={search} filters={this.state.filters}/>
         </React.Fragment>
      );
   }
}

export default UnitDetailReport;
