import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ErrorSnackbar from './ErrorSnackbar';
import memoizeOne from 'memoize-one';

/**
 * Component to show the error messages .
 *
 * Note:
 *    Message is the default property in values.
 */
export default class DisplayError extends Component {

   static propTypes = {
      error: PropTypes.object,                  // The error object to display.
      enableRefresh: PropTypes.bool,            // Indicates if the Refresh action should be shown / enabled.
      onClose: PropTypes.func,                  // Callback when the snackbar is closed.
      errorId: PropTypes.string,                // Intl ID for the error message.
      values: PropTypes.object,                 // Value object for the error message.
   };

   static defaultProps = {
      enableRefresh: true,
      errorId: 'fetch.error',
   };

   state = {
      showError: true,
   };

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({showError: false}, this.props.onClose);
   };

   /**
    * When a new error comes in, update to show errors even if user has turned off show errors, .
    * @type {result}
    */
   resetShowError = memoizeOne((error) => {
      if (!!error) {
         this.setState({showError: true});
      }
   });

   render() {
      const {errorId, error, values, enableRefresh} = this.props;
      const { showError } = this.state;

      this.resetShowError(error);
      if (showError) {
         return (
            <ErrorSnackbar open={error && this.state.showError} onClose={this.handleErrorClose}
                           errorId={errorId} values={values || error || {message: 'N/A'}} enableRefresh={enableRefresh}/>
         );
      } else {
         return null;
      }
   }
}