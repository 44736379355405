import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import Mutation from 'react-apollo/Mutation';

/**
 * Mutation component to delete items. Updates the cache of the updateQuery.
 */
export default class FHGMutation extends PureComponent {

   static propTypes = {
      isAdd: PropTypes.bool.isRequired,         // Indicates if the create mutation will be used.
      addMutation: PropTypes.object.isRequired,    // Mutation used to add the item.
      updateMutation: PropTypes.object.isRequired, // Mutation used to update the item.
      updateCache: PropTypes.any.isRequired,    // Query to be updated for the delete.
      mutationKey: PropTypes.string.isRequired,    // The name of the values returned by the mutation query
      children: PropTypes.any.isRequired,          // The child function to get the results of the mutation.
   };

   render() {
      const {isAdd, addMutation, updateMutation, children} = this.props;

      return (
         <Mutation mutation={isAdd ? addMutation : updateMutation} >
            {children}
         </Mutation>
      );
   }
}
