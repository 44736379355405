module.exports = {
   // General
   "refresh": 'Refresh',
   "app.title": 'Dashboard',
   "fetch.error": 'Could not load data. ({message})',
   "filter.fetch.error": 'Could not load filter options. ({message})',
   "save.label": 'Save',
   "cancel.button": 'Cancel',
   "ok.button": 'OK',
   'delete.button': 'Delete',
   'path.logout': 'Log Out',
   'leavePage': "You have made changes, which have not been saved. Do you want to discard changes?",
   'noPermission.message': "You do not have permission to view this page. Contact your administrator to get permission.",

   // Dashboard filters
   "filter.title": 'Filters ',
   "filter.currentReadingMin": 'Current Minimum',
   "filter.currentReadingMax": 'Current Maximum',
   "filter.status": 'Status',
   "filter.event": 'Event',
   "filter.unitRequired.label": 'Unit (Required)',
   "filter.unitNotRequired.label": 'Unit',
   "filter.timestamp": 'Timestamp',
   "filter.dateRange.label": 'Date Range',
   "filter.timestampMax": 'Start Date Maximum',

   //Unit balance table columns
   "balance.status.column": 'Status',
   "balance.event.column": 'Event',
   "balance.totals.column": 'Totals',
   "balance.city.column": 'City',
   "balance.fuelBegin.column": 'Fuel Begin',
   "balance.fuelEnd.column": 'Fuel End',
   "balance.grossGallons.column": 'Gross\nGallons',
   "balance.netGallons.column": 'Net\nGallons',
   "balance.dtlSupplier.column": 'DTL\nSupplier',
   "balance.totalBegin.column": 'Totalizer\nBegin',
   "balance.totalEnd.column": 'Totalizer\nEnd',
   "balance.transaction.column": 'Transaction',
   "balance.nonLoco.column": 'Non Loco #',
   "balance.reason.column": 'Reason',

   //Unit detail table columns
   "detail.status.column": 'Status',
   "detail.fuelBegin.column": 'Fuel Begin',
   "detail.fuelEnd.column": 'Fuel End',
   "detail.transaction.column": 'Transaction',
   "detail.submitTo.column": 'Submit To',
   "detail.unit.column": 'Unit',
   "detail.event.column": 'Event',
   "detail.grossGallons.column": 'Gross\nGallons',
   "detail.netGallons.column": 'Net\nGallons',
   "detail.dtlSupplier.column": 'DTL\nSupplier',
   "detail.location.column": 'Location',
   "detail.city.column": 'City',
   "detail.state.column": 'State',
   "detail.product.column": 'Product',
   "detail.beginLat.column": 'Begin Lat',
   "detail.beginLong.column": 'Begin Long',
   "detail.endLat.column": 'End Lat',
   "detail.endLong.column": 'End Long',
   "detail.locoId.column": 'Loco Id',
   "detail.locoNumber.column": 'Loco #',
   "detail.gangId.column": 'Gang ID',
   "detail.cNumber.column": 'C Number',
   "detail.totalBegin.column": 'Totalizer\nBegin',
   "detail.totalEnd.column": 'Totalizer\nEnd',
   "detail.net.column": 'Net',
   "detail.reason.column": 'Reason',
   "detail.ticketBOL.column": 'Ticket/BOL',

   //Download to CSV
   'csv.exceededMaximumRows.error': 'The maximum number of rows, {maxRows}, has been exceeded.',
   'csv.download.error': 'The CSV file could not be downloaded ({message}).',

   //Transaction
   "transaction.edit.title": "Transaction - {ticketNumber}",
   "transaction.loading.error": "The transaction could not be {action} ({message})",
      //Required
   "transaction.fuelEvent.label": "Fuel Event",
   "transaction.status.label": "Status",
   "transaction.unit.label": "Unit",
   "transaction.yourName.label": "Your Name",
   "transaction.grossGallons.label": "Gross Gallons",
   "transaction.netGallons.label": "Net Gallons",
   "transaction.fuelBeginTimestamp.label": "Fueling Begin",
   "transaction.fuelEndTimestamp.label": "Fueling End",
   "transaction.fuelingEventTimeZone.label": "Fueling Time Zone",
   "transaction.driver.label": "Driver",
   "transaction.locationName.label": "Location Name",
   "transaction.locationCity.label": "Location City",
   "transaction.locationState.label": "Location State",
   "transaction.productType.label": "Product Type",
   "transaction.dtlSupplierName.label": "DTL Supplier Name",
   "transaction.submitTo.label": "Submit To",
   "transaction.locomotiveIdInitial.label": "Locomotive ID Initial",
   "transaction.locomotiveIdInitialOptional.label": "Locomotive ID Initial (optional)",
   "transaction.locomotiveIdNumber.label": "Locomotive ID Number",
   "transaction.locomotiveIdNumberOptional.label": "Locomotive ID Number (optional)",
   "transaction.gangIdentifier.label": "Gang ID",
   "transaction.cNumber.label": "C Number",
      //Optional
   "transaction.comment.label": "Comment (optional)",
   "transaction.billOfLadingNumber.label": "Ticket Number / Bill of Lading Number (optional)",
   "transaction.billOfLadingBeginTimestamp.label": "Bill of Lading Begin (optional)",
   "transaction.billOfLadingEndTimestamp.label": "Bill of Lading End (optional)",
   "transaction.nonLocomotiveId.label": "Non-Loco ID (optional)",
   "transaction.loadingFacility.label": "Loading Facility (optional)",
   "transaction.SpillNoSpill.label": "Spill, No Spill (optional)",
   "transaction.dtsUnloadStartTime.label": "DTS Unload Start (optional)",
   "transaction.dtsUnloadEndTime.label": "DTS Unload End (optional)",
   "transaction.deliveredTo.label": "Delivered To (optional)",
   'transaction.duplicate.label': 'Exclude from duplicate checks',

   // Unit Edit
   'unit.edit.title': 'Unit',
   'unit.number.label': 'Unit Number',
   'unit.drivers.label': 'Drivers',
   'unit.addDriver.label': 'Add Driver',
   'unit.locomotiveIds.label': 'Locomotive IDs',
   'unit.unitLocation.label': 'Unit Location',
   'unit.customer.label': 'Customers',
   'unit.deliveredTo.label': 'Delivered To',
   'unit.products.label': 'Products',
   'unit.racks.label': 'Racks',
   'unit.hasMeter.label': 'Has Meter',
   'unit.requireDvir.label': 'Require Driver Vehicle Inspection Report',
   'unit.loadingFacility.label': 'Loading Facility',
   'unit.submitTo.label': 'Submit To',
   'unit.time.label': 'Time',
   'unit.delete.confirmation': 'Delete the unit, {item}?',
   "unit.loading.error": "The unit could not be {action} ({message})",

   // Location Edit
   'location.edit.title': 'Location',
   'location.name.label': 'Name',
   'location.state.label': 'State',
   'location.type.label': 'Type',
   'location.delete.confirmation': 'Delete the location, {item}?',
   'location.get.error': 'Cannot get the locations. ({message})',
   'location.delete.error': 'Cannot delete the location. ({message})',
   "location.loading.error": "The location could not be {action} ({message})",

   // Create Account
   'user.username.label': 'Username',
   'user.email.label': 'Email (optional)',
   'user.firstName.label': 'First Name',
   'user.lastName.label': 'Last Name',
   'user.isAdmin.label': 'Admin',
   'user.confirmPassword.label': 'Confirm',
   'user.newPassword.label': 'Password',
   'user.changePassword.label': 'Change Password',
   'user.createAccount.button': 'Create Account',
   'user.removeAccount.button': 'Remove Account',
   'user.removeAccount.confirm': 'Remove the user acount for {label}?',
   'user.createAccount.error': 'Could not create user account ({message}).',
   'user.invalidParameter.error': 'Password should be at least 8 characters. ({message}).',
   'user.policy.error': '{message}',
   "user.loading.error": "The users could not be loaded ({message})",

   // Driver Vehicle Inspection
   'vehicleInspection.unit.column': 'Tractor/Truck Number',
   'vehicleInspection.condition.column': 'Condition',
   'vehicleInspection.driver.column': 'Driver',
   'vehicleInspection.startDate.column': 'Date',
   'vehicleInspection.endDate.column': 'End Date',
   'vehicleInspection.condition.label': 'Condition',
   'vehicleInspection.driver.label': 'Driver',
   'vehicleInspection.identityNumber.label': 'Tractor/Truck Number',
   'vehicleInspection.pdf.column': 'PDF',

   // Settings
   'settings.title': 'Settings',
   'settings.fromEmail.label': 'From Email',
   'settings.toEmail.label': 'To Email',

};
