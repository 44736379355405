import React, {PureComponent} from 'react';
import Main from './Main';
import Amplify, { Hub } from '@aws-amplify/core';
import {
   Authenticator, SignIn,
} from 'aws-amplify-react';

// if (process.env.USE_PRODUCTION_CONFIG) {
   console.log('Using Production Config');
   Amplify.configure({
      Auth: {
         // REQUIRED - Amazon Cognito Identity Pool ID
         identityPoolId: 'us-east-2:047c2bb4-2468-425b-9ebb-6ee0ae0efd3c',
         // REQUIRED - Amazon Cognito Region
         region: 'us-east-2',
         // OPTIONAL - Amazon Cognito User Pool ID
         userPoolId: 'us-east-2_Gtwm2ZCCc',
         // OPTIONAL - Amazon Cognito Web Client ID
         userPoolWebClientId: '1jukv27k8a223t1q1p2mfnos6d',
      },
      Analytics: {
         // OPTIONAL - disable Analytics if true
         disabled: true,
      },
   });
// } else {
//    console.log('Using Development Config');
//    Amplify.configure({
//       Auth: {
//          // REQUIRED - Amazon Cognito Identity Pool ID
//          identityPoolId: 'us-east-2:60806e60-a30a-4874-abbb-dbf64baeb200',
//          // REQUIRED - Amazon Cognito Region
//          region: 'us-east-2',
//          // OPTIONAL - Amazon Cognito User Pool ID
//          userPoolId: 'us-east-2_FRHHPGYD0',
//          // OPTIONAL - Amazon Cognito Web Client ID
//          userPoolWebClientId: '35jgsaqj3kqmr8j0dkk747b5eu',
//       },
//       Analytics: {
//          // OPTIONAL - disable Analytics if true
//          disabled: true,
//       },
//    });
// }

export const MyTheme = {
   button: {

      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: 64,
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      lineHeight: 1.75,
      fontFamily: '"prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif',
      fontWeight: 500,
      borderRadius: 4,
      textTransform: 'uppercase',
      color: '#fff',
      backgroundColor: '#A90004',
   },
   a: {
      color: '#A90004',
      // cursor: 'pointer',
   },
   hint: {
      color: '#828282',
      fontSize: '14px',
   },
   sectionFooter: {
      fontSize: '16px',
   },
};

class App extends PureComponent {
   constructor(props, context) {
      super(props, context);

      Hub.listen('auth', this.listener);
   }

   listener = (data) => {
      if (data.payload.event === 'signIn') {
         window.location.reload();
      }
   };

   render() {

      return (
         <Authenticator hideDefault={true} theme={MyTheme}>
            <SignIn/>
            <Main/>
         </Authenticator>
      );
   }
}

export default App;
