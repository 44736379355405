import {Dialog, DialogTitle, DialogContent, DialogActions, Button, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { emptyFunction } from '../../utils/Utils';
import Typography from '../Typography';

const styles = theme => ({
   titleStyle: {
      // borderBottom: `1px solid ${theme.palette.primary.light}`,
      position: 'sticky',
      backgroundColor: 'white',
      top: 0,
      zIndex: 100,
   },
   contentStyle: {
      padding: theme.spacing(3, 4, 2),
   },
   actionStyle: {
      position: 'sticky',
      bottom: 0,
      height: 60,
      margin: 0,
      padding: theme.spacing(0, 4.5),
      // backgroundColor: theme.palette.primary.light,
      // borderTop: `1px solid ${theme.palette.primary.light}`,
      zIndex: 100,
   },
});

/**
 * Confirmation dialog that shows the user a message and allows for confirmation or cancel.
 */
class ConfirmDialog extends Component {

   static propTypes = {
      message: PropTypes.string,       // Message to be displayed to the user. Use either message or messageKey but not
                                       //    both.
      messageKey: PropTypes.string,    // Message key of the message to be displayed to the user.
      onConfirm: PropTypes.func,       // Called when the user confirms the message.
      onCancel: PropTypes.func,        // Called when the user cancels.
      open: PropTypes.bool,            // Indicates if the dialog should be open or not.
      title: PropTypes.string,         // Title for the confirmation dialog.
      titleKey: PropTypes.string,      // Localization key for the Title for the confirmation dialog.
      confirmLabel: PropTypes.string,  // Label for the confirm button.
      confirmKey: PropTypes.string,    // Localization key for the confirm button label.
      values: PropTypes.object,        // Localization values for the message.
   };

   static defaultProps = {
      open: true,
      onConfirm: emptyFunction,
      onCancel: emptyFunction,
      confirmLabel: 'OK',
      confirmKey: 'ok.button',
   };

   render() {
      const { classes, title, titleKey, message, messageKey, open, onCancel, onConfirm, confirmLabel, confirmKey, values,
         children } = this.props;

      return (
         <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='xs'
            aria-labelledby='confirmation-dialog-title'
            open={open}
         >
            {(title || titleKey) && (
               <DialogTitle disableTypography id='confirmation-dialog-title'>
                  <Typography variant='title' id={titleKey}>{title}</Typography>
               </DialogTitle>
            )}
            <DialogContent className={classes.contentStyle}>
               <Typography variant='h6' id={messageKey}  values={values}>{message}</Typography>
               {children}
            </DialogContent>
            <DialogActions>
               <Button onClick={onCancel} color='primary'>
                  <Typography color='inherit' id='cancel.button'>Cancel</Typography>
               </Button>
               <Button onClick={onConfirm} color='secondary'>
                  <Typography color='inherit' id={confirmKey}>{confirmLabel}</Typography>
               </Button>
            </DialogActions>
         </Dialog>
      );
   }
}
export default withStyles(styles)(ConfirmDialog);
