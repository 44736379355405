import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Query from 'react-apollo/Query';
import 'react-table/react-table.css';
import {DAYS_BACK_FOR_DETAIL_FILTER} from '../Constants';
import DateRangePicker from '../fhg/components/DateRangePicker';
import DisplayError from '../fhg/components/DisplayError';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import {sortDate} from '../fhg/utils/Utils';
import Filter from './Filter';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';

const styles = theme => ({
   root: {
      margin: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      flex: '0 0 auto'
   },
   titleStyle: {
      marginRight: theme.spacing(2),
   },
   noWrap: {
      whiteSpace: 'nowrap',
   },
   buttonStyle: {
      marginTop: theme.spacing(2),
      alignSelf: 'flex-start',
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
});

const GetOptionValues = gql`
   {
      statuses: status_All {
          id
         value:id
         label:name
      }
      submitTo:submitTo_All {
         id
         value:id
         label:name
      }
      locations:location_All {
          id
         value:name
         label:name
         stateId
      }
      units:truck_All {
         id
         identityNumber
         location {
             id
            name
         }
      }
      eventTypes:eventType_All {
          id
         label:name
         value:id
      }
   }
`;

/**
 * The filter component to determine which data to fetch.
 */
class UnitDetailFilter extends Filter {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      onFilter: PropTypes.func,                 // Called when the filter changes and new data should be fetched.
   };

   constructor(props, context) {
      super(props, context);

      if (this.state === undefined) {
         this.state = {
            statusId: '',
            submitToId: '',
            location: '',
            eventId: '',
            unitId: '',
         };
      }
      const dateRange = this.getSessionFilter();
      if (!dateRange) {
         const today = new Date();
         this.state.dateRange = [today, subDays(today, DAYS_BACK_FOR_DETAIL_FILTER)];
         this.handleFilter();
      } else {
         this.state.dateRange = [];
         for (const dateElement of dateRange) {
            //Single date range has null value in range. Don't add those in.
            if (dateElement) {
               const date = new Date(dateElement);
               if (!isNaN(date.getTime())) {
                  this.state.dateRange.push(date);
               }
            }
         }
         //If there was a null in the date range, put in the other date for the end date.
         if (this.state.dateRange.length === 1) {
            this.state.dateRange.push(this.state.dateRange[0]);
         }
      }
   }

   /**
    * Get the filters from the state. The filtering is based on Googles Firestore query.where calls.
    *
    * @return {Array} The array of filters.
    */
   getFilters = () => {
      const {dateRange} = this.state;
      const filters = {};

      this.setFilterValue(filters, 'statusId');
      this.setFilterValue(filters, 'submitToId');
      this.setFilterValue(filters, 'location');
      this.setFilterValue(filters, 'eventId');
      this.setFilterValue(filters, 'unitId', true);
      if (dateRange.length >= 1 && dateRange[0]) {
         const sortedDateRange = dateRange.sort(sortDate);
         filters.startDate = sortedDateRange[0].toISOString();
         filters.endDate = (sortedDateRange[1] && endOfDay(sortedDateRange[1]).toISOString()) || endOfDay(sortedDateRange[0]).toISOString();
      }
      return filters;
   };

   /**
    * Handle date changes to filter.
    *
    * @param name The name of the filter.
    * @param date The date from the component that changed.
    */
   handleDateChange = name => (date) => {
      this.setState({[name]: date}, () => {
         this.saveSessionFilter(this.state.dateRange);
      });
   };

   render() {
      let {classes} = this.props;
      const {dateRange} = this.state;

      return (
         <Query query={GetOptionValues} fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <Fragment>
                  <DisplayError error={error} errorId={'filter.fetch.error'}/>
                  <Grid container item className={classes.root} direction={'column'}>
                     <Typography id={'filter.title'} className={classes.titleStyle} variant='h6' color='inherit'
                                 noWrap/>
                     <Grid container direction={'row'} spacing={2}>
                        <Grid container item xs={12} sm={6} md={4} lg={2}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap}>Status</Typography>}
                              options={data.statuses}
                              name={'statusId'}
                              value={this.state.statusId}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={2}>
                           <ReactSelect
                               label={<Typography className={classes.noWrap}>Submit To</Typography>}
                               options={data.submitTo}
                               name={'submitToId'}
                               value={this.state.submitToId}
                               onChange={this.handleChange}
                               fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={2}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap}>Location</Typography>}
                              options={data.locations}
                              name={'location'}
                              isCreatable={true}
                              value={this.state.location}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={2}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap}>Event</Typography>}
                              options={data.eventTypes}
                              name={'eventId'}
                              value={this.state.eventId}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={2}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap} id={'filter.unitNotRequired.label'}/>}
                              options={data.units}
                              getLabel={item => `${item.identityNumber || 'N/A'} - ${get(item, 'location.name',
                                 'N/A')}`}
                              labelKey={'identityNumber'}
                              valueKey={'id'}
                              name={'unitId'}
                              value={this.state.unitId}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={2} spacing={0}>
                           <DateRangePicker
                              name={'dateRange'}
                              label={<Typography className={classes.noWrap}>Date Range</Typography>}
                              value={dateRange}
                              style={{top: 8}}
                              allowKeyboardControl
                              disableFuture
                              autoOk={false}
                              clearable
                              fullWidth
                              onChange={this.handleDateChange('dateRange')}
                           />
                        </Grid>
                     </Grid>
                     <Button variant='contained' color='secondary' className={classes.buttonStyle}
                             onClick={this.handleFilter}>
                        <FilterIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                        Filter
                     </Button>
                  </Grid>
               </Fragment>
            )}
         </Query>
      );
   }
}

// export default withStyles(styles)(UnitDetailFilter);

export default withStyles(styles)(UnitDetailFilter);
