import {Grid} from '@material-ui/core';
import TextField from '@material-ui/core//TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/es/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import gql from 'graphql-tag';
import {isEqual, memoize} from 'lodash';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Query from 'react-apollo/Query';
import {intlShape, injectIntl} from 'react-intl';
import {Prompt} from 'react-router-dom';
import Mutation from '../fhg/components/data/Mutation';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import {formatMessage} from '../fhg/utils/Utils';

const MAX_WIDTH = 500;

const createOption = (label) => ({
   label,
   value: label,
});

const styles = theme => ({
   button: {
      margin: theme.spacing(1, 1, 1, 0),
   },
   unsizingFlex: {
      flex: '0 0 auto',
   },
   flex: {
      display: 'flex',
   },
   stickyCaption: {
      position: 'sticky',
      left: 0,
   },
   resizingContainer: {
      display: 'flex',
      flex: '1 1',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
   },
   outerContainer: {
      maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
      overflow: 'auto',
      flex: '1 1',
   },
   innerContainer: {
      marginBottom: 150,
      maxWidth: MAX_WIDTH,
   },
   progressMargin: {
      marginLeft: theme.spacing(0.5),
   },
   titleStyle: {
      marginBottom: theme.spacing(3),
   },
   inlineStyle: {
      display: 'inline-block',
   },
   selectStyle: {
      marginTop: 3,
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
   largeWidth: {
      maxWidth: MAX_WIDTH,
      minWidth: MAX_WIDTH / 2,
   },
   mediumWidth: {
      maxWidth: MAX_WIDTH * .75,
      minWidth: (MAX_WIDTH * .75) / 2,
   },
   smallWidth: {
      maxWidth: MAX_WIDTH / 2,
      minWidth: MAX_WIDTH / 4,
   },
   formStyle: {
      flex: '1 1 100%',
      overflow: 'hidden',
   },
   formContainer: {
      margin: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(3)}px)`,
      maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
   },
   editStyle: {
      flex: '0 0 auto',
   },
   spinnerStyle: {
      color: 'white',
      marginLeft: theme.spacing(0.5),
   },
   checkboxStyle: {
      marginLeft: theme.spacing(1),
   },
});

export const UNIT_FRAGMENT = gql`
   fragment unitInfo on Truck {
      id
      identityNumber
      customers
      submitToList {
         id
         value:id
         label:name
      }
      tabletId
      tablet {
         id
         value:id
         label:tabletIdentifier
      }
      deliveredToList {
         id
         value:id
         label:name
      }
      driverList {
         id
         value:id
         firstName
         lastName
      }
      locomotiveIdentifierList {
         id
         value:id
         label:name
      }
      location {
         id
         name
         value: id
         label: name
      }
      locationList {
         id
         value:id
         label:name
      }
      loadingFacilityList {
         id
         value: id
         label:name
      }
      productTypeList {
         id
         value:id
         label:name
      }
      hasMeter:hasPump
      requireDvir
      dtlSupplierId
   }
`;

export const UNIT_SMALL_FRAGMENT = gql`
   fragment unitInfo on Truck {
      id
      identityNumber
      location {
         id
         name
         value: id
         label: name
      }
   }
`;

export const getUnitsQuerySmall = gql`
    query getUnits($locationId: [Int], $unitId: [String])
    {
        units:truck_AllWhere(truckSearch: {locationId: $locationId, identityNumber: $unitId}) {
           ...unitInfo
        }
    }
   ${UNIT_SMALL_FRAGMENT}
`;

/**
 * Missing:
 *    Customers
 *    DeliveredTo
 *    Loading Facility
 *    SubmitTo
 */
export const getUnitsQuery = gql`
   query getUnit($unitId: Int!)
   {
      productTypes:productType_All {
         id
         value:id
         label:name
      }

      unitLocations:location_AllWhere (locationSearch: {locationTypeId: 3}) {
         id
         value:id
         label:name
      }

      deliveredToLocations:location_AllWhere (locationSearch: {locationTypeId: 5}) {
         id
         value:id
         label:name
      }

      loadingFacilities:location_AllWhere (locationSearch: {locationTypeId: 4}) {
         id
         value:id
         label:name
      }

      drivers:user_All {
         id
         value:id
         firstName
         lastName
      }

      locomotiveIdentifiers:locomotiveIdentifier_All {
         id
         value:id
         label:name
      }

      tablets:tablet_AllUnassigned{
         id
         value:id
         label:tabletIdentifier
      }

      dtlSuppliers:dtlSupplier_All {
         id
         value:id
         label:name
      }

      submitTo:submitTo_All {
         id
         value:id
         label:name
      }

      unit:truck_ById(truckId: $unitId) {
         ...unitInfo
      }
   }
   ${UNIT_FRAGMENT}
`;

/*
TODO
Unused:
currentFuelLevel
 */

/*
locationId: Int
identityNumber: String
capacity: Float
currentFuelLevel: Float
driverIdList: [Int]
locationIdList: [Int]
locomotiveIdList: [Int]
productTypeIdList: [Int]
 */
const UPDATE_UNIT = gql`
   mutation UpdateUnit($id: Int!, $locationId: Int, $identityNumber: String, $tabletId: Int, $customers: [String], $submitToIdList: [Int], $driverIdList: [Int], $loadingFacilityIdList: [Int], $locationIdList: [Int], $deliveredToIdList: [Int], $locomotiveIdentifierNameList: [String], $productTypeIdList: [Int], $hasMeter: Boolean, $requireDvir: Boolean, $dtlSupplierId: Int) {
      unitUpdate:truck_Update(truckId: $id, truck: {locationId: $locationId, tabletId: $tabletId, identityNumber: $identityNumber, customers: $customers, submitToIdList: $submitToIdList, driverIdList: $driverIdList, loadingFacilityIdList: $loadingFacilityIdList, locationIdList: $locationIdList, deliveredToIdList: $deliveredToIdList, locomotiveIdentifierNameList: $locomotiveIdentifierNameList, productTypeIdList: $productTypeIdList, hasPump: $hasMeter, requireDvir: $requireDvir, dtlSupplierId: $dtlSupplierId}) {
         ...unitInfo
      }
   }
   ${UNIT_FRAGMENT}
`;

const CREATE_UNIT = gql`
   mutation CreateUnit($locationId: Int, $identityNumber: String!, $tabletId: Int, $customers: [String], $submitToIdList: [Int], $driverIdList: [Int], $loadingFacilityIdList: [Int], $locationIdList: [Int], $deliveredToIdList: [Int], $locomotiveIdentifierNameList: [String], $productTypeIdList: [Int], $hasMeter: Boolean, $requireDvir: Boolean, $dtlSupplierId: Int) {
      unitUpdate:truck_Create(truck: {tabletId: $tabletId, customers: $customers, submitToIdList: $submitToIdList, locationId: $locationId, identityNumber: $identityNumber, driverIdList: $driverIdList, loadingFacilityIdList: $loadingFacilityIdList, locationIdList: $locationIdList, deliveredToIdList: $deliveredToIdList, locomotiveIdentifierNameList: $locomotiveIdentifierNameList, productTypeIdList: $productTypeIdList, hasPump: $hasMeter, requireDvir: $requireDvir, dtlSupplierId: $dtlSupplierId}) {
...unitInfo
      }
   }
   ${UNIT_FRAGMENT}
`;

/**
 * The component that shows the employee specific fields. Primary purpose is to connect employee to account on ADP and
 * username and password to match employee when connection to ADP cannot be made.
 */
class UnitEdit extends Component {

   static propTypes = {
      intl: intlShape,                       // Localization messages.
      classes: PropTypes.object.isRequired,  // The style for the component.
      isParentValid: PropTypes.bool,         // Indicates if the parent data is valid.
      isParentChanged: PropTypes.bool,       // Indicates if the parent would enable the submit button. True is changed
                                             // data and is valid, false is invalid or missing data and undefined is
                                             // unchanged valid data.
      roles: PropTypes.object,               // The data roles from DataHOC.
      onSubmit: PropTypes.func.isRequired,   // Callback when the submit button is pressed from Employee.
      onQuery: PropTypes.func.isRequired,    // Callback for the DataHOC to perform a query to the server.
      onChange: PropTypes.func,              // Callback for when the component has changed.
   };

   static defaultProps = {
      isSubmitEnabled: true,
   };

   constructor(props, context) {
      // noinspection JSCheckFunctionSignatures
      super(props, context);

      this.state = {
         isChanged: false,
         errorId: undefined,
         customerOptions: [],
         locomotiveIdentifierIdsOptions: [],
         submitToOptions: [],
         isUniqueKey: true,
         hasMeter: true,
         requireDvir: true,
      };
      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape') {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push('/admin/report/unit');
         }
      }
   };

   /**
    * Handle changes to filter.
    *
    * @param value The new value of the filter.
    * @param name The name of the filter component.
    */
   handleChange = (value, name) => {
      this.setState({[name]: value, isChanged: true, isUniqueKey: true});
   };

   handleCheckboxChange = ({target}) => {
      this.setState({[target.name]: target.checked, isChanged: true});
   };

   handleCreateChange = (name) => (newValue, unused, isMulti) => {
      if (isMulti) {
         this.setState({[name]: newValue});
      }
   };

   handleCreate = name => inputValue => {
      const options = this.state[name + 'Options'] || [];
      const values = this.state[name] || [];
      const currentValues = values.slice(0);
      const newOption = createOption(inputValue);
      currentValues.push(newOption);
      this.setState({
         [name + 'Options']: [...options, newOption],
         [name]: currentValues,
      });
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   onChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true, isUniqueKey: true});
   };

   /**
    * Callback when the user submits the changes.
    */
   onSubmit = unitUpdate => e => {
      const {
         identityNumber, customers, tabletId, submitToIdList, drivers, loadingFacility, unitLocation,
         locomotiveIdentifierIds, products, deliveredTo, hasMeter, requireDvir, dtlSupplierId,
      } = this.state;
      e.preventDefault();
      this.setState({isSaving: true, mutationError: false});
      const options = {
         variables: {
            id: Number(this.props.match.params.id),
            identityNumber,
            tabletId: tabletId && tabletId.value,
            customers: customers && customers.map(item => item.value),
            submitToIdList: submitToIdList && submitToIdList.map(submitToId => submitToId.value),
            driverIdList: drivers && drivers.map(driver => driver.value),
            loadingFacilityIdList: loadingFacility && loadingFacility.map(item => item.value),
            locationId: unitLocation && unitLocation.value,
            locomotiveIdentifierNameList: locomotiveIdentifierIds && locomotiveIdentifierIds.map(item => item.label),
            productTypeIdList: products && products.map(item => item.value),
            deliveredToIdList: deliveredTo && deliveredTo.map(item => item.value),
            hasMeter,
            requireDvir,
            dtlSupplierId: dtlSupplierId && dtlSupplierId.value,
         }
      };
      unitUpdate(options).then(result => {
         this.setState({isSaving: false, isChanged: false}, () => {
            this.props.history.replace('/admin/edit/unit/' + result.data.unitUpdate.id); //identityNumber
            this.props.history.push(`/admin/report/unit?select=${result.data.unitUpdate.id}`);
         });
      }).catch(mutationError => {
         const isUniqueKey = mutationError.message.indexOf('GraphQL error: duplicate key value violates unique') < 0;
         !isUniqueKey && this.scrollIntoView('identityNumber', this.props.classes.outerContainer);

         console.log(mutationError);
         this.setState({mutationError, isUniqueKey});
      }).finally(() => {
         this.setState({isSaving: false});
      });
   };

   /**
    * Scroll the error component into view.
    * @param name Element name of the element to scroll to.
    * @param autoOverflowClass The class name of the parent with the 'auto' overflow attribute.
    */
   scrollIntoView = (name, autoOverflowClass) => {
      const element = get(document.getElementsByName(name), '[0]');
      const overflowElement = get(document.getElementsByClassName(autoOverflowClass), '[0]');

      if (overflowElement && element) {
         overflowElement.scrollTop = element.offsetTop - overflowElement.offsetTop - (overflowElement.offsetHeight / 2) +
            (element.offsetHeight / 2);
      }
   };

   getSelectValues = (object, path, defaultValue, getLabel) => {
      const array = get(object, path, defaultValue);
      const convertedArray = [];
      if (array && array.length > 0) {
         for (const item of array) {
            convertedArray.push({label: (getLabel && getLabel(item)) || item, value: item.value || item});
         }
      }
      return convertedArray;
   };

   getLabel = (item) => {
      return `${item.firstName} ${item.lastName}`;
   };

   updateStateCache = data => {
      const customerOptions = this.getSelectValues(data, 'unit.customers', []);
      const locomotiveIdentifierIdsOptions = get(data, 'locomotiveIdentifiers', []);
      const hasMeter = get(data, 'unit.hasMeter', true);
      const requireDvir = get(data, 'unit.requireDvir', true);

      this.setState({customers: customerOptions, customerOptions, locomotiveIdentifierIdsOptions, hasMeter, requireDvir});
   };

   updateState = memoizeOne(this.updateStateCache, isEqual);

   updateTablets = memoize((tablets = [], unitTablet) => {
      if (!!unitTablet) {
         return [unitTablet].concat(tablets);
      }
      return tablets;
   });
   /*
   Vehicle Number
Drivers
Locomotive IDs
Del Locations
Suppliers  Change to “Customer]”[
Products
Racks
P/U Locations  Change to “Loading Facility”
Submit To
Time Zone-Unknown if this is required by BNSF with each transaction.  If not, we can delete
Beginning Fuel   D
*/

   render() {
      const {intl, classes} = this.props;
      const {
         isChanged, mutationError, identityNumber, unitLocation, customers, deliveredTo, isSaving, isUniqueKey,
         dtlSupplierId
      } = this.state;

      const isAdd = this.props.match.params.id === '0';
      const variables = {unitId: Number(this.props.match.params.id)};

      return (
         <Query query={getUnitsQuery} variables={variables} fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <DataLoadingAndErrors isLoading={loading} error={(error || mutationError) && isUniqueKey} data={data} showOnLoad
                                     errorId={'unit.loading.error'} values={{
                  action: !!mutationError ? 'saved' : 'loaded',
                  message: error && error.message
               }}>
                  {data => {
                     this.updateState(data);
                     const tabletOptions = this.updateTablets(get(data, 'tablets'), get(data, 'unit.tablet'));
                     return (
                        <Mutation isAdd={isAdd} addMutation={CREATE_UNIT}
                                  updateMutation={UPDATE_UNIT} key={this.props.match.params.id}
                                  // updateCache={[
                                  //    {query: getUnitsQuerySmall, key: 'units', variables: location.state},
                                  //    {query: getUnitsQuery, key: 'unit', variables},
                                  // ]}
                                  mutationKey={'unitUpdate'}
                        >
                           {unitUpdate => (

                              <form onSubmit={this.onSubmit(unitUpdate)} className={classes.formStyle}>
                                 {(!loading || isAdd) && (

                                    <Grid container direction={'column'} spacing={0} wrap={'nowrap'}
                                          className={classes.formContainer}>
                                       <Prompt when={isChanged}
                                               message={() => formatMessage(intl, 'leavePage', 'Discard changes?')}/>
                                       <Grid item container className={classes.resizingContainer} spacing={0}
                                             direction={'column'} wrap={'nowrap'}>
                                          <Grid item className={classes.unsizingFlex} style={{width: '100%'}}>
                                             <Typography className={classes.titleStyle} variant={'h5'}
                                                         id={'unit.edit.title'}/>
                                          </Grid>
                                          <Grid container className={classes.outerContainer} direction={'column'}
                                                xs={12}>
                                             <Grid container className={classes.innerContainer} direction={'column'}
                                                   xs={12} spacing={2} wrap={'nowrap'}>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <TextField
                                                      name='identityNumber'
                                                      className={classes.largeWidth}
                                                      error={!isUniqueKey}
                                                      helperText={!isUniqueKey && 'Conflict with existing unit. Please enter a unique value.'}
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'unit.number.label'}
                                                                         nowrap/>}
                                                      fullWidth
                                                      autoFocus
                                                      required
                                                      defaultValue={get(data, 'unit.identityNumber')}
                                                      value={identityNumber}
                                                      onChange={this.onChange}
                                                      disabled={isSaving}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name={'drivers'}
                                                      label={'Drivers'}
                                                      defaultValue={this.getSelectValues(data, 'drivers', [],
                                                         this.getLabel)}
                                                      value={this.state.drivers ||
                                                      this.getSelectValues(data, 'unit.driverList', [], this.getLabel)}
                                                      onChange={this.handleChange}
                                                      required
                                                      disabled={isSaving}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      options={this.getSelectValues(data, 'drivers', [], this.getLabel)}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name={'locomotiveIdentifierIds'}
                                                      label={'Locomotive Identifier IDs'}
                                                      defaultValue={get(data, 'unit.locomotiveIdentifierList', [])}
                                                      value={this.state.locomotiveIdentifierIds}
                                                      // onChange={this.handleChange}
                                                      onChange={this.handleCreateChange('locomotiveIdentifierIds')}
                                                      onCreateOption={this.handleCreate('locomotiveIdentifierIds')}
                                                      disabled={isSaving}
                                                      required
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      isCreatable
                                                      fullWidth
                                                      // options={data.locomotiveIdentifiers}
                                                      options={this.state.locomotiveIdentifierIdsOptions}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name='unitLocation'
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'unit.unitLocation.label'} nowrap/>}
                                                      defaultValue={get(data, 'unit.location')}
                                                      required
                                                      value={unitLocation}
                                                      onChange={this.handleChange}
                                                      isMulti={false}
                                                      disabled={isSaving}
                                                      fullWidth
                                                      options={data.unitLocations}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name='customers'
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'unit.customer.label'} nowrap/>}
                                                      // defaultValue={this.getSelectValues(data, 'unit.customers', [])}
                                                      value={customers}
                                                      onChange={this.handleCreateChange('customers')}
                                                      onCreateOption={this.handleCreate('customers')}
                                                      onCreate
                                                      required
                                                      disabled={isSaving}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      isCreatable={true}
                                                      options={this.state.customerOptions}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name='deliveredTo'
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'unit.deliveredTo.label'} nowrap/>}
                                                      defaultValue={get(data, 'unit.deliveredToList', [])}
                                                      value={deliveredTo}
                                                      required
                                                      onChange={this.handleChange}
                                                      disabled={isSaving}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      options={data.deliveredToLocations}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name={'products'}
                                                      label={'Products'}
                                                      defaultValue={get(data, 'unit.productTypeList', [])}
                                                      value={this.state.products}
                                                      onChange={this.handleChange}
                                                      disabled={isSaving}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      options={data.productTypes}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      label={'Loading Facility'}
                                                      name={'loadingFacility'}
                                                      defaultValue={get(data, 'unit.loadingFacilityList', [])}
                                                      value={this.state.loadingFacility}
                                                      onChange={this.handleChange}
                                                      disabled={isSaving}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      options={data.loadingFacilities}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      label={'Submit To'}
                                                      name={'submitToIdList'}
                                                      defaultValue={get(data, 'unit.submitToList')}
                                                      value={this.state.submitToIdList}
                                                      onChange={this.handleChange}
                                                      disabled={isSaving}
                                                      isMulti={true}
                                                      closeMenuOnSelect={false}
                                                      fullWidth
                                                      options={data.submitTo}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <FormControlLabel
                                                      // style={{marginLeft: 0}}
                                                      className={classes.checkboxStyle}
                                                      control={
                                                         <Checkbox
                                                            name={'hasMeter'}
                                                            disableRipple
                                                            disabled={isSaving}
                                                            checked={this.state.hasMeter}
                                                            onChange={this.handleCheckboxChange}
                                                            value='hasMeter'
                                                         />
                                                      }
                                                      label={<Typography id={'unit.hasMeter.label'}/>}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      label={'Tablet ID'}
                                                      name={'tabletId'}
                                                      defaultValue={get(data, 'unit.tabletId')}
                                                      value={this.state.tabletId}
                                                      onChange={this.handleChange}
                                                      isMulti={false}
                                                      disabled={isSaving}
                                                      fullWidth
                                                      isCreatable={false}
                                                      options={tabletOptions}
                                                      maxMenuHeight={180}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <FormControlLabel
                                                      // style={{marginLeft: 0}}
                                                      className={classes.checkboxStyle}
                                                      control={
                                                         <Checkbox
                                                            name={'requireDvir'}
                                                            disableRipple
                                                            checked={this.state.requireDvir}
                                                            onChange={this.handleCheckboxChange}
                                                            value='requireDvir'
                                                            disabled={isSaving}
                                                         />
                                                      }
                                                      label={<Typography id={'unit.requireDvir.label'}/>}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <ReactSelect
                                                      name='dtlSupplierId'
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'transaction.dtlSupplierName.label'}
                                                                         nowrap/>}
                                                      defaultValue={get(data, 'unit.dtlSupplierId')}
                                                      required
                                                      value={dtlSupplierId}
                                                      onChange={this.handleChange}
                                                      isMulti={false}
                                                      disabled={isSaving}
                                                      fullWidth
                                                      options={data.dtlSuppliers}
                                                   />
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                       <Grid item style={{padding: 0}}>
                                          <Button variant='contained' color='secondary' className={`no-print ${classes.button}`}
                                                  onClick={this.handleFilter} type={'submit'} disabled={isSaving}>
                                             <SaveIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                                             <Typography color='inherit' id={'save.label'}>Save</Typography>
                                             {isSaving &&
                                             <CircularProgress className={classes.spinnerStyle} size={15}
                                                               thickness={2.5}/>}
                                          </Button>
                                       </Grid>
                                    </Grid>
                                 )}
                              </form>
                           )}
                        </Mutation>
                     )
                  }}
               </DataLoadingAndErrors>
            )}
         </Query>
      );
   }

}

export default injectIntl(withStyles(styles)(UnitEdit));
