import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {createStyles, withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {LOGO_SMALL} from '../Constants';
import CsvExportButton from '../fhg/components/CsvExportButton';
import Search from '../fhg/components/Search';
import Typography from '../fhg/components/Typography';
import {Auth} from 'aws-amplify';
import { AccountCircle } from '@material-ui/icons/';
import { Link } from 'react-router-dom';

const styles = (theme) =>
   createStyles({
      grow: {
         flexGrow: 1,
      },
      appBar: {
         zIndex: theme.zIndex.drawer + 1,
         backgroundImage: 'url(/images/thumb.jpeg)',
         backgroundSize: 'cover',
      },
      titleStyle: {
         display: 'none',
         [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(2),
         },
      },
      version: {
         display: 'none',
         fontSize: 12,
         [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginLeft: theme.spacing(1),
         },
      },
      imageStyle: {
         // height: 44,
         width: 240,
         [theme.breakpoints.down('sm')]: {
            width: '20%',
            height: '20%',
            // minHeight: 50,
            minWidth: 150,
            // minHeight: 50,
            maxWidth: 240,
         },
         [theme.breakpoints.down('xs')]: {
            height: 28,
            minWidth: 150,
         },
      },
      logoutStyle: {
         whiteSpace: 'nowrap',
         color: 'white',
         backgroundColor: 'transparent',
         marginLeft: theme.spacing(2),
      }
   });

/**
 * The AppBar with search and export to CSV capabilities.
 */
class SearchAppBar extends Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,
      onSearch: PropTypes.func.isRequired,
      onShowSignup: PropTypes.func.isRequired,
   };

   state = {
      search: '',
   };

   handleLogOut = () => {
      const {onStateChange} = this.props;

      Auth.signOut()
         .then(() => {
            if (onStateChange) {
               onStateChange('signedOut');
            }
            window.location.reload();
         }).catch(err => { console.log(err); });
   };

   handleCreateAccount = () => {
      this.setState({ anchorEl: null}, this.props.onShowSignup);
   };

   handleRemoveAccount = () => {
      this.setState({ anchorEl: null}, this.props.onShowRemove);
   };

   handleMenu = event => {
      this.setState({ anchorEl: event.currentTarget });
   };

   handleClose = () => {
      this.setState({ anchorEl: null });
   };


   render() {
      const {classes, onDownloadToCsv, showCsv, showSearch, onSearch, isAdmin} = this.props;
      const { anchorEl} = this.state;
      const menuOpen = Boolean(anchorEl);

      // noinspection HtmlUnknownTarget
      return (
         <AppBar position='fixed' className={classes.appBar}>
            <Toolbar style={{backgroundColor: 'rgba(0, 0, 0, 0.65)'}}>
               <img alt='' className={classes.imageStyle} src={LOGO_SMALL}/>
               <Grid container direction={'column'} alignItems={'flex-start'} justify={'center'}>
                  <Typography id={'app.title'} className={classes.titleStyle} variant='h6' color='inherit' noWrap/>
                  <Typography className={classes.version} variant='Caption' color='inherit'
                              noWrap>Version: {process.env.REACT_APP_VERSION}</Typography>
               </Grid>
               <div className={classes.grow}/>
               {showCsv && (
                  <CsvExportButton onClick={onDownloadToCsv}/>
               )}
               {showSearch && (
                  <Search classes={classes} value={this.state.search} onChange={onSearch}/>
               )}
               {isAdmin ? (
                  <Fragment>
                     <IconButton
                        aria-owns={'menu-appbar'}
                        aria-haspopup='true'
                        onClick={this.handleMenu}
                        color='inherit'
                     >
                        <AccountCircle/>
                     </IconButton>
                     <Menu
                        id='menu-appbar'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={menuOpen}
                        onClose={this.handleClose}
                     >
                        <MenuItem onClick={this.handleCreateAccount}>
                           <Typography variant='menuItem'>Create Account</Typography>
                        </MenuItem>
                        <MenuItem onClick={this.handleRemoveAccount}>
                           <Typography variant='menuItem'>Remove Account</Typography>
                        </MenuItem>
                        <MenuItem component={Link} onClick={this.handleLogOut}>
                           <Typography id='path.logout' variant='menuItem'>Logout</Typography>
                        </MenuItem>
                     </Menu>
                  </Fragment>
               ) : (
                  <Button variant='contained' className={classes.logoutStyle} onClick={this.handleLogOut}>
                     Log Out
                  </Button>
               )}
            </Toolbar>
         </AppBar>
      );
   }
}

export default withStyles(styles)(SearchAppBar);
