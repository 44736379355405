import {Typography} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {drawerWidth, SETTINGS_PATH} from '../Constants';

const styles = theme => ({
   drawerStyle: {
      width: drawerWidth,
      flexShrink: 0,
   },
   drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#EFF0F0',
   },
   toolbar: theme.mixins.toolbar,
});

/**
 * Drawer to navigate to reports and other screens.
 */
class NavigationDrawer extends Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,
   };

   render() {
      const {classes, location} = this.props;

      return (
         <Drawer
            className={`no-print ${classes.drawerStyle}`}
            variant='permanent'
            classes={{
               paper: classes.drawerPaper,
            }}
         >
            <div className={classes.toolbar}/>
            <Typography variant={'h6'} style={{marginLeft: 16}} color={'secondary'}>Reports</Typography>
            <List>
               <ListItem button component={Link} to='/admin/report/balance' selected={location.pathname === '/admin/report/balance'}>
                  <ListItemText primary={'Balance Report'}/>
               </ListItem>
               <ListItem button component={Link} to='/admin/report/detail' selected={location.pathname === '/admin/report/detail'}>
                  <ListItemText primary={'Detail Report'}/>
               </ListItem>
               <ListItem button component={Link} to='/admin/report/inspection' selected={location.pathname === '/admin/report/inspection'}>
                  <ListItemText primary={'Driver Vehicle Inspection'}/>
               </ListItem>
            </List>
            <Divider/>
            <Typography variant={'h6'} style={{marginLeft: 16}} color={'secondary'}>Manage</Typography>
            <ListItem button component={Link} to='/admin/report/unit' selected={location.pathname === '/admin/report/unit'}>
               <ListItemText primary={'Units'}/>
            </ListItem>
            <ListItem button component={Link} to='/admin/report/location' selected={location.pathname === '/admin/report/location'}>
               <ListItemText primary={'Locations'}/>
            </ListItem>
            <ListItem button component={Link} to={SETTINGS_PATH} selected={location.pathname === SETTINGS_PATH}>
               <ListItemText primary={'Settings'}/>
            </ListItem>
         </Drawer>
      );
   }
}

export default withRouter(withStyles(styles)(NavigationDrawer));
