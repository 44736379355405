import {Grid} from '@material-ui/core';
import TextField from '@material-ui/core//TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/es/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Query from 'react-apollo/Query';
import {intlShape, injectIntl} from 'react-intl';
import {HEADER_HEIGHT, DEFAULT_PATH} from '../Constants';
import Mutation from '../fhg/components/data/Mutation';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import Typography from '../fhg/components/Typography';
import {Prompt} from 'react-router-dom';
import {formatMessage} from '../fhg/utils/Utils';

const MAX_WIDTH = 500;

const styles = theme => ({
   buttonStyle: {
      margin: theme.spacing(1, 1, 1, 0),
   },
   unsizingFlex: {
      flex: '0 0 auto',
   },
   resizingContainer: {
      display: 'flex',
      flex: '1 1',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
      padding: `0 !important`,
   },
   outerContainer: {
      margin: -theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
      overflow: 'auto',
      flex: '1 1',
   },
   innerContainer: {
      margin: theme.spacing(1),
      maxHeight: '100%',
      maxWidth: MAX_WIDTH,
   },
   titleStyle: {
      marginBottom: theme.spacing(3),
   },
   inlineStyle: {
      display: 'inline-block',
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
   largeWidth: {
      maxWidth: MAX_WIDTH,
      minWidth: MAX_WIDTH / 2,
   },
   formStyle: {
      height: '100%',
      overflow: 'hidden',
   },
   formContainer: {
      margin: theme.spacing(2),
      maxHeight: `calc(100% - ${HEADER_HEIGHT}px)`,
      maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
   },
   editStyle: {
      flex: '0 0 auto',
   },
   spinnerStyle: {
      color: 'white',
      marginLeft: theme.spacing(0.5),
   },
});

const SETTINGS_FRAGMENT = gql`
    fragment settingsInfo on AlertTemplate {
        id
        fromEmail
        forAdmins
        name
        recipientList
        sendEmails
    }
`;

const alertTemplate = gql`
    query AlertTempleQuery {
        alertTemplate: alertTemplate_All {
            ...settingsInfo
        }
    }
    ${SETTINGS_FRAGMENT}
`

const UPDATE_SETTINGS = gql`
    mutation UpdateSettings($id: Int!, $fromEmail: String, $recipientList: [String]) {
        alertTemplate: alertTemplate_Update(alertTemplate: {fromEmail: $fromEmail, recipientList: $recipientList}, alertTemplateId: $id) {
            ...settingsInfo
        }
    }
    ${SETTINGS_FRAGMENT}
`;

/**
 * The settings edit.
 */
class SettingsEdit extends Component {

   static propTypes = {
      intl: intlShape,                       // Localization messages.
      classes: PropTypes.object.isRequired,  // The style for the component.
   };

   constructor(props, context) {
      // noinspection JSCheckFunctionSignatures
      super(props, context);

      this.state = {
         isChanged: false,
         errorId: undefined,
         fromEmail: undefined,
         toEmail: undefined
      };
      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape') {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(DEFAULT_PATH);
         }
      }
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   onChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
   };

   /**
    * Callback when the user submits the changes.
    */
   onSubmit = settingsUpdate => e => {
      const {toEmail, fromEmail} = this.state;
      e.preventDefault();
      this.setState({isSaving: true, mutationError: false});
      settingsUpdate({
         variables: {
            id: 1,
            fromEmail,
            recipientList: [toEmail],
         }
      }).then(() => {
         this.setState({isSaving: false, isChanged: false}, () => {
            this.props.history.replace(DEFAULT_PATH);
         });
      }).catch(mutationError => {
         console.log(mutationError);
         this.setState({mutationError});
      }).finally(() => {
         this.setState({isSaving: false});
      });
   };

   render() {
      const {intl, classes} = this.props;
      const {
         isChanged, fromEmail, toEmail, isSaving, mutationError
      } = this.state;

      return (
         <Query query={alertTemplate} fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <DataLoadingAndErrors isLoading={loading} error={error || mutationError} data={data}
                                     errorId={'location.loading.error'} values={{
                  action: !!mutationError ? 'saved' : 'loaded',
                  message: error && error.message
               }}>
                  {data => {
                     return (
                        <Mutation isAdd={false} updateMutation={UPDATE_SETTINGS} mutationKey={'settingsUpdate'}>
                           {settingsUpdate => (
                              <form onSubmit={this.onSubmit(settingsUpdate)} className={classes.formStyle}>
                                 {(!loading) && (
                                    <Grid container direction={'column'} wrap={'nowrap'}
                                          className={classes.formContainer}>
                                       <Prompt when={isChanged}
                                               message={() => formatMessage(intl, 'leavePage', 'Discard changes?')}/>

                                       <Grid item container className={classes.resizingContainer} spacing={0}
                                             direction={'column'} wrap={'nowrap'}>
                                          <Grid item className={classes.unsizingFlex} style={{width: '100%'}}>
                                             <Typography className={classes.titleStyle} variant={'h5'}
                                                         id={'settings.title'}/>
                                          </Grid>
                                          <Grid container className={classes.outerContainer} direction={'column'}
                                                xs={12}>
                                             <Grid container className={classes.innerContainer} direction={'column'}
                                                   xs={12} spacing={2} wrap={'nowrap'}>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <TextField
                                                      name='fromEmail'
                                                      className={classes.largeWidth}
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'settings.fromEmail.label'}
                                                                         nowrap/>}
                                                      fullWidth
                                                      autoFocus
                                                      required
                                                      defaultValue={get(data, 'alertTemplate[0].fromEmail')}
                                                      value={fromEmail}
                                                      onChange={this.onChange}
                                                   />
                                                </Grid>
                                                <Grid item className={classes.editStyle} xs={12}>
                                                   <TextField
                                                      name='toEmail'
                                                      className={classes.largeWidth}
                                                      label={<Typography className={classes.inlineStyle}
                                                                         variant='inherit'
                                                                         id={'settings.toEmail.label'}
                                                                         nowrap/>}
                                                      fullWidth
                                                      required
                                                      defaultValue={get(data, 'alertTemplate[0].recipientList[0]')}
                                                      value={toEmail}
                                                      onChange={this.onChange}
                                                   />
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                       <Grid item className={'no-print'} style={{padding: 0}}>
                                          <Button variant='contained' color='secondary'
                                                  className={`no-print ${classes.buttonStyle}`}
                                                  onClick={this.handleFilter} type={'submit'}>
                                             <SaveIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                                             <Typography color='inherit' id={'save.label'}>Save</Typography>
                                             {isSaving &&
                                             <CircularProgress className={classes.spinnerStyle} size={15}
                                                               thickness={2.5}/>}
                                          </Button>
                                       </Grid>
                                    </Grid>
                                 )}
                              </form>
                           )}
                        </Mutation>
                     )
                  }}
               </DataLoadingAndErrors>
            )}
         </Query>
      );
   }
}

export default injectIntl(withStyles(styles)(SettingsEdit));
