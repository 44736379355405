import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {lighten, darken} from '@material-ui/core/styles/colorManipulator';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import React from 'react';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
   overrides: {
      MuiFormLabel: { // Name of the component ⚛️ / style sheet
         asterisk: { // Name of the rule
            display: 'none', // Some CSS
         },
      },
   },
   palette: {
      primary: {
         light: lighten('#1E2021', 0.1),
         main: '#1E2021',
         dark: darken('#1E2021', 0.1),//'#21355A',
      },
      //#C32127
      secondary: {
         light: lighten('#A90004', 0.1),
         main: '#A90004',
         dark: darken('#A90004', 0.1),//'#21355A',
      },
   },
   typography: {
      fontFamily: '"prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif;',
   },
});

/**
 * An HOC component to handle setting up the Material-UI theme and provider.
 * @param Component
 * @return {function(*): *}
 */
function withRoot(Component) {
   function WithRoot(props) {
      // MuiThemeProvider makes the theme available down the React tree
      // thanks to React context.
      return (
         <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
               <CssBaseline/>
               <Component {...props} />
            </MuiPickersUtilsProvider>
         </MuiThemeProvider>
      );
   }

   return WithRoot;
}

export default withRoot;
