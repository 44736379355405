import PropTypes from 'prop-types';
import React, {PureComponent, Fragment} from 'react';
import Mutation from 'react-apollo/Mutation';
import {updateDelete} from '../../utils/DataUtil';
import ConfirmDialog from '../dialog/ConfirmDialog';

/**
 * Mutation component to delete items. Updates the cache of the updateQuery.
 */
export default class DeleteMutation extends PureComponent {

   static propTypes = {
      mutation: PropTypes.object.isRequired,       // Mutation used to delete.
      deletedItemId: PropTypes.number.isRequired,  // Id of the item being deleted.
      updateQuery: PropTypes.object.isRequired,    // Query to be updated for the delete.
      idKey: PropTypes.string.isRequired,          // The property name of the delete ID.
      updateKey: PropTypes.string.isRequired,      // The name of the values returned by the update query.
      children: PropTypes.any.isRequired,          // The child function to get the results of the mutation.
   };

   static defaultProps = {
      idKey: 'id',
   };

   state = {};

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.deletedItemId !== this.props.deletedItemId) {
         this.setState({showConfirmDelete: !!this.props.deletedItemId});
      }
   }

   submitDelete = doMutation => () => {
      const { deletedItemId, idKey } = this.props;
      const options = {
         variables: {
            [idKey]: deletedItemId
         }
      };
      this.closeConfirm();
      return doMutation(options).finally(() => {
         const { onUpdate } = this.props;

         onUpdate && onUpdate();
      });
   };

   handleCancel = () => {
      this.closeConfirm();
      this.props.onCancel && this.props.onCancel();
   };

   closeConfirm = () => {
      this.setState({showConfirmDelete: false});
   };

   render() {
      const {mutation, variables, deletedItemId, updateCache, confirmValues, confirmKey, title, children} = this.props;
      const { showConfirmDelete } = this.state;
      return (
         <Mutation mutation={mutation} variables={variables}
                   update={updateDelete(deletedItemId, updateCache)}
         >
            {(deleteCallback) => (
               <Fragment>
                  {showConfirmDelete && (
                     <ConfirmDialog title={title} messageKey={confirmKey} onCancel={this.handleCancel}
                                    onConfirm={this.submitDelete(deleteCallback)} values={confirmValues} confirmKey={'delete.button'}/>

                  )}
                  {children}
               </Fragment>
            )}
         </Mutation>
      );
   }
}
