import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {format} from 'date-fns-tz';
import gql from 'graphql-tag';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, createRef} from 'react';
import Query from 'react-apollo/Query';
import {Link} from 'react-router-dom';
import 'react-table/react-table.css';
import {DATE_FORMAT_TIME_ZONE} from '../Constants';
import DashboardTable from '../fhg/components/DashboardTable';
import DisplayError from '../fhg/components/DisplayError';
import Typography from '../fhg/components/Typography';
import AddIcon from '@material-ui/icons/Add';

// Styles for the table.
const styles = theme => ({
   outerFrame: {
      margin: theme.spacing(0, 2, 2, 2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      overflow: 'hidden',
      height: '100%',
   },
   addStyle: {
      width: 'fit-content',
   }
});

const vehicleDetailReport = gql`
   query getVehicleDetailReport($statusId: [Int], $submitToId: [Int], $location: [String], $eventId: [Int], $unitId: [Int], $startDate: String, $endDate: String)
   {
      vehicleDetailReport(statusId: $statusId, submitToId: $submitToId, location: $location, eventTypeId: $eventId, truckId: $unitId, startDate: $startDate, endDate: $endDate) {
         id:transactionId
         Status
         FuelBegin
         FuelEnd
         Transaction
         TicketNumber
         Event
         GrossGallons
         NetGallons
         DTLSupplier
         Unit
         Location
         City
         State
         Product
         BeginLat
         BeginLong
         EndLat
         EndLong
         LocoId
         LocoNumber
         GangID
         CNumber
         TotalizerBegin
         TotalizerEnd
         ValidityCheckText
         Timezone
         SubmitTo
      }
   }
`;

/**
 * The table of data for the devices.
 */
class UnitBalanceTable extends Component {
   tableRef = createRef();

   state = {};

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      search: PropTypes.string,                 // A search string for filtering the table.
      isLoading: PropTypes.bool,                // Indicates if the table data is being loaded.
      disabled: PropTypes.bool,                 // True if the table should be disabled.
      onQuery: PropTypes.func,                  // Supplied by the withRequest HOC to perform the query on the server.
   };

   /*
   Status,Event,City,Driver,Ticket/BOL,Start Date,Start Time,Gallons,Net Gallons,Loco ID,Loco #,Totalizer Start,Totalizer End,Loaded,Delivered,Adjusted,Balance,Start Day,Springfield,,,,,,,,,,,,,,8043.5 */
   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   getColumns = () => {
      return [
         {
            id: 'Status',
            Header: <Typography id={'detail.status.column'}/>,
            accessor: 'Status',
            minWidth: 100,
            Footer: <Typography>Totals</Typography>
         }, {
            id: 'Fuel Begin',
            Header: <Typography id={'detail.fuelBegin.column'} hasLineBreaks/>,
            accessor: 'FuelBegin',
            Cell: row => {
               if (row.value) {
                  const dateInTimezone = new Date(new Date(row.value).toLocaleString("en-US", {timeZone: row.original.Timezone}));
                  return format(dateInTimezone, DATE_FORMAT_TIME_ZONE, {timeZone: row.original.Timezone});
               } else {
                  return 'N/A';
               }
            },
            minWidth: 185,
         }, {
            id: 'Fuel End',
            Header: <Typography id={'detail.fuelEnd.column'} hasLineBreaks/>,
            accessor: 'FuelEnd',
            Cell: row => {
               if (row.value) {
                  const dateInTimezone = new Date(new Date(row.value).toLocaleString("en-US", {timeZone: row.original.Timezone}));
                  return format(dateInTimezone, DATE_FORMAT_TIME_ZONE, {timeZone: row.original.Timezone});
               } else {
                  return 'N/A';
               }
            },
            minWidth: 185,
         }, {
            id: 'SubmitTo',
            Header: <Typography id={'detail.submitTo.column'}/>,
            accessor: 'SubmitTo',
            minWidth: 85,
         }, {
            id: 'Transaction',
            Header: <Typography id={'detail.transaction.column'}/>,
            accessor: 'Transaction',
            Cell: row => <Link to={`/admin/edit/transaction/${row.value}`}>{row.value}</Link>,
            minWidth: 125,
         }, {
            id: 'Ticket',
            Header: <Typography id={'detail.ticketBOL.column'}/>,
            accessor: 'TicketNumber',
            minWidth: 100,
         }, {
            id: 'Unit',
            Header: <Typography id={'detail.unit.column'}/>,
            accessor: 'Unit',
            minWidth: 125,
         }, {
            id: 'Event',
            Header: <Typography id={'detail.event.column'}/>,
            accessor: 'Event',
            minWidth: 100,
         }, {
            id: 'GrossGallons',
            Header: <Typography hasLineBreaks id={'detail.grossGallons.column'}>Gross Gallons</Typography>,
            accessor: 'GrossGallons',
            minWidth: 105,
            Footer: table => (
               <span>
                  {_.round(_.sumBy(table.data, d => Number(d['GrossGallons'] || 0)).toFixed(1), 1)}
               </span>
            )
         }, {
            id: 'NetGallons',
            Header: <Typography hasLineBreaks id={'detail.netGallons.column'}>Net Gallons</Typography>,
            accessor: 'NetGallons',
            minWidth: 105,
            Footer: table => (
               <span>
                  {_.round(_.sumBy(table.data, d => Number(d['NetGallons'] || 0)).toFixed(1), 1)}
               </span>
            )
         }, {
            id: 'DTLSupplier',
            Header: <Typography hasLineBreaks id={'detail.dtlSupplier.column'}>DTL Supplier</Typography>,
            accessor: 'DTLSupplier',
            minWidth: 120,
         }, {
            id: 'Location',
            Header: <Typography  id={'detail.location.column'}/>,
            accessor: 'Location',
            minWidth: 125,
         }, {
            id: 'City',
            Header: <Typography  id={'detail.city.column'}/>,
            accessor: 'City',
            minWidth: 135,
         }, {
            id: 'State',
            Header: <Typography id={'detail.state.column'}/>,
            accessor: 'State',
            minWidth: 75,
         }, {
            id: 'Product',
            Header: <Typography id={'detail.product.column'}/>,
            accessor: 'Product',
            minWidth: 150,
         }, {
            id: 'Begin Lat',
            Header: <Typography id={'detail.beginLat.column'}/>,
            accessor: 'BeginLat',
            minWidth: 95,
         }, {
            id: 'Begin Long',
            Header: <Typography id={'detail.beginLong.column'}/>,
            accessor: 'BeginLong',
            minWidth: 95,
         }, {
            id: 'End Lat',
            Header: <Typography id={'detail.endLat.column'}/>,
            accessor: 'EndLat',
            minWidth: 95,
         }, {
            id: 'End Long',
            Header: <Typography id={'detail.endLong.column'}/>,
            accessor: 'EndLong',
            minWidth: 95,
         }, {
            id: 'Loco Id',
            Header: <Typography id={'detail.locoId.column'}/>,
            accessor: 'LocoId',
            minWidth: 75,
         }, {
            id: 'Loco #',
            Header: <Typography id={'detail.locoNumber.column'}/>,
            accessor: 'LocoNumber',
            minWidth: 75,
         }, {
            id: 'GangID',
            Header: <Typography id={'detail.gangId.column'}/>,
            accessor: 'GangID',
            minWidth: 75,
         }, {
            id: 'CNumber',
            Header: <Typography id={'detail.cNumber.column'}/>,
            accessor: 'CNumber',
            minWidth: 75,
         }, {
            id: 'TotalizerBegin',
            Header: <Typography id={'detail.totalBegin.column'} hasLineBreaks/>,
            accessor: 'TotalizerBegin',
            minWidth: 95,
         }, {
            id: 'TotalizerEnd',
            Header: <Typography id={'detail.totalEnd.column'} hasLineBreaks/>,
            accessor: 'TotalizerEnd',
            minWidth: 95,
         }, {
            id: 'ValidityCheckText',
            Header: <Typography id={'detail.reason.column'}/>,
            accessor: 'ValidityCheckText',
            minWidth: 300,
         },
      ]
   };

   render() {
      let {classes, isDisabled, search, tableRef, filters} = this.props;

      return (
         <Query query={vehicleDetailReport} variables={filters} skip={filters === undefined} fetchPolicy={'network-only'}>
            {({loading, error, data}) => (
               <Grid item className={classes.outerFrame}>
                  <DisplayError error={error}/>
                  <Button className={classes.addStyle} component={Link} to={`/admin/edit/transaction/new`}
                          color={'secondary'} variant={'flat'}>
                     <AddIcon color={'inherit'}/>
                     <Typography color={'secondary'}>Add Transaction</Typography>
                  </Button>

                  <DashboardTable name='Unit Detail Report' innerRef={tableRef} data={data && data.vehicleDetailReport} getColumns={this.getColumns} isLoading={loading}
                                  disabled={isDisabled}
                                  search={search}/>
               </Grid>
            )}
         </Query>
      );
   }
}

// The initial fetch will be only the data from today.
export default withStyles(styles)(UnitBalanceTable);
