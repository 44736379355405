import { CircularProgress, withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { removeOne } from '../../utils/Utils';
import ConfirmDialog from '../dialog/ConfirmDialog';
import ErrorSnackbar from '../ErrorSnackbar';
import addUrlParams from '../UrlAsPropertyHOC';

function serverError(errorMsg) {
   return errorMsg.replace('GraphQL error:', ' ').trim();
}

/**
 * Add a graphQL query to the wrapped component. The HOC will handle loading indicators and errors.
 *
 * If the add and update mutations are present, an onSubmit callback will be added to the wrapped component. The wrapped
 * component should call this on submit with the new or updated item. onSubmit assumes all the data has been validated
 * and will return a promise. The wrapped component can use the promise to update status (e.g. isChanged). The saving
 * indicator and the errors are handled by DataHOC.
 *
 * Styles:
 *    data - applies to the outer div.
 *
 * @param WrappedComponent The component which will use the data.
 * @param options The options for the query
 *    query The graphQL query to execute.
 *    dataKeys   The result key name(s) of the query.
 *    errorMessage The message for the Toast message and remaining message.
 *    showOnLoad True if the component handles loading indicator. Defaults to false.
 *    skip: True if the query should be skipped.
 *    requiredProp: A property that is required. If not present, the query will be skipped.
 *    useUrl: Indicates that the URL should be used for props. If there is a URL param and prop by the same name, the
 *       param will be used.
 *    addMutation: The mutation for adding an item to the data.
 *    updateMutation: The mutation for updating an item in the data.
 *    mutationError: The error message to display when the add or update fails.
 *    deleteMutation: The mutation for deleting the item in the data.
 *    deleteError: The error message to display when the delete fails.
 *    deleteConfirm: The message for confirming the delete.
 * @return {<{}> | *} The HOC.
 */
const addQuery = (options) => WrappedComponent => {

   if (!Array.isArray(options.dataKeys)) {
      options.dataKeys = [ options.dataKeys ];
   }

   const styles = theme => ({
      progress: {
         position: 'fixed',
         zIndex: 501,
         margin: 0,
      },
      message: {
         backgroundColor: theme.palette.error.main,
      },
   });

   class DataHOC extends Component {

      static contextTypes = {
         client: PropTypes.object,
      };

      state = {
         showError: false,
         errorMessage: undefined,
         isSaving: false,
         isConfirmDelete: false,
      };

      componentWillReceiveProps(nextProps) {
         if (nextProps.error !== this.props.error) {
            this.setState({ showError: nextProps.error, errorMessage: options.errorMessage, values: { message: serverError(nextProps.error.message) }})
         }
      }

      handleClose = () => {
         this.setState({ showError: false });
      };

      onQuery = (name, variables, fetchPolicy) => {
         const { queries } = options;

         if (queries && queries[name] && queries[name].query) {
            this.setState({isSaving: true});
            return this.context.client.query({
               query: queries[name].query,
               variables,
               fetchPolicy,
            }).then((result) => {
               this.setState({isSaving: false});
               return result.data;
            }, (error) => {
               this.setState({
                  isSaving: false,
                  errorMessage: queries[ name ].error,
                  showError: true,
                  values: { message: serverError(error.message) }
               });
               console.log(error.message);
               throw error;
            })
         }
      };

       updateAdd = (proxy, { data }) => {
          const query = options.updateQuery || options.query
          const queryData = proxy.readQuery({ query: query });
          const usingDataKey = queryData[ options.dataKeys[ 0 ] ];
          if (usingDataKey) {
             usingDataKey.push(data[ options.updateKey]);
          } else {
             queryData[ options.updateKey ].push(data[ options.dataKeys[ 0 ] ]);
          }
          proxy.writeQuery({ query: query, data: queryData })
       };

       updateDelete = (variables) => (proxy, { data }) => {
          const queryData = proxy.readQuery({ query: options.query });
          const selectedIndex = queryData[ options.dataKeys[ 0 ] ].findIndex(item => item.id === variables.id);
          if (selectedIndex >= 0) {
             removeOne(queryData[ options.dataKeys[ 0 ] ], selectedIndex);
             proxy.writeQuery({ query: options.query, data: queryData })
          } else {
             console.log('Could not find item to remove from cache on delete.');
          }
       };

      onSubmit = (name, variables, confirmed = false, messageOptions) => {
         const { mutations } = options;

         if (mutations[ name ] && mutations[ name ].query) {
            //If this mutation needs to be confirmed, but hasn't been confirmed yet.
            if (!confirmed && mutations[ name ].confirm) {
               this.setState({ isConfirm: true, name, variables, messageOptions });
               return;
            }

            this.setState({ isSaving: mutations[ name ].showProgress !== false, isConfirm: false, name: undefined, variables: undefined });

            let update;
            if (options.updateKey || options.updateQuery) {
               if (name === 'add') {
                  update = this.updateAdd;
               } else if (name === 'delete') {
                  update = this.updateDelete(variables);
               }
            }

            return this.context.client.mutate({
               mutation: mutations[ name ].query,
               variables,
               update,
            }).then((result) => {
               this.setState({ isSaving: false, showError: false, data: result.data });
               return result.data;
            }, (saveError) => {
               this.setState({
                  isSaving: false,
                  errorMessage: mutations[ name ].error,
                  showError: true,
                  values: { message: serverError(saveError.message) }
               });
               console.log(saveError.message);
               throw saveError;
            })
         } else {
            console.log(
               `To use onSubmit, include the mutation ${name}. For example: options = {mutations: {${name}: { query: [QueryName], confirm: [Optional message. not needed if no confirmation], error: [Error Message]}}}`)
            return Promise.reject();
         }
      };

      onDelete = (id, messageOptions) => {
         if (get(options, 'mutations.delete')) {
            return this.onSubmit('delete', { id, repoFullName: 'apollographql/apollo-client' }, undefined, messageOptions);
         } else {
            console.log(
               'To use onDelete, include a delete mutation. For example: options = {mutations: {delete: { query: DeleteEmployee, confirm: \'Delete the employee?\', error: \'Cannot delete the employee\'}}}')
            return Promise.resolve();
         }
      };

      onCancelConfirm = () => {
         this.setState({ isConfirm: false, name: undefined, variables: undefined });
      };

      onConfirm = () => {
         const { name, variables } = this.state;

         this.onSubmit(name, variables, true)
      };

      render() {
         let { isLoading, error, data, className, classes, ...props } = this.props;
         const { showError, isSaving, errorMessage, isConfirm, name, values, messageOptions } = this.state;

         if (isLoading && options.showOnLoad !== true) {
            if (options.showProgress !== false) {
               return (
                  <Grid container className={classes.progress} justify='center'>
                     <CircularProgress/>
                  </Grid>
               )
            } else {
               return null;
            }
         } else if (error && options.showOnLoad !== true) {
            return (
               <div>
                  <ErrorSnackbar open={showError} errorId={errorMessage} values={values} onClose={this.handleClose}
                                 enableRefresh={false}/>
               </div>
            );
         } else {
            let resultsObject = {};
            const currentData = data || this.state.data;
            if (currentData) {
               for (let i = 0; i < options.dataKeys.length; i++) {
                  let key = options.dataKeys[ i ];
                  resultsObject[ key ] = currentData[ key ];
                  if (options.requiredProp) {
                     resultsObject[ options.requiredProp ] = resultsObject[ key ][ options.requiredProp ];
                  }
               }
            }
            return (
               <div className={classNames(className, classes.data)}>
                  {(isLoading || isSaving) && options.showProgress !== false && !showError && (
                     <Grid container className={classes.progress} justify='center' alignItems='center'>
                        <CircularProgress/>
                     </Grid>
                  )}
                  <ConfirmDialog open={isConfirm}
                                 messageKey={get(options, `mutations[${name}].confirm`)} onCancel={this.onCancelConfirm}
                                 onConfirm={this.onConfirm} values={messageOptions} confirmKey={get(options, `mutations[${name}].confirmLabel`)}/>
                  <ErrorSnackbar open={showError} errorId={errorMessage} values={values} onClose={this.handleClose}
                                 enableRefresh={false}/>
                  <WrappedComponent {...props} {...resultsObject} client={this.context.client} onDataSubmit={this.onSubmit}
                                    onDelete={this.onDelete} onQuery={this.onQuery} isLoading={options.showOnLoad === true && isLoading} isSaving={isSaving}
                  />
               </div>
            );
         }
      }
      ;
   }

   function shouldSkip(ownProps) {
      if (!options.query || options.skip) {
         return true;
      }
      if (options && options.requiredProp) {
         const requiredProp = ownProps[ options.requiredProp ];
         return !requiredProp && requiredProp !== 0;
      }
      return false;
   }

   if (options.useUrl) {
      return addUrlParams(graphql(options.query, {
         skip: shouldSkip,
         props: ({ ownProps, data, data: { loading, error } }) => ({
            isLoading: loading,
            data,
            error,
         }),
      })(withStyles(styles)(DataHOC)));
   } else if (options.query) {
      return graphql(options.query, {
         skip: shouldSkip,
         props: ({ ownProps, data, data: { loading, error } }) => ({
            isLoading: loading,
            data,
            error,
         }),
      })(withStyles(styles)(DataHOC));
   } else {
      return withStyles(styles)(DataHOC);
   }
};

export default addQuery;
