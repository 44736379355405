import {Page, Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import {format} from 'date-fns-tz';
import get from 'lodash/get';
import React, {Component} from 'react';
import {CHECKBOX_OUTLINE, CHECKBOX, DATE_FORMAT_TIME_ZONE} from '../Constants';

Font.register({
   family: 'montserrat', fonts: [
      {src: '/fonts/montserrat-regular-webfont.ttf'}, // font-style: normal, font-weight: normal
      {src: '/fonts/montserrat-bold-webfont.ttf', fontWeight: 700},
   ]
});

const styles = StyleSheet.create({
   page: {
      flexDirection: 'column',
      // backgroundColor: '#E4E4E4',
      display: 'flex',
   },
   section1: {
      margin: '0 32 8',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
   },
   section2: {
      padding: '8 32 0 32',
      justifyContent: 'space-between',
      flexGrow: 0,
      flexDirection: 'row',
      width: '100%',
   },
   section4: {
      padding: '16 16 16 60',
      alignContent: 'center',
      flex: '1 1',
      flexDirection: 'row',
      width: '100%',
   },
   section5: {
      padding: '0 32 16 148',
      alignContent: 'center',
      // justifyContent: 'center',
      flex: '0 0 auto',
      flexDirection: 'row',
      width: '100%',
   },
   section6: {
      padding: '0 24 16 60',
      flex: '0 0 auto',
      flexDirection: 'column',
      width: '100%',
      minHeight: 130,
      maxHeight: 180,
   },
   section6a: {
      flex: '0 0 auto',
      flexDirection: 'row',
   },
   section6b: {
      flex: '0 0 auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingBottom: 16,
   },
   section7: {
      padding: '0 32 8 300',
      alignContent: 'center',
      // justifyContent: 'center',
      flex: '0 0 auto',
      flexDirection: 'row',
      // width: '100%',
   },
   section8: {
      padding: '0 32 8 60',
      // alignContent: 'center',
      // justifyContent: 'center',
      flex: '0 0 auto',
      flexDirection: 'row',
      // width: '100%',
   },
   edgeSubSection: {
      margin: '0',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'center',
      flex: '1 1',
      flexDirection: 'row'
   },
   edgeSubSection2: {
      margin: '0',
      display: 'flex',
      alignItems: 'center',
      // // justifyContent: 'center',
      flex: '1 1',
      flexDirection: 'row'
   },
   middleSubSection: {
      margin: '0 8',
      // justifyContent: 'center',
      flexGrow: 0,
      flexDirection: 'row'
   },
   checkboxFrameStyle: {
      display: 'flex',
      flex: '0 0 auto',
      // flexGrow: 0,
      // width: 630,
      flexDirection: 'column',
      justifyContent: 'space-around',
   },
   checkboxFrameStyle2: {
      padding: '0 32 8 32',
      display: 'flex',
      flex: '0 0 auto',
      // flexGrow: 0,
      // width: 630,
      flexDirection: 'row',
      justifyContent: 'space-around',
   },
   labelStyle: {
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: 'montserrat',
      fontWeight: 'bold',
      paddingRight: 8,
      textTransform: 'uppercase',
   },
   labelStyle2: {
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: 'montserrat',
      fontWeight: 500,
      paddingRight: 8,
      textTransform: 'uppercase',
   },
   nameStyle: {
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: 'montserrat',
      fontWeight: 550,
      paddingRight: 8,
   },
   valueStyle: {
      fontSize: 12,
      fontFamily: 'montserrat',
      lineHeight: 1.5,
      paddingRight: 8,
   },
   titleStyle: {
      fontSize: 16,
      lineHeight: 1,
      fontFamily: 'montserrat',
      fontWeight: 'bold',
      padding: '16 8 0 8',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
   },
   subTitleStyle: {
      fontSize: 12,
      lineHeight: 1,
      fontFamily: 'montserrat',
      padding: '4 0 16 8',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
   },
   checkboxStyle: {
      flex: '1 1',
      fontSize: 10,
      fontFamily: 'montserrat',
   },
   checkboxStyle2: {
      flex: '1 1',
      fontSize: 12,
      fontFamily: 'montserrat',
   },
   textStyle2: {
      flex: '1 1',
      fontSize: 10,
      fontFamily: 'montserrat',
      lineHeight: 1.5,
   },
   textStyle3: {
      flex: '1 1',
      fontSize: 10,
      fontFamily: 'montserrat',
      fontWeight: 'bold',
      lineHeight: 1.5,
   }
});

export default class DriverVehicleInspectionPdfPage extends Component {

   shouldComponentUpdate(nextProps, nextState, nextContext) {
      return false;
   }

   render() {
      const {report = {}} = this.props;
      let useDate = get(report, 'date');
      if (useDate) {
         const dateInTimezone = new Date(new Date(useDate).toLocaleString());
         useDate = format(dateInTimezone, DATE_FORMAT_TIME_ZONE);
      } else {
         useDate = 'N/A';
      }

      const vehicleIssues = get(report, 'vehicleIssues') || [];
      const trailerIssues = get(report, 'trailerIssues') || [];

      return (
         <Page size='A4' orientation='landscape' style={styles.page}>
            <View>
               <Text style={styles.titleStyle}>Driver's Vehicle Inspection Report</Text>
               <Text style={styles.subTitleStyle}>As Required By the D.O.T. Federal Motor Carrier Safety Regulations, I
                  Submit the Following:</Text>
            </View>
            <View style={styles.section1}>
               <Text style={styles.labelStyle}>Carrier:</Text>
               <Text style={styles.valueStyle}>McAlister Transportation</Text>
            </View>
            <View style={styles.section2}>
               <View style={styles.edgeSubSection}>
                  <Text style={styles.labelStyle}>Date:</Text>
                  <Text style={styles.valueStyle}>{useDate}</Text>
               </View>
               <View style={styles.middleSubSection}>
                  <Text style={styles.labelStyle}>Tractor/Truck No.:</Text>
                  <Text style={styles.valueStyle}>{get(report, 'identityNumber') || 'N/A'}</Text>
               </View>
               <View style={styles.edgeSubSection}>
                  <Text style={styles.labelStyle}>Trailer(s) No.(s):</Text>
                  <Text style={styles.valueStyle}>{get(report, 'trailerNumber') || 'N/A'}</Text>
               </View>
            </View>
            <View style={styles.section4}>
               <View style={styles.checkboxFrameStyle}>
                  <View style={styles.edgeSubSection}>
                     <Image src={get(report, 'conditionSatisfactory') || false ? CHECKBOX : CHECKBOX_OUTLINE}
                            style={{height: 12, marginRight: 8}}/>
                     <Text style={styles.checkboxStyle}>
                        I detect no defect or deficiency in this motor vehicle as would be
                        likely to affect the safety of its operation or result in its mechanical breakdown.
                     </Text>
                  </View>
               </View>
            </View>
            <View style={styles.section5}>
               <Text style={styles.textStyle2}>
                  {'Indicate whether defects are on '}
               </Text>
               <Text style={styles.textStyle3}>
                  {'TRACTOR / TRUCK  '}
               </Text>
               <Text style={styles.textStyle2}>
                  {' or '}
               </Text>
               <Text style={styles.textStyle3}>
                  {'TRAILER '}
               </Text>
               <Text style={styles.textStyle2}>
                  {' - Use sufficient detail to locate for mechanic.'}
               </Text>
            </View>
            <View style={styles.section6}>
               {vehicleIssues.length > 0 && (
                  <View style={styles.section6b}>
                     <Text style={styles.labelStyle}>
                        TRACTOR / TRUCK -
                     </Text>
                     {vehicleIssues.map((issue) => (
                           <View style={styles.section6a}>
                              <Text style={styles.nameStyle}>
                                 {issue.name}
                              </Text>
                              <Text style={styles.valueStyle}>
                                 {issue.remarks}
                              </Text>
                           </View>
                        )
                     )}
                  </View>
               )}
               {trailerIssues.length > 0 && (
                  <View style={styles.section6b}>
                     <Text style={styles.labelStyle}>
                        TRAILER -
                     </Text>
                     {trailerIssues.map((issue) => (
                           <View style={styles.section6a}>
                              <Text style={styles.nameStyle}>
                                 {issue.name}
                              </Text>
                              <Text style={styles.valueStyle}>
                                 {issue.remarks}
                              </Text>
                           </View>
                        )
                     )}
                  </View>
               )}
            </View>
            <View style={styles.section7}>
               <Text style={styles.labelStyle2}>Driver's Signature:</Text>
               <Text style={styles.valueStyle}>{get(report, 'driverSigPrimary') || 'N/A'}</Text>
            </View>
            <View style={styles.checkboxFrameStyle2}>
               <View style={styles.edgeSubSection2}>
                  <Image src={get(report, 'defectsCorrected') || false ? CHECKBOX : CHECKBOX_OUTLINE}
                         style={{height: 12, marginRight: 8}}/>
                  <Text style={styles.checkboxStyle2}>Above defects corrected</Text>
               </View>
               <View style={styles.edgeSubSection2}>
                  <Image src={get(report, 'correctionNotRequired') || false ? CHECKBOX : CHECKBOX_OUTLINE}
                         style={{height: 12, marginRight: 8}}/>
                  <Text style={styles.checkboxStyle2}>Above defects need not be corrected for safe operation of
                     vehicle</Text>
               </View>
            </View>
            <View style={styles.section8}>
               <Text style={styles.labelStyle2}>Mechanic's Signature:</Text>
               <Text style={styles.valueStyle}>{get(report, 'mechanicSig') || 'N/A'}</Text>
            </View>
            <View style={styles.section8}>
               <Text style={styles.labelStyle2}>Driver's Signature:</Text>
               <Text style={styles.valueStyle}>{get(report, 'driverSigSecondary') || 'N/A'}</Text>
            </View>
         </Page>
      );
   }
}
