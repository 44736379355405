import {createStyles, Checkbox} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Auth} from 'aws-amplify';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {DEFAULT_EMAIL} from '../Constants';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import DisplayError from '../fhg/components/DisplayError';
import Typography from '../fhg/components/Typography';

const styles = (theme) =>
   createStyles({
      inlineStyle: {
         display: 'inline-block',
      },
   });

const SET_USER_ADMIN = gql`
   mutation SetUserAdmin($username: String!) {
      user_SetAdmin(username: $username)
   }
`;

const USER_SYNC = gql`
   mutation USER_SYNC {
      user_Sync
   }
`;

class MySignUp extends PureComponent {
   static contextTypes = {
      client: PropTypes.object,
   };

   state = {
      open: true,
      username: undefined,
      showPassword: false,
   };

   handleClose = () => {
      this.setState({open: false}, this.props.onClose);
   };

   /**
    * The show password button was clicked.
    *
    * @param event The show password button click
    */
   onShowPasswordClick = event => {
      event.preventDefault();

      this.setState({showPassword: !this.state.showPassword, errorId: undefined}, this.checkPassword);
   };

   handleChange = ({target}) => {
      this.setState({[target.name]: target.value}, this.createDefaultUsername);
   };

   handleCheckboxChange = ({target}) => {
      this.setState({[target.name]: target.checked, isChanged: true});
   };

   checkPassword = () => {
      const target = document.getElementById('confirm_password');
      if (target) {
         target.setCustomValidity(this.state.confirm !== this.state.password ? 'Confirm does not match the password.' : '');
      }
   };

   handlePasswordChange = ({target}) => {
      this.setState({[target.name]: target.value}, this.checkPassword);
   };

   createDefaultUsername = debounce(() => {
      const {firstName, lastName, username} = this.state;
      if (!username && firstName && lastName) {
         this.setState(
            {defaultUsername: `${(firstName || '').toLocaleLowerCase()}.${(lastName || '').toLocaleLowerCase()}`});
      }
   }, 1500);

   handleSubmit = async () => {
      const username = this.state.username || this.state.defaultUsername;
      const signup_info = {
         username,
         password: this.state.password,
         attributes: {
            email: this.state.email || DEFAULT_EMAIL,
            'custom:firstName': this.state.firstName,
            'custom:lastName': this.state.lastName,
         }
      };

      try {
         await Auth.signUp(signup_info);
         await this.context.client.mutate({
            mutation: this.state.isAdmin ? SET_USER_ADMIN : USER_SYNC,
            variables: {username},
         });
         this.handleClose();
      } catch (error) {
         console.log(error);
         let errorId;
         if (error.code === 'InvalidParameterException') {
            errorId = 'user.invalidParameter.error';
         } else if (error.code === 'InvalidPasswordException') {
            errorId = 'user.policy.error';
            error.message = error.message.replace('Password did not conform with policy: ', '');
         }  else {
            errorId = 'user.createAccount.error';
         }
         this.setState({errorId, error, showError: true})
      }
   };

   render() {
      const {classes,} = this.props;
      const {open, showPassword, isEnabled = true, errorId, isNew = true, firstName, username, defaultUsername, email, lastName, password, confirm, error, } = this.state;
      return (
         <ModalDialog open={open} title={'Create User Account'} onClose={this.handleClose} maxWidth={'sm'} isForm={true} onSubmit={this.handleSubmit} submitKey={'user.createAccount.button'}>
            <DisplayError error={error} errorId={errorId} enableRefresh={false}/>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <TextField
                     name='firstName'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'user.firstName.label'}>First Name</Typography>}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     value={firstName}
                     onBlur={() => this.createDefaultUsername.flush()}
                     onChange={this.handleChange}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     name='lastName'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'user.lastName.label'}>First Name</Typography>}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     value={lastName}
                     onBlur={() => this.createDefaultUsername.flush()}
                     onChange={this.handleChange}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     key={defaultUsername + 'key'}
                     name='username'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'user.username.label'}>Username</Typography>}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     defaultValue={defaultUsername}
                     value={username}
                     onChange={this.handleChange}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     name='email'
                     type='email'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'user.email.label'}/>}
                     fullWidth
                     disabled={!isEnabled}
                     defaultValue={DEFAULT_EMAIL}
                     value={email}
                     onChange={this.handleChange}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     name='password'
                     inputProps={{pattern:'^[0-9]{6}[0-9]*$', title:'Password must be a 6 or more digit long number'}}
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={isNew ? 'user.newPassword.label' :
                                           'user.changePassword.label'}>Password</Typography>}
                     fullWidth
                     required={isNew}
                     disabled={!isEnabled}
                     type={showPassword ? 'text' : 'password'}
                     autoComplete='current-password'
                     onChange={this.handlePasswordChange}
                     value={password}
                     // eslint-disable-next-line
                     InputProps={{
                        'aria-label': 'Password',
                        endAdornment: (
                           <InputAdornment position='end'>
                              <IconButton
                                 aria-label='Toggle password visibility'
                                 onMouseDown={this.onShowPasswordClick}
                                 disabled={!isEnabled}
                              >
                                 {showPassword ? <VisibilityOff/> : <Visibility/>}
                              </IconButton>
                           </InputAdornment>
                        )
                     }}
                  />
               </Grid>
               {!showPassword && (
                  <Grid item xs={12} sm={6}>
                     <TextField
                        name='confirm'
                        label={<Typography className={classes.inlineStyle} variant='inherit'
                                           id={'user.confirmPassword.label'}>Confirm</Typography>}
                        type={'password'}
                        required={isNew}
                        onChange={this.handlePasswordChange}
                        value={confirm}
                        autoComplete='current-password'
                        fullWidth
                        disabled={!isEnabled}
                        InputProps={{id: 'confirm_password'}}
                     />
                  </Grid>
               )}
               <Grid item xs={12} sm={3}>
                  <FormControlLabel
                     control={
                        <Checkbox
                           name={'isAdmin'}
                           color='default'
                           checked={this.state.isAdmin}
                           onChange={this.handleCheckboxChange}
                           disabled={!isEnabled}
                           value='isAdmin'
                        />
                     }
                     label={<Typography id={'user.isAdmin.label'}/>}
                  />
               </Grid>
            </Grid>
         </ModalDialog>
      );
   }
}

export default withStyles(styles)(MySignUp);
/**
 * The custom AWS Cognito Sign In component.
 */
// class MySignUp extends SignUp {
