import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import LocationFilter from '../components/LocationFilter';
import LocationTable from '../components/LocationTable';
import Link from 'react-router-dom/Link';

const styles = theme => ({
   outerGridStyle: {
      paddingBottom: theme.spacing(1) + 2,
      flex: '1 1 100%',
      overflow: 'hidden',
   },
   innerGridStyle: {
      paddingTop: theme.spacing(1),
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
   },
   addStyle: {
      width: 'fit-content',
      marginLeft: theme.spacing(2),
   }
});

class LocationList extends React.Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,
   };

   state = {
      filters: [],
   };

   handleFilter = (filters) => {
      this.setState({filters});
   };

   render() {
      const {classes, search, tableRef, isSaving} = this.props;

      return (
         <Grid container className={classes.outerGridStyle}>
            <Grid item xs={12} sm={12} md={12} className={classes.innerGridStyle}>
               <LocationFilter onFilter={this.handleFilter} storeName={'locationFilter'}/>
               <Button className={classes.addStyle} component={Link}
                       to={{pathname: `/admin/edit/location/0`, state: this.state.filters}} color={'secondary'}
                       variant={'flat'} disabled={isSaving}>
                  <AddIcon color={'inherit'}/>
                  <Typography color={'secondary'}>Add Location</Typography>
               </Button>
               <LocationTable tableRef={tableRef} search={search} filters={this.state.filters}/>
            </Grid>
         </Grid>
      );
   }
}

export default withStyles(styles)(LocationList);
