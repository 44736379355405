export const CHECKBOX_OUTLINE = '/images/baseline_check_box_outline_blank_black_18dp.png';
export const CHECKBOX = '/images/baseline_check_box_black_18dp.png';

// Dates displayed to the user.
export const DATE_FORMAT = 'M/d/yyyy HH:mm';
export const DATE_FORMAT_TIME_ZONE = 'M/d/yyyy HH:mm (zzz)';
export const DATE_LONG_FORMAT = 'M/d/yyyy hh:mm:ss a';

// Delay before the filter changes will cause a fetch to the server.
export const FILTER_DEBOUNCE = 2000;

export const LOGO_SMALL= '/logo.svg';
export const PDF_DOWNLOAD_ICON = '/images/pdf_download.svg';


export const MAX_CSV_ROWS = 18000;

export const drawerWidth = 250;

// export const HOST = 'mcoil-test-pool.auth.us-east-2.amazoncognito.com';
export const HOST = 'mcoil-demo.zbfqcskpj5.us-east-2.elasticbeanstalk.com';
// export const HOST = 'localhost:8081';
// export const ENDPOINT = `http://mcoil-demo.zbfqcskpj5.us-east-2.elasticbeanstalk.com/api/graphql`;
export const ENDPOINT = `http://${HOST}/api/graphql/`;

export const HEADER_HEIGHT = 64;

export const DEFAULT_EMAIL = 'support@mcalisteroil.com';

export const ADMIN_GROUP = 'userAdmin';

// So that the detail report doesn't show data from the beginning, set the number of days back from the current day.
export const DAYS_BACK_FOR_DETAIL_FILTER = 8;

// So that the balance report doesn't show data from the beginning, set the number of days back from the current day.
export const DAYS_BACK_FOR_BALANCE_FILTER = 8;

export const PDF_BASE_PATH = '/admin/report/inspection/pdf';
export const PDF_PATH = '/admin/report/inspection/pdf/:reportId';
export const DEFAULT_PATH = '/admin/report/balance';
export const SETTINGS_PATH = '/admin/settings';
