import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import DisplayError from './DisplayError';

const styles = theme => ({
   progressStyle: {
      position: 'relative',
      top: '50%',
      left: '50%',
   },
});

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 */
class DataLoadingAndErrors extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // Styles for the component.
      isLoading: PropTypes.bool,                // Indicates if the data is still loading.
      open: PropTypes.bool,                     // Indicates if the Error snackbar should be shown to the user.
      enableRefresh: PropTypes.bool,            // Indicates if the Refresh action should be shown / enabled.
      onClose: PropTypes.func,                  // Callback when the snackbar is closed.
      errorId: PropTypes.string,                // Intl ID for the error message.
      message: PropTypes.string,                // Text for the error message.
      values: PropTypes.object,                 // Value object for the error message.
   };

   static defaultProps = {
      enableRefresh: true,
      values: {message: 'N/A'},
      errorId: 'fetch.error',
      data: {},
   };

   state = {
   };

   render() {
      const {classes, onClose, errorId, error, isLoading, enableRefresh, showOnLoad, data, values, children} = this.props;

      return (
         <Fragment>
            <DisplayError error={error} onClose={onClose} errorId={errorId} values={values || error} enableRefresh={enableRefresh}/>
            {isLoading && <CircularProgress className={classes.progressStyle}/>}
            {(!isLoading || showOnLoad) && children(data)}
         </Fragment>
      );
   }
}

export default withStyles(styles)(DataLoadingAndErrors);
