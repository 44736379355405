import compareAsc from 'date-fns/compareAsc';
import {compact, isArray, isNil, forOwn} from 'lodash';
import isEqual from 'lodash/isEqual';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({ id, defaultMessage: newDefaultMessage }, values) : newDefaultMessage;
   } else {
      return '';
   }
}

/**
 * Converts any boolean type string to a boolean value.
 * @param string The string to convert.
 * @return {*} The boolean value or the original string if the string wasn't a boolean type string.
 */
export const stringToBoolean = (string) => {
   if (string) {
      switch (string.toLocaleLowerCase().trim()) {
         case 'true':
         case 'yes':
            return true;
         case 'false':
         case 'no':
            return false;
         default:
            return string;
      }
   } else {
      return string;
   }
};

/**
 * b64 encoding for a string containing unicode characters.
 * @param str The string to encode.
 * @return {string} The encoded string.
 */
export function b64EncodeUnicode (str) {
   // first we use encodeURIComponent to get percent-encoded UTF-8,
   // then we convert the percent encodings into raw bytes which
   // can be fed into btoa.
   return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
         return String.fromCharCode('0x' + p1);
      }));
}

/**
 * Sorts the dates using date-fns.
 * @param a
 * @param b
 * @return {number}
 */
export const sortDate = (a, b) => {

   if (a === b) {
      return 0;
   }
   if (a === undefined || a === 'Invalid date' || b === undefined || b === 'Invalid date') {
      return (a === undefined || a === 'Invalid date') ? -1 : 1;
   }
   return compareAsc(a, b);
};

/**
 * Sorts the dates firestore timestamp objects.
 * @param a
 * @param b
 * @return {number}
 */
export const sortTimestamp = (a, b) => {

   if (isEqual(a, b)) {
      return 0;
   }
   if (a === undefined || b === undefined ) {
      return a === undefined ? -1 : 1;
   }
   return ((a.seconds > b.seconds) || ((a.seconds === b.seconds) && (a.nanoseconds > b.nanoseconds))) ? 1 : -1


};

export function removeOne(array, index) {
   if (array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
}

export const emptyFunction = () => {};

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return !isNil(item) && item !== '' && (!isArray(item) || item.length > 0);
}

/**
 * If items both have values and they are equal or if they both don'have values, they are equivalent.
 * @param item1 First item to check
 * @param item2 Second item to check.
 * @return {boolean} True if the two items have the same value or both don't have a value.
 */
export function isEquivalent(item1, item2) {
   return (!hasValue(item1) && !hasValue(item2)) || isEqual(item1, item2);
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperty The property of the changed item to compare.
 * @param originalProperty The property of the original item to compare.
 * @param isCompactArray True to compact the array type properties.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getChangedProperty(changedItem, originalItem, changedProperty, originalProperty,
   isCompactArray = false) {
   originalProperty = originalProperty || changedProperty;
   const changedItemProperty = isCompactArray && isArray(changedItem[changedProperty]) ?
      compact(changedItem[changedProperty]) : changedItem[changedProperty];
   return !isEquivalent(changedItemProperty, originalItem[originalProperty]) &&
      {[originalProperty]: changedItemProperty};
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed. The changed
 * property is passed directly instead of as part of an object for getChangedProperty.
 *
 * @param changedProperty The possibly changed property to compare.
 * @param originalItem The original object to compare properties.
 * @param originalProperty The property of the original item to compare.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getCustomChanged(changedProperty, originalItem, originalProperty) {
   return !isEquivalent(changedProperty, originalItem[originalProperty]) && {[originalProperty]: changedProperty};
}

/**
 * Get an object with all the properties set to the changed properties. Only changed properties will be in the returned
 * object.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperties The list of properties of the changed item to compare.
 * @param convertCallback The function to convert from the view value to the save object type.
 * @param mapChangedPropertiesToOriginal The mapping from changed property names to original properties. For example:
 *    {
 *       changedItemPropertyName: 'originalProperyName',
 *       changedItemPropertyName2: 'originalProperyName2',
 *    }
 */
export function getChangedObject(changedItem, originalItem, changedProperties, convertCallback, mapChangedPropertiesToOriginal = {}) {
   const changed = {};
   for (const property of changedProperties) {
      const originalProperty = mapChangedPropertiesToOriginal[property] || property;
      const changedField = getChangedProperty(changedItem, originalItem, property, originalProperty);
      if (changedField) {
         changed[originalProperty] = convertCallback ? convertCallback(changedItem[property]) : changedItem[property];
      }
   }
   return changed;
}

export function hasOwnToObject (object) {
   const result = {};

   forOwn(object, function(value, key) {
      if (key !== 'undefined') {
         result[key] = value;
      }
   });

   return result;
}