import { Typography } from '@material-ui/core';
import React from 'react';
import { formatMessage } from '../utils/Utils';
import PropTypes from 'prop-types';

/**
 * The Typography component that supports intl. The default value is the child element. The id is the lookup value for
 * the locale file.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 */
const FHGTypography = (props, context) => {
   const { id, children, values, hasLineBreaks = false, ...otherProps } = props;
   const message = id ? formatMessage(context.intl, id, children, values) : children;
   return (
      <Typography id={id} {...otherProps} >{hasLineBreaks ? linesToParagraphs(message) : message}</Typography>
   );
};

function linesToParagraphs(...nodes) {
   return nodes
      .map(node => typeof node === 'string' ?
         node.split('\n').map(text => <p key={text}>{text}</p>) : node)
      .reduce((nodes, node) => nodes.concat(node), []);
}

FHGTypography.contextTypes = {
   intl: PropTypes.object
};

FHGTypography.propTypes = {
   id: PropTypes.string,                  // Key to message in the localization file.
   values: PropTypes.object,              // Values to use to fill parameters in the localized message.
   hasLineBreaks: PropTypes.bool,         // Indicates if the string could have line breaks.
   ...Typography.propTypes,               // Supports all the properties from Typography.
};

FHGTypography.defaultProps = {
   variant: 'inherit',
};
FHGTypography.displayName = 'FHGTypography';

export default FHGTypography;
