import {isEmpty} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {hasOwnToObject} from '../fhg/utils/Utils';
import {forOwn, isArray} from 'lodash';

/**
 * The generic filter component to determine which data to fetch.
 *
 * NOTE: This component must be overridden to be used.
 */
export default class Filter extends React.PureComponent {
   static propTypes = {
      storeFilters: PropTypes.bool, //Indicates the filters that should be stored when the filters are submitted.
      storeName: PropTypes.string,  // The name to use when storing filters.
   };

   static defaultProps = {
      storeFilters: true,
   };

   constructor(props, context) {
      super(props, context);

      if (props.storeFilters) {
         this.state = this.restoreStoredFilters(props.storeName);
      }
      this.handleFilter = this.handleFilter.bind(this);
   }

   /**
    * Restore the stored filters.
    *
    * NOTE: The handleFilter will be called to trigger the filtering if any filters exist.
    *
    * @param name
    * @return {{}|any}
    */
   restoreStoredFilters = name => {
      const filters = this.getStoredFilters(name);
      if (filters) {
         setTimeout(() => {
            this.handleFilter();
         });
         return filters;
      }
      return undefined;
   };

   getSessionFilter = () => {
      const filtersString = sessionStorage.getItem('filter' + window.btoa(this.props.storeName));
      return filtersString && JSON.parse(window.atob(filtersString));
   };

   saveSessionFilter = filters => {
      sessionStorage.setItem('filter' + window.btoa(this.props.storeName), window.btoa(JSON.stringify(filters)));
   };

   /**
    * Gets the filters that were stored in localStorage.
    *
    * @param name The name of the filters.
    * @return {{}|any} The filters that were stored or undefined if there aren't any.
    */
   getStoredFilters = name => {
      const filtersString = localStorage.getItem('filter' + window.btoa(name));
      const result = filtersString && JSON.parse(window.atob(filtersString));
      forOwn(result, (value, key) => {
         if (isArray(value)) {
            for (const [index, item] of value.entries()) {
               if (typeof item === 'string') {
                  const date = new Date(item);
                  if (!isNaN(date.getTime())) {
                     value[index] = date;
                  }
               }
            }
         } else if (typeof value === 'string') {
            const date = new Date(value);
            if (!isNaN(date.getTime())) {
               value = date;
            }
         }
      });
      return result;
   };

   /**
    * Get the filters from the state. The filtering is based on Googles Firestore query.where calls.
    *
    * NOTE: This method must be overridden.
    *
    * @return {Array} The array of filters.
    */
   getFilters() {
      console.log('getFilters needs to be overridden');
      return {};
   };

   /**
    * Indicates if the filters are valid and can be used to filter the table.
    *
    * NOTE: (OPTIONAL) Override to validate filters.
    *
    * @return {boolean} True if the filters are all valid.
    */
   areFiltersValid = () => {
      return true;
   };

   /**
    * Handle changes to filter.
    *
    * @param value The new value of the filter.
    * @param name The name of the filter component.
    */
   handleChange = (value, name) => {
      if (name !== undefined) {
         this.setState({[name]: value});
      }
   };

   /**
    * Handle date changes to filter.
    *
    * @param name The name of the filter.
    * @param date The date from the component that changed.
    */
   handleDateChange = name => (date) => {
      this.setState({[name]: date});
   };

   /**
    * Sets the filter value from the state given the key. Extracts the "value" properties from filters set by the
    * ReactSelect component.
    *
    * @param filters The filters object building all the filters to be used.
    * @param key The property key from the state for the filter.
    * @param isInt Indicates if the value should be converted to an Int.
    */
   setFilterValue = (filters, key, isInt = false) => {
      const filter = this.state[key];
      const filterValueCallback = this.getFilterValue(isInt);

      if (!isEmpty(filter)) {
         if (Array.isArray(filter)) {
            filters[key] = filter.map(filterValueCallback);
         } else {
            filters[key] = filterValueCallback(filter);
         }
      }
   };

   /**
    * Gets the filter value from the "value" property and converts it to Int if needed.
    *
    * @param isInt Indicates if the value is an Int.
    * @return {function(*): number}  The extracted value property.
    */
   getFilterValue = isInt => filter => {
      return isInt ? Number(filter.value) : filter.value;
   };

   /**
    * Calls onFilter to fetch new data.
    *
    * @param event The event of the button used to save the filters.
    */
   handleFilter (event) {
      if (event) {
         event.preventDefault();
      }

      if (this.areFiltersValid()) {
         if (this.props.storeFilters && event !== undefined) {
            const justFilters = hasOwnToObject(this.state);
            localStorage.setItem('filter' + window.btoa(this.props.storeName), window.btoa(JSON.stringify(justFilters)));
         }
         this.props.onFilter && this.props.onFilter(this.getFilters());
      }
   }

   render() {
      throw Error('Filter cannot be rendered');
      // eslint-disable-next-line
      return null;
   }
}
