import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
   const { inputRef, onChange, ...other } = props;

   return (
      <NumberFormat
         {...other}
         getInputRef={inputRef}
         onValueChange={values => {
            onChange({
               target: {
                  name: props.name,
                  value: values.value,
               },
            });
         }}
         thousandSeparator
      />
   );
}

NumberFormatCustom.propTypes = {
   inputRef: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
};

export default NumberFormatCustom;