import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import UnitFilter from '../components/UnitFilter';
import UnitTable from '../components/UnitTable';
import Link from 'react-router-dom/Link';

const styles = theme => ({
   root: {
      height: '100vh',
      overflow: 'hidden',
   },
   outerGridStyle: {
      paddingBottom: 10,
      flex: '1 1 100%',
      overflow: 'hidden',
   },
   innerGridStyle: {
      paddingTop: theme.spacing(1),
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
   },
   tableFrameStyle: {
      margin: 12,
      overflow: 'auto',
      display: 'flex',
      maxHeight: 'calc(100% - 56px)',
   },
   addStyle: {
      width: 'fit-content',
      marginLeft: theme.spacing(2),
   }
});

class UnitList extends React.Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,
   };

   state = {
      filters: [],
   };

   handleFilter = (filters) => {
      this.setState({filters});
   };

   render() {
      const {classes, search, tableRef, isSaving} = this.props;

      return (
         <Grid container className={classes.outerGridStyle}>
            <Grid item xs={12} sm={12} md={12} className={classes.innerGridStyle}>
               <UnitFilter onFilter={this.handleFilter} storeName={'unitFilter'}/>
               <Button className={classes.addStyle} component={Link} to={{pathname: '/admin/edit/unit/0', state: this.state.filters}} color={'secondary'}
                       variant='text' disabled={isSaving}>
                  <AddIcon color={'inherit'}/>
                  <Typography color={'secondary'}>Add Unit</Typography>
               </Button>
               <UnitTable tableRef={tableRef} search={search} filters={this.state.filters}/>
            </Grid>
         </Grid>
      );
   }
}

export default withStyles(styles)(UnitList);
