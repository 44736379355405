import React from 'react';
import DriverVehicleInspectionFilter from '../components/DriverVehicleInspectionFilter';
import DriverVehicleInspectionPdfPage from '../components/DriverVehicleInspectionPdfPage';
import DriverVehicleInspectionTable from '../components/DriverVehicleInspectionTable';

/**
 * Component to show the Driver Vehicle Inspection Report.
 */
class DriverVehicleInspectionReport extends React.Component {

   state = {
      filters: undefined,
   };

   handleFilter = (filters) => {
      this.setState({filters});
   };

   render() {
      const {tableRef, search} = this.props;

      return (
         <React.Fragment>
            <DriverVehicleInspectionFilter key="vehicleInspectionFilter" onFilter={this.handleFilter} storeName={'vehicleInspectionFilter'}/>
            <DriverVehicleInspectionTable tableRef={tableRef} search={search} filters={this.state.filters}/>
         </React.Fragment>
      );
   }
}

export default DriverVehicleInspectionReport;
