import {createStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React, {PureComponent} from 'react';
import Query from 'react-apollo/Query';
import DeleteMutation from '../fhg/components/data/DeleteMutation';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import DisplayError from '../fhg/components/DisplayError';
import ReactSelect from '../fhg/components/ReactSelect';

export const GET_USERS = gql`
   query getUsers
   {
      users:user_All {
         id
         value:id
         firstName
         lastName
      }
   }
`;

const DELETE_USER = gql`
   mutation deleteUser($id: Int!)
   {
      user_Delete(userId: $id)
   }
`;

const styles = (theme) =>
   createStyles({
      inlineStyle: {
         display: 'inline-block',
      },
      actionStyle: {
         position: 'sticky',
         bottom: 0,
         height: 60,
         margin: 0,
         padding: `0 ${theme.spacing(4.5)}px`,
         // backgroundColor: theme.palette.primary.light,
         // borderTop: `1px solid ${theme.palette.primary.light}`,
         zIndex: 0,
      },
   });

class RemoveUserAccount extends PureComponent {

   state = {
      open: true,
      user: undefined,
   };

   handleUpdate = () => {
      const {onClose} = this.props;
      onClose && onClose();
   };

   getSelectValues = (object, path, defaultValue, getLabel) => {
      const array = get(object, path, defaultValue);
      const convertedArray = [];
      if (array && array.length > 0) {
         for (const item of array) {
            convertedArray.push({id: item.id, label: (getLabel && getLabel(item)) || item, value: item.value || item});
         }
      }
      return convertedArray;
   };

   getLabel = (item) => {
      return `${item.firstName} ${item.lastName}`;
   };

   /**
    * Handle changes to filter.
    *
    * @param value The new value of the filter.
    * @param name The name of the filter component.
    */
   handleChange = (value, name) => {
      this.setState({[name]: value, isChanged: true, isUniqueKey: true});
   };

   handleSubmit = () => {
      this.setState({deleteUser: this.state.user})
   };

   handleCancel = () => {
      this.setState({deleteUser: undefined});
   };

   render() {
      const {classes, onClose} = this.props;
      const {open, deleteUser, isEnabled = true, errorId,} = this.state;
      return (
         <Query query={GET_USERS} fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <DataLoadingAndErrors isLoading={loading} error={error} data={data}
                                     errorId={'user.loading.error'} values={{message: error && error.message}}>
                  {data => (
                     <DeleteMutation mutation={DELETE_USER} deletedItemId={deleteUser && deleteUser.id}
                                     confirmValues={deleteUser}
                                     confirmKey={'user.removeAccount.confirm'}
                                     onUpdate={this.handleUpdate} onCancel={this.handleCancel}
                     >
                        <ModalDialog open={open} title={'Remove User Account'} onClose={onClose} maxWidth={'sm'}
                                     isForm={true} onSubmit={this.handleSubmit} submitKey={'user.removeAccount.button'}
                                     classes={{actionStyle:classes.actionStyle}}
                        >
                           <DisplayError error={error} errorId={errorId} enableRefresh={false}/>
                           <Grid container>
                              <ReactSelect
                                 name={'user'}
                                 label={'Users'}
                                 value={this.state.user}
                                 onChange={this.handleChange}
                                 required
                                 // disabled={isSaving}
                                 isMulti={false}
                                 fullWidth
                                 options={this.getSelectValues(data, 'users', [], this.getLabel)}
                              />
                           </Grid>
                        </ModalDialog>
                     </DeleteMutation>
                  )}
               </DataLoadingAndErrors>
            )}
         </Query>
      );
   }
}

export default withStyles(styles)(RemoveUserAccount);
