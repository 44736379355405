import {Document, Font, PDFViewer} from '@react-pdf/renderer';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React from 'react';
import Query from 'react-apollo/Query';
import {useParams} from 'react-router-dom';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import DriverVehicleInspectionPdfPage from './DriverVehicleInspectionPdfPage';

Font.register({
   family: 'montserrat', fonts: [
      {src: '/fonts/montserrat-regular-webfont.ttf'}, // font-style: normal, font-weight: normal
      {src: '/fonts/montserrat-bold-webfont.ttf', fontWeight: 700},
   ]
});

const driverVehicleInspectionReportById = gql`
   query getDriverVehicleInspectionReportById($id: Int!)
   {
      driverVehicleInspectionReport:dvir_ById(dvirId: $id) {
         id
         carrierId
         identityNumber
         driverId
         date:createdDateTime
         defectsCorrected
         conditionSatisfactory
         correctionNotRequired
         trailerNumber
         driverSigPrimary
         driverSigSecondary
         mechanicSig
         vehicleIssues {
            name
            remarks
         }
         trailerIssues {
            name
            remarks
         }
      }
   }
`;

const DriverVehicleInspectionReportPdf = React.memo(({onRender}) => {
   const {reportId} = useParams();

   return (
      <Query query={driverVehicleInspectionReportById} variables={{id: Number(reportId)}} fetchPolicy={'cache-and-network'}>
         {({loading, error, data}) => (
            <DataLoadingAndErrors isLoading={loading} error={error} data={data}
                                  errorId={'fetch.error'} values={{message: error && error.message}}>
               {data => (
                  <PDFViewer style={{height: '100%', width: '100%'}}>
                     <Document
                        onRender={onRender}
                        title={`Driver's Vehicle Inspection Report - ${get(data,
                           'driverVehicleInspectionReport.date') || 'N/A'} ${get(data,
                           'driverVehicleInspectionReport.identityNumber')}`}
                        author={get(data, 'driverVehicleInspectionReport.driverSigPrimary')}
                     >
                        <DriverVehicleInspectionPdfPage report={data && data.driverVehicleInspectionReport}/>
                     </Document>
                  </PDFViewer>
               )}
            </DataLoadingAndErrors>
         )}
      </Query>
   );
}, () => true);

export default DriverVehicleInspectionReportPdf;