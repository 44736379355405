import {Grid} from '@material-ui/core';
import TextField from '@material-ui/core//TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/es/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Query from 'react-apollo/Query';
import {intlShape, injectIntl} from 'react-intl';
import {getLocations} from '../components/LocationTable';
import {HEADER_HEIGHT} from '../Constants';
import Mutation from '../fhg/components/data/Mutation';
import DataLoadingAndErrors from '../fhg/components/DataLoadingAndErrors';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import {Prompt} from 'react-router-dom';
import {formatMessage} from '../fhg/utils/Utils';

const MAX_WIDTH = 500;

const styles = theme => ({
   buttonStyle: {
      margin: theme.spacing(1, 1, 1, 0),
   },
   unsizingFlex: {
      flex: '0 0 auto',
   },
   resizingContainer: {
      display: 'flex',
      flex: '1 1',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
      padding: `0 !important`,
   },
   outerContainer: {
      maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
      overflow: 'auto',
      flex: '1 1',
   },
   innerContainer: {
      maxHeight: '100%',
      maxWidth: MAX_WIDTH,
   },
   titleStyle: {
      marginBottom: theme.spacing(3),
   },
   inlineStyle: {
      display: 'inline-block',
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
   largeWidth: {
      maxWidth: MAX_WIDTH,
      minWidth: MAX_WIDTH / 2,
   },
   mediumWidth: {
      maxWidth: MAX_WIDTH * .75,
      minWidth: (MAX_WIDTH * .75) / 2,
   },
   smallWidth: {
      maxWidth: MAX_WIDTH / 2,
      minWidth: MAX_WIDTH / 4,
   },
   formStyle: {
      height: '100%',
      overflow: 'hidden',
   },
   formContainer: {
      margin: theme.spacing(2),
      maxHeight: `calc(100% - ${HEADER_HEIGHT}px)`,
      maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
   },
   editStyle: {
      flex: '0 0 auto',
   },
   spinnerStyle: {
      color: 'white',
      marginLeft: theme.spacing(0.5),
   },
});

const LOCATION_FRAGMENT = gql`
   fragment locationInfo on Location {
      id
      name
      locationTypeId
      locationType {
         id
         value:id
         label:name
      }
      state {
         id
         value:id
         label:name
      }
   }
`;

const locations = gql`
   query getLocation($locationId: Int!)
   {
      locationTypes: locationType_All {
         id
         value:id
         label:name
      }
      states:state_All {
         id
         value:id
         label:name
      }

      location:location_ById(locationId: $locationId) {
         ...locationInfo
      }
   }
   ${LOCATION_FRAGMENT}
`;

const UPDATE_LOCATION = gql`
   mutation UpdateLocation($id: Int!, $locationTypeId: Int, $name: String, $stateId: Int) {
      locationUpdate:location_Update(locationId: $id, location: {locationTypeId: $locationTypeId, stateId: $stateId, name: $name}) {
         ...locationInfo
      }
   }
   ${LOCATION_FRAGMENT}
`;

const CREATE_LOCATION = gql`
   mutation CreateLocation($locationTypeId: Int, $name: String!, $stateId: Int) {
      locationUpdate:location_Create(location: {name: $name, stateId: $stateId, locationTypeId: $locationTypeId}) {
         ...locationInfo
      }
   }
   ${LOCATION_FRAGMENT}
`;

/**
 * The location edit.
 */
class LocationEdit extends Component {

   static propTypes = {
      intl: intlShape,                       // Localization messages.
      classes: PropTypes.object.isRequired,  // The style for the component.
   };

   constructor(props, context) {
      // noinspection JSCheckFunctionSignatures
      super(props, context);

      this.state = {
         isChanged: false,
         errorId: undefined,
         name: undefined,
         locationId: undefined,
         stateId: undefined
      };
      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape') {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push('/admin/report/location');
         }
      }
   };

   /**
    * Handle changes to filter.
    *
    * @param value The new value of the filter.
    * @param name The name of the filter component.
    */
   handleChange = (value, name) => {
      this.setState({[name]: value, isChanged: true});
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   onChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
   };

   /**
    * Callback when the user submits the changes.
    */
   onSubmit = locationUpdate => e => {
      const {name, stateId, locationTypeId} = this.state;
      e.preventDefault();
      this.setState({isSaving: true, mutationError: false});
      locationUpdate({
         variables: {
            id: Number(this.props.match.params.id),
            name,
            stateId: stateId && stateId.value,
            locationTypeId: locationTypeId && locationTypeId.value,
         }
      }).then(result => {
         this.setState({isSaving: false, isChanged: false}, () => {
            this.props.history.replace('/admin/edit/location/' + result.data.locationUpdate.id,
               this.props.location.state); //identityNumber
            this.props.history.push(`/admin/report/location?select=${result.data.locationUpdate.id}`);
         });
      }).catch(mutationError => {
         console.log(mutationError);
         this.setState({mutationError});
      }).finally(() => {
         this.setState({isSaving: false});
      });
   };

   render() {
      const {intl, classes, location} = this.props;
      const {
         isChanged, identityNumber, isSaving, mutationError
      } = this.state;

      const isAdd = this.props.match.params.id === '0';
      const variables = {locationId: Number(this.props.match.params.id)};

      return (
         <Query query={locations} variables={variables} fetchPolicy={'cache-and-network'}>
            {({loading, error, data}) => (
               <DataLoadingAndErrors isLoading={loading} error={error || mutationError} data={data}
                                     errorId={'location.loading.error'} values={{action: !!mutationError ? 'saved' : 'loaded', message: error && error.message}}>
                  {data => {
                     return (
                     <Mutation isAdd={isAdd} addMutation={CREATE_LOCATION}
                               updateMutation={UPDATE_LOCATION} key={this.props.match.params.id}
                               updateCache={[
                                  {query: getLocations, key: 'locations', variables: location.state},
                                  // {query: getLocations, key: 'locations'},
                                  {query: locations, key: 'location', variables},
                               ]}
                               mutationKey={'locationUpdate'}
                     >
                        {locationUpdate => (
                           <form onSubmit={this.onSubmit(locationUpdate)} className={classes.formStyle}>
                              {(!loading || isAdd) && (
                                 <Grid container direction={'column'} spacing={2} wrap={'nowrap'}
                                       className={classes.formContainer}>
                                    <Prompt when={isChanged}
                                            message={() => formatMessage(intl, 'leavePage', 'Discard changes?')}/>

                                    <Grid item container className={classes.resizingContainer} spacing={0}
                                          direction={'column'} wrap={'nowrap'}>
                                       <Grid item className={classes.unsizingFlex} style={{width: '100%'}}>
                                          <Typography className={classes.titleStyle} variant={'h5'}
                                                      id={'location.edit.title'}/>
                                       </Grid>
                                       <Grid container className={classes.outerContainer} direction={'column'} xs={12}>
                                          <Grid container className={classes.innerContainer} direction={'column'}
                                                xs={12} spacing={2} wrap={'nowrap'}>
                                             <Grid item className={classes.editStyle} xs={12}>
                                                <TextField
                                                   name='name'
                                                   className={classes.largeWidth}
                                                   label={<Typography className={classes.inlineStyle} variant='inherit'
                                                                      id={'location.name.label'}
                                                                      nowrap/>}
                                                   fullWidth
                                                   autoFocus
                                                   required
                                                   defaultValue={get(data, 'location.name')}
                                                   value={identityNumber}
                                                   onChange={this.onChange}
                                                />
                                             </Grid>
                                             <Grid item className={classes.editStyle} xs={12}>
                                                <ReactSelect
                                                   name={'stateId'}
                                                   label={<Typography className={classes.inlineStyle} variant='inherit'
                                                                      id={'location.state.label'}
                                                                      nowrap/>}
                                                   defaultValue={get(data, 'location.state', [])}
                                                   value={this.state.stateId}
                                                   onChange={this.handleChange}
                                                   isMulti={false}
                                                   fullWidth
                                                   required
                                                   options={data && data.states}
                                                   menuPosition={'fixed'}
                                                />
                                             </Grid>
                                             <Grid item className={classes.editStyle} xs={12}>
                                                <ReactSelect
                                                   name={'locationTypeId'}
                                                   label={<Typography className={classes.inlineStyle} variant='inherit'
                                                                      id={'location.type.label'}
                                                                      nowrap/>}
                                                   defaultValue={get(data, 'location.locationType', [])}
                                                   value={this.state.locationTypeId}
                                                   onChange={this.handleChange}
                                                   required
                                                   isMulti={false}
                                                   fullWidth
                                                   options={data && data.locationTypes}
                                                />
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                    <Grid item className={'no-print'} style={{padding: 0}}>
                                       <Button variant='contained' color='secondary' className={`no-print ${classes.buttonStyle}`}
                                               onClick={this.handleFilter} type={'submit'}>
                                          <SaveIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                                          <Typography color='inherit' id={'save.label'}>Save</Typography>
                                          {isSaving &&
                                          <CircularProgress className={classes.spinnerStyle} size={15}
                                                            thickness={2.5}/>}
                                       </Button>
                                    </Grid>
                                 </Grid>
                              )}
                           </form>
                        )}
                     </Mutation>
                  )
                  }}
               </DataLoadingAndErrors>
            )}
         </Query>
      );
   }
}

export default injectIntl(withStyles(styles)(LocationEdit));
