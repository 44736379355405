import {Button, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import gql from 'graphql-tag';
import {isDate} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Query from 'react-apollo/Query';
import 'react-table/react-table.css';
import {DAYS_BACK_FOR_BALANCE_FILTER} from '../Constants';
import DateRangePicker from '../fhg/components/DateRangePicker';
import DisplayError from '../fhg/components/DisplayError';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import {sortDate} from '../fhg/utils/Utils';
import Filter from './Filter';

const VEHICLE_SAFE = 0;
const VEHICLE_NOT_SAFE = 1;
const DEFECTS_FOUND_REPAIRED = 2;

const conditionOptions = [
   {label: 'Vehicle is Safe', value: VEHICLE_SAFE},
   {label: 'Defects found and vehicle may not be safe', value: VEHICLE_NOT_SAFE},
   {label: 'Defects found and repaired', value: DEFECTS_FOUND_REPAIRED},
];

const styles = theme => ({
   root: {
      margin: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      flex: '0 0 auto'
   },
   titleStyle: {
      marginRight: theme.spacing(2),
   },
   noWrap: {
      whiteSpace: 'nowrap',
   },
   buttonStyle: {
      marginTop: theme.spacing(2),
      alignSelf: 'flex-start',
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
});

const GetOptionValues = gql`
   {
      users:user_All {
         id
         value:id
         firstName
         lastName
      }
   }
`;

/**
 * The filter component to determine which data to fetch.
 */
class DriverVehicleInspectionFilter extends Filter {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      onFilter: PropTypes.func,                 // Called when the filter changes and new data should be fetched.
   };

   constructor(props, context) {
      super(props, context);

      if (this.state === undefined) {
         this.state = {
            unitId: undefined,
            eventId: undefined,
         };
      }
      const dateRange = this.getSessionFilter();
      if (!dateRange) {
         const today = new Date();
         this.state.dateRange = [today, subDays(today, DAYS_BACK_FOR_BALANCE_FILTER)];
      } else {
         this.state.dateRange = [];
         for (const dateElement of dateRange) {
            //Single date range has null value in range. Don't add those in.
            if (dateElement) {
               const date = new Date(dateElement);
               if (!isNaN(date.getTime())) {
                  this.state.dateRange.push(date);
               }
            }
         }
         //If there was a null in the date range, put in the other date for the end date.
         if (this.state.dateRange.length === 1) {
            this.state.dateRange.push(this.state.dateRange[0]);
         }
      }
   }

   /**
    * Get the filters from the state. The filtering is based on Googles Firestore query.where calls.
    *
    * @return {Array} The array of filters.
    */
   getFilters = () => {
      const {dateRange} = this.state;
      const filters = {};

      switch (get(this.state, 'condition.value')) {
         case VEHICLE_SAFE:
            filters.safeOnly = true;
            break;
         case VEHICLE_NOT_SAFE:
            filters.safeOnly = false;
            filters.correctionNotRequired = false;
            filters.defectsCorrected = false;
            filters.conditionSatisfactory = false;
            break;
         case DEFECTS_FOUND_REPAIRED:
            filters.defectsCorrected = true;
            break;
         default:
            break;
      }
      const identityNumber = get(this.state, 'identityNumber');
      filters.identityNumber = identityNumber ? identityNumber + "" : undefined;
      filters.driverId = get(this.state, 'userId.id');
      if (dateRange && dateRange.length >= 1 && dateRange[0]) {
         const sortedDateRange = dateRange.sort(sortDate);
         filters.startDate = isDate(sortedDateRange[0]) ? format(sortedDateRange[0], 'yyyy-MM-dd') : sortedDateRange[0];
         filters.endDate = (sortedDateRange[1] && endOfDay(sortedDateRange[1])) || endOfDay(sortedDateRange[0]);
         if (filters.endDate) {
            filters.endDate = format(filters.endDate, 'yyyy-MM-dd');
         }
      }
      return filters;
   };

   /**
    * Handle date changes to filter.
    *
    * @param name The name of the filter.
    * @param date The date from the component that changed.
    */
   handleDateChange = name => (date) => {
      this.setState({[name]: date}, () => {
         this.saveSessionFilter(this.state.dateRange);
      });
   };

   getLabel = (item) => {
      return `${item.firstName} ${item.lastName}`;
   };

   getSelectValues = (object, path, defaultValue, getLabel) => {
      const array = get(object, path, defaultValue);
      const convertedArray = [];
      if (array && array.length > 0) {
         for (const item of array) {
            convertedArray.push({id: item.id, label: (getLabel && getLabel(item)) || item, value: item.value || item});
         }
      }
      return convertedArray;
   };

   handleTextChange = (event) => {
      this.handleChange(event.target.value, event.target.name)
   }

   render() {
      let {classes} = this.props;
      const {dateRange} = this.state;

      return (
         <Query query={GetOptionValues} fetchPolicy={'cache-and-network'}>
            {({error, data = {}}) => (
               <Fragment>
                  <DisplayError error={error} errorId={'filter.fetch.error'}/>
                  <form id="balanceId" onSubmit={this.handleFilter}>
                     <Grid container item className={classes.root} direction={'column'}>
                        <Typography id={'filter.title'} className={classes.titleStyle} variant='h6' color='inherit'
                                    noWrap/>
                        <Grid container direction={'row'} spacing={2}>
                           <Grid container item xs={12} sm={6} md={3} alignItems={'flex-end'}>
                              <TextField
                                 name='identityNumber'
                                 className={classes.largeWidth}
                                 label={<Typography className={classes.inlineStyle} variant='inherit'
                                                    id={'vehicleInspection.identityNumber.label'}
                                                    nowrap/>}
                                 fullWidth
                                 autoFocus
                                 value={this.state.identityNumber}
                                 onChange={this.handleTextChange}
                              />
                           </Grid>
                           <Grid container item xs={12} sm={6} md={4}>
                              <ReactSelect
                                 label={<Typography className={classes.noWrap} id={'vehicleInspection.condition.label'}/>}
                                 options={conditionOptions}
                                 name={'condition'}
                                 value={this.state.condition}
                                 onChange={this.handleChange}
                                 fullWidth
                                 isClearable
                                 isMulti={false}
                                 // required
                              />
                           </Grid>
                           <Grid container item xs={12} sm={6} md={3} alignItems={'flex-end'}>
                              <ReactSelect
                                 name={'userId'}
                                 label={<Typography className={classes.noWrap} id={'vehicleInspection.driver.label'}/>}
                                 value={this.state.userId}
                                 valueKey={'id'}
                                 onChange={this.handleChange}
                                 // disabled={isSaving}
                                 isMulti={false}
                                 fullWidth
                                 isClearable
                                 options={this.getSelectValues(data, 'users', [], this.getLabel)}
                              />
                           </Grid>
                           <Grid container item xs={12} sm={6} md={2} alignItems={'flex-end'} style={{marginBottom: 8}}>
                              <DateRangePicker
                                 name={'dateRange'}
                                 label={<Typography className={classes.noWrap} id={'filter.dateRange.label'}/>}
                                 value={dateRange}
                                 style={{top: 8}}
                                 allowKeyboardControl
                                 disableFuture
                                 autoOk={false}
                                 clearable
                                 fullWidth
                                 onChange={this.handleDateChange('dateRange')}
                              />
                           </Grid>
                        </Grid>
                        <Button variant='contained' color='secondary' className={classes.buttonStyle} type='submit'>
                           <FilterIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                           Filter
                        </Button>
                     </Grid>
                  </form>
               </Fragment>
            )}
         </Query>
      );
   }
}

export default withStyles(styles)(DriverVehicleInspectionFilter);
