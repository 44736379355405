import React, { Component } from 'react';

/**
 * Add the params on the URL to the wrapped component.
 * @param WrappedComponent
 * @return {*} The Wrapped component with the properties set from the URL.
 */
export default function addUrlParams(WrappedComponent) {

   return class extends Component {
      render() {
         const { match } = this.props;

         if (match) {
            return <WrappedComponent {...match.params} {...this.props} />;
         } else {
            return <WrappedComponent {...this.props} />;
         }
      }
   };
}
