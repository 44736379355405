import React from 'react';
import {ENDPOINT} from './Constants';
import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { createHttpLink } from 'apollo-link-http';

const uri = process.env.NODE_ENV === 'production' ? '/api/graphql/' : ENDPOINT;

/**
 * The HOC for handling localization by loading messages and setting up the provider.
 * @return {function(*): function(*): *}
 */
const withGraphql = (getAccessToken)=> Component => {
   // const httpLink = createHttpLink({uri});
   //
   // const authLink = setContext(async (_, {headers}) => {
   //    let token;
   //    // get the authentication token from local storage if it exists
   //    if (getAccessToken) {
   //       token = await getAccessToken();
   //    }
   //    // return the headers to the context so httpLink can read them
   //    return {
   //       headers: {
   //          ...headers,
   //          accesstoken: token || '',
   //       }
   //    }
   // });

   const client = new ApolloClient({
      uri,
      request: async operation => {
         let token;
         if (getAccessToken) {
            token = await getAccessToken();
         }
         operation.setContext({
            headers: {
               accesstoken: token || '',
            }
         });
      },
   });

   function WithGraphql(props) {
      return (
         <ApolloProvider client={client}>
            <Component {...props} />
         </ApolloProvider>
      );
   }

   return WithGraphql;
};

export default withGraphql;
