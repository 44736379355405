import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import Typography from '../Typography';

const styles = theme => ({
   titleStyle: {
      // borderBottom: `1px solid ${theme.palette.primary.light}`,
      position: 'sticky',
      backgroundColor: 'white',
      top: 0,
      zIndex: 100,
   },
   contentStyle: {
      padding: theme.spacing(1, 4.5, 5.5),
   },
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
   },
   actionStyle: {
      position: 'sticky',
      bottom: 0,
      height: 60,
      margin: 0,
      padding: `0 ${theme.spacing(4.5)}px`,
      // backgroundColor: theme.palette.primary.light,
      // borderTop: `1px solid ${theme.palette.primary.light}`,
      zIndex: 100,
   },
   // fatButtonStyle: {
   //    padding: '12px 30px !important',
   //    width: 'unset',
   //    height: 'unset',
   // },
});

/**
 * The New User dialog which creates a new user.
 */
class ModalDialog extends PureComponent {
   static propTypes = {
      classes: PropTypes.object.isRequired,// Styles for the component.
      message: PropTypes.string,          // Message to be displayed to the user. Use either message or messageKey but
                                          //    not both.
      messageKey: PropTypes.string,       // Message key of the message to be displayed to the user.
      onSubmit: PropTypes.func,           // Called when the user submits/confirms.
      onClose: PropTypes.func,            // Called when the user closes/cancels.
      open: PropTypes.bool,               // Indicates if the dialog should be open or not.
      title: PropTypes.string,            // Title for the confirmation dialog.
      titleKey: PropTypes.string,         // Localization key for the Title for the confirmation dialog.
      submitLabel: PropTypes.string,      // Label for the submit button.
      submitKey: PropTypes.string,        // Localization key for the submit button label.
      cancelLabel: PropTypes.string,      // Label for the cancel button.
      cancelKey: PropTypes.string,        // Localization key for the cancel button label.
      values: PropTypes.object,           // Localization values for the message.
      isSaving: PropTypes.bool,           // Indicates if the saving progress should be shown.
      isEnabled: PropTypes.bool,           // Indicates if the submit button can be enabled. It won't be enabled if
                                           // isSaving is true.
      submitColorStyle: PropTypes.string, // The class specifying the color of the submit button. Needs color and
                                          //    background color for all states (e.g. primary-color-button).
      maxWidth: PropTypes.string,         // The maximum width of the dialog.
      children: PropTypes.any,            // Optional children components.
      isForm: PropTypes.bool,             // Is the modal containing a form? If not, the enter key is handled.
   };

   static defaultProps = {
      open: true,
      isSaving: false,
      isEnabled: true,
      submitLabel: 'OK',
      submitKey: 'ok.button',
      cancelLabel: 'Cancel',
      cancelKey: 'cancel.button',
      submitColorStyle: 'primary-color-button',
      maxWidth: 'md',
      isForm: false,
   };

   constructor(props, context) {
      super(props, context);

      document.addEventListener('keydown', this.handleKey, {capture: true});
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, {capture: true});
   }

   handleKey = (event) => {
      const {onClose, open} = this.props;
      if (!event.defaultPrevented && open) {

         if (event.key === 'Escape' && onClose) {
            event.preventDefault();
            onClose(event);
         } else if (!this.props.isForm && event.key === 'Enter') {
            this.handleSubmit(event);
         }
      }
   };

   handleClickSubmit = event => {
      if (!event.isDefaultPrevented()) {
         this.handleSubmit(event);
      }
   };

   handleSubmit = event => {
      const {onSubmit} = this.props;
      event.preventDefault();
      onSubmit && onSubmit(event);
   };

   render() {
      const {
         classes, open, onClose, isSaving, isEnabled, titleKey, title, submitKey, submitLabel, messageKey, values,
         message, cancelKey, cancelLabel, submitColorStyle, maxWidth, children, isForm,
      } = this.props;

      return (
         <Dialog open={open} onClose={onClose}  maxWidth={maxWidth} onKeyDown={this.handleKey}>
            <form onSubmit={this.handleSubmit}>
               <DialogTitle disableTypography className={classes.titleStyle}>
                  <Typography variant={'h6'} id={titleKey}>{title}</Typography>
               </DialogTitle>
               <DialogContent className={classes.contentStyle}>
                  {messageKey && (
                     <Typography id={messageKey} values={values}>{message}</Typography>
                  )}
                  {children}
               </DialogContent>
               <DialogActions className={classes.actionStyle} style={{marginTop: 0}}>
                  <Button className={`${classes.fatButtonStyle} minimal-cancel-button`} disabled={isSaving}
                          onClick={onClose}>

                     <Typography color='inherit' id={cancelKey}>{cancelLabel}</Typography>
                  </Button>
                  <Button className={`${classes.fatButtonStyle} button ${submitColorStyle}`} type='submit'
                          disabled={isSaving || !isEnabled} onClick={!isForm && this.handleClickSubmit}>
                     <Typography color='inherit' id={submitKey}>{submitLabel}</Typography>
                     {isSaving &&
                     <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
                  </Button>
               </DialogActions>
            </form>
         </Dialog>
      );
   }
}

export default withStyles(styles)(ModalDialog);
