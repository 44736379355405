import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Query from 'react-apollo/Query';
import 'react-table/react-table.css';
import DisplayError from '../fhg/components/DisplayError';
import ReactSelect from '../fhg/components/ReactSelect';
import Typography from '../fhg/components/Typography';
import Filter from './Filter';

const styles = theme => ({
   root: {
      margin: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      flex: '0 0 auto',
      maxWidth: 500,
   },
   title: {
      marginRight: theme.spacing(2),
   },
   noWrap: {
      whiteSpace: 'nowrap',
      minWidth: 'fit-content',
   },
   button: {
      marginTop: theme.spacing(1),
      alignSelf: 'flex-start',
   },
   leftIcon: {
      marginRight: theme.spacing(1),
   },
   iconSmall: {
      fontSize: 20,
   },
});

const GetOptionValues = gql`
    {
        locationTypes: locationType_All {
            id
            value:id
            label:name
        }
        states:state_All {
            id
            value:id
            label:name
        }
    }
`;

/**
 * The location filter component to determine which data to fetch.
 */
class LocationFilter extends Filter {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      onFilter: PropTypes.func,                 // Called when the filter changes and new data should be fetched.
   };

   constructor(props, context) {
      super(props, context);

      if (this.state === undefined) {
         this.state = {
            locationTypeId: '',
            stateId: '',
         };
      }
   }

   /**
    * Get the filters from the state. The filtering is based on parameters to the GraphQL query.
    *
    * @return {Array} The array of filters.
    */
   getFilters = () => {
      const filters = {};

      this.setFilterValue(filters, 'locationTypeId');
      this.setFilterValue(filters, 'stateId');
      return filters;
   };

   render() {
      let {classes} = this.props;

      return (
         <Query query={GetOptionValues} fetchPolicy={'cache-and-network'}>
            {({error, data = {}}) => (
               <Fragment>
                  <DisplayError error={error} errorId={'filter.fetch.error'}/>
                  <Grid container item className={classes.root} direction={'column'} xs={12} md={6}>
                     <Typography id={'filter.title'} className={classes.title} variant='h6' color='inherit' noWrap/>
                     <Grid container direction={'row'} spacing={2}>
                        <Grid container item xs={12} sm={6}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap}>Location Type</Typography>}
                              options={data.locationTypes}
                              name={'locationTypeId'}
                              value={this.state.locationTypeId}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                           <ReactSelect
                              label={<Typography className={classes.noWrap}>State</Typography>}
                              options={data.states}
                              name={'stateId'}
                              value={this.state.stateId}
                              onChange={this.handleChange}
                              fullWidth
                           />
                        </Grid>
                     </Grid>
                     <Button variant='contained' color='secondary' className={classes.button}
                             onClick={this.handleFilter}>
                        <FilterIcon className={`${classes.leftIcon} ${classes.iconSmall}`}/>
                        Filter
                     </Button>
                  </Grid>
               </Fragment>
            )}
         </Query>
      );
   }
}

export default withStyles(styles)(LocationFilter);
